// src/components/Admin/AddEditNews.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Tabs, Tab } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import axiosInstance from './axiosConfig';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Kırpma için yardımcı fonksiyon
import imageCompression from 'browser-image-compression';
import './Admin.css';

const modules = (imageHandler) => ({
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
    handlers: {
      image: imageHandler,
    },
  },
});

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

const AddEditNews = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);

  const [groupId, setGroupId] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [titleTr, setTitleTr] = useState('');
  const [contentTr, setContentTr] = useState('');
  const [date, setDate] = useState('');
  const [active, setActive] = useState(true); // Aktif Durum
  const [image, setImage] = useState(null); // Görsel URL'si
  const [croppedImage, setCroppedImage] = useState(null); // Kırpılmış görsel blob'u
  const [existingImage, setExistingImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [activeTab, setActiveTab] = useState('en');

  // Quill içerisine resim yükleme için progress & error
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [imageUploadError, setImageUploadError] = useState('');

  const navigate = useNavigate();
  const quillRef = useRef(null);
  const savedSelection = useRef(null); // Quill'de seçim konumunu saklamak için

  const BACKEND_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isEdit) {
      const fetchNews = async () => {
        try {
          const response = await axiosInstance.get(`/news/${id}`);
          // Gelen veri İngilizce/Türkçe olmak üzere bir dizi olabilir
          const news = response.data;
          const newsEn = news.find(item => item.language === 'en');
          const newsTr = news.find(item => item.language === 'tr');

          if (newsEn) {
            setTitleEn(newsEn.title);
            setContentEn(newsEn.content);
            setDate(newsEn.date.split('T')[0]);
            setExistingImage(newsEn.image);
            setGroupId(newsEn.group_id);
            setActive(newsEn.active ? true : false); // Aktif durumu
          }

          if (newsTr) {
            setTitleTr(newsTr.title);
            setContentTr(newsTr.content);
            // Tarih ve görsel EN ile aynı gruba ait olduğu için tekrar set etmenize gerek yok
          }
        } catch (err) {
          setError("Haber alınamadı.");
          console.error('Haber alınamadı:', err);
        }
      };
      fetchNews();
    }
  }, [id, isEdit]);

  /**
   * Kapak görseli seçildiğinde sıkıştırma ve kırpma süreci
   */
  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileSizeKB = selectedFile.size / 1024;

      try {
        let finalFile = selectedFile;
        if (fileSizeKB > 600) {
          // 600 KB'den büyükse sıkıştır
          const options = {
            maxSizeMB: 1.5,
            maxWidthOrHeight: 1920,
            initialQuality: 0.9,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(selectedFile, options);
          finalFile = compressedFile;
        }
        // Kırpma öncesi gösterim amacıyla blob URL oluştur
        const imageURL = URL.createObjectURL(finalFile);
        setImage(imageURL);
        setCroppedImage(finalFile); // kırpma modali için orijinal(veya sıkıştırılmış) dosyayı tut
        setShowCropModal(true);
      } catch (error) {
        console.error("Görsel sıkıştırma hatası:", error);
        setError("Görsel sıkıştırma başarısız.");
      }
    }
  };

  /**
   * Görsel kırpma tamamlandığında (modal içinde)
   */
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(image, croppedAreaPixels, zoom);
      setCroppedImage(croppedBlob);
      setShowCropModal(false);
    } catch (e) {
      console.error(e);
      setError("Görsel kırpma başarısız.");
    }
  }, [image, croppedAreaPixels, zoom]);

  /**
   * Quill içerisindeki "Resim Ekle" butonuna tıklanınca çalışacak handler
   * Burada da yine dosya seçilip, 600 KB'den büyükse sıkıştırma yapıyoruz.
   * Sonra sunucuya yolluyor ve Quill içine embed ediyoruz.
   */
  const imageHandler = useCallback(async () => {
    const quill = quillRef.current.getEditor();
    // Mevcut cursor konumunu kaydet
    const range = quill.getSelection(true);
    savedSelection.current = range;

    // Dosya seçimi için input oluştur
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      try {
        setImageUploadProgress(0);
        setImageUploadError('');

        // 1) Önce gerekliyse sıkıştır
        let finalFile = file;
        const fileSizeKB = file.size / 1024;
        if (fileSizeKB > 600) {
          const options = {
            maxSizeMB: 1.5,
            maxWidthOrHeight: 1920,
            initialQuality: 0.9,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const newFile = new File(
            [compressedFile],
            file.name, // orijinal dosya adı veya "compressed.jpg"
            { type: compressedFile.type }
          );
          finalFile = newFile;
        }

        // 2) Sıkıştırılmış (ya da orijinal) dosyayı FormData'ya ekle
        const formData = new FormData();
        formData.append('file', finalFile);

        // 3) Sunucuya yükle
        const response = await axiosInstance.post('/news/upload-image-news', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setImageUploadProgress(percentCompleted);
          },
        });

        // Yükleme tamamlanınca
        const { url } = response.data; // /uploads/images/news/xxx.jpg gibi bir yol
        setImageUploadProgress(0);

        // 4) Quill selection'ı geri yükle
        if (savedSelection.current) {
          quill.setSelection(savedSelection.current.index, savedSelection.current.length);
          // 5) Görseli Quill editörüne ekle
          const fullImageUrl = BACKEND_URL + url;
          quill.insertEmbed(savedSelection.current.index, 'image', fullImageUrl);
          quill.setSelection(savedSelection.current.index + 1);
          savedSelection.current = null; 
        } else {
          // Eğer selection kaydedilmediyse en sona ekle
          const fullImageUrl = BACKEND_URL + url;
          quill.insertEmbed(quill.getLength(), 'image', fullImageUrl);
        }

      } catch (error) {
        console.error('Resim yükleme hatası:', error);
        setImageUploadError("Resim yükleme başarısız.");
        setImageUploadProgress(0);
      }
    };

    input.click();
  }, [BACKEND_URL]);

  /**
   * Form submit (Haber ekleme/güncelleme) işlemi
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('date', date);
    formData.append('active', active);

    if (isEdit && groupId) {
      formData.append('group_id', groupId);
    }

    // Kapak resmi (Kırpılmış Blob)
    if (croppedImage) {
      formData.append('image', croppedImage, 'croppedImage.jpeg');
    }

    // İngilizce içerik
    if (titleEn.trim() !== '' && contentEn.trim() !== '') {
      formData.append('title_en', titleEn);
      formData.append('content_en', contentEn);
    }
    // Türkçe içerik
    if (titleTr.trim() !== '' && contentTr.trim() !== '') {
      formData.append('title_tr', titleTr);
      formData.append('content_tr', contentTr);
    }

    // En az bir dilin doldurulduğundan emin olun
    if (!formData.has('title_en') && !formData.has('title_tr')) {
      setError('En az bir dilde haber girmelisiniz.');
      setLoading(false);
      return;
    }

    try {
      if (isEdit) {
        await axiosInstance.put(`/news/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
      } else {
        await axiosInstance.post('/news', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
      }
      navigate('/admin/news');
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Haber kaydedilemedi.";
      const errorDetails = err.response?.data?.details || "";
      setError(`${errorMessage} ${errorDetails}`);
      console.error('Haber kaydedilemedi:', err);
    } finally {
      setLoading(false);
    }
  };

  // Bellek sızıntısı önlemek için, bileşen unmount olduğunda oluşturulan URL'leri temizliyoruz
  useEffect(() => {
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
      if (croppedImage) {
        URL.revokeObjectURL(croppedImage);
      }
    };
  }, [image, croppedImage]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>{isEdit ? "Haberi Düzenle" : "Haber Ekle"}</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {imageUploadError && <Alert variant="danger">{imageUploadError}</Alert>}
        {imageUploadProgress > 0 && imageUploadProgress < 100 && (
          <ProgressBar now={imageUploadProgress} label={`${imageUploadProgress}%`} animated striped className="mb-3" />
        )}
        <Form onSubmit={handleSubmit} noValidate>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="en" title="English">
              <Form.Group controlId="newsTitleEn" className="mb-3">
                <Form.Label>Başlık (EN)</Form.Label>
                <Form.Control
                  type="text"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                  placeholder={"Enter title in English"}
                />
              </Form.Group>

              <Form.Group controlId="newsContentEn" className="mb-3">
                <Form.Label>İçerik (EN)</Form.Label>
                <ReactQuill
                  ref={quillRef}
                  value={contentEn}
                  onChange={setContentEn}
                  theme="snow"
                  modules={modules(imageHandler)}
                  formats={formats}
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="tr" title="Türkçe">
              <Form.Group controlId="newsTitleTr" className="mb-3">
                <Form.Label>Başlık (TR)</Form.Label>
                <Form.Control
                  type="text"
                  value={titleTr}
                  onChange={(e) => setTitleTr(e.target.value)}
                  placeholder="Başlık (TR)"
                />
              </Form.Group>

              <Form.Group controlId="newsContentTr" className="mb-3">
                <Form.Label>İçerik (TR)</Form.Label>
                <ReactQuill
                  value={contentTr}
                  onChange={setContentTr}
                  theme="snow"
                  modules={modules(imageHandler)}
                  formats={formats}
                />
              </Form.Group>
            </Tab>
          </Tabs>

          <Form.Group controlId="newsDate" className="mb-3">
            <Form.Label>Tarih</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </Form.Group>

          {/* Aktif Durum Kontrolü */}
          <Form.Group controlId="newsActive" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Aktif"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="newsImage" className="mb-3">
            <Form.Label>Görsel (Kapak Fotoğrafı)</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {existingImage && !croppedImage && isEdit && (
              <div className="mt-3">
                <Form.Label>Mevcut Görsel</Form.Label>
                <br />
                <img
                  src={`${BACKEND_URL}${existingImage}`}
                  alt="Mevcut Görsel"
                  className="img-thumbnail"
                  style={{ width: '200px' }}
                />
              </div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="mt-3">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
              </div>
            )}
            {croppedImage && (
              <div className="mt-3">
                <Form.Label>Kırpılmış Görsel</Form.Label>
                <br />
                <img
                  src={URL.createObjectURL(croppedImage)}
                  alt="Kırpılmış Görsel"
                  className="img-thumbnail"
                  style={{ width: '200px' }}
                />
              </div>
            )}
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                {isEdit ? "Güncelleniyor..." : "Ekleniyor..."}
              </>
            ) : (
              isEdit ? "Güncelle" : "Ekle"
            )}
          </Button>
        </Form>
      </Card.Body>

      {/* Crop Modal */}
      <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Kırpma</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative', height: '400px' }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={5 / 4} // 5:4 oranında kırpma
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            Kırp ve Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default AddEditNews;
