// src/admin/SocialList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Spinner, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { useTranslation } from 'react-i18next';

const SocialList = () => {
    const { t } = useTranslation();

    const [socialGroups, setSocialGroups] = useState([]); // Gruplandırılmış akordiyonlar
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    const fetchAccordions = async () => {
        try {
            const response = await axiosInstance.get(`/social`);
            const allAccordions = response.data;

            // group_id'ye göre gruplandırma
            const groups = {};
            allAccordions.forEach(acc => {
                if (!groups[acc.group_id]) {
                    groups[acc.group_id] = {
                        group_id: acc.group_id,
                        id_en: null,
                        id_tr: null,
                        title_en: '',
                        title_tr: '',
                        content_en: '',
                        content_tr: '',
                        background_image: acc.background_image // İlk bulunan background_image
                    };
                }

                if (acc.language === 'en') {
                    groups[acc.group_id].id_en = acc.id;
                    groups[acc.group_id].title_en = acc.title;
                    groups[acc.group_id].content_en = acc.content;
                } else if (acc.language === 'tr') {
                    groups[acc.group_id].id_tr = acc.id;
                    groups[acc.group_id].title_tr = acc.title;
                    groups[acc.group_id].content_tr = acc.content;
                }

                // Eğer background_image henüz set edilmemişse, mevcut resmi set et
                if (!groups[acc.group_id].background_image && acc.background_image) {
                    groups[acc.group_id].background_image = acc.background_image;
                }
            });

            const groupedSocial = Object.values(groups);
            setSocialGroups(groupedSocial);
        } catch (err) {
            setError(t('errors.fetchSocialFailed') || "Akordiyonlar alınamadı.");
            console.error('Akordiyonlar alınamadı:', err);
        } finally {
            setLoading(false);
        }
    };

    // Görseli indirme fonksiyonu
    const handleDownload = (imageName) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = `${BACKEND_URL}${imageName}`;   // Arka uçtaki tam URL
        link.setAttribute('download', imageName);   // İndirme dosya adı
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchAccordions();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>{"Akordiyon Yönetimi"}</h3>
                {/* Ekleme butonu kaldırıldı */}
            </div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Görsel</th>
                        <th>Başlık (EN)</th>
                        <th>Başlık (TR)</th>
                        <th>İçerik (EN)</th>
                        <th>İçerik (TR)</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {socialGroups.length > 0 ? (
                        socialGroups.map(group => (
                            <tr key={group.group_id}>
                                <td>
                                    {group.background_image ? (
                                        <Image src={`${BACKEND_URL}${group.background_image}`} alt="Arka Plan" thumbnail style={{ width: '100px' }} />
                                    ) : (
                                        t('socialList.noImage') || "Görsel Yok"
                                    )}
                                </td>
                                <td>{group.title_en || t('socialList.noTitle') || "Başlık Yok"}</td>
                                <td>{group.title_tr || t('socialList.noTitle') || "Başlık Yok"}</td>
                                <td>{group.content_en.length > 100 ? group.content_en.substring(0, 100) + '...' : group.content_en}</td>
                                <td>{group.content_tr.length > 100 ? group.content_tr.substring(0, 100) + '...' : group.content_tr}</td>
                                <td>
                                    {group.id_en && (
                                        <Link to={`/admin/social/edit/${group.id_en}`}>
                                            <Button variant="warning" size="sm" className="me-2">Düzenle</Button>
                                        </Link>
                                    )}
                                    {!group.id_en && group.id_tr && (
                                        <>
                                            <Link to={`/admin/social/edit/${group.id_tr}`}>
                                                <Button variant="warning" size="sm" className="me-2">Düzenle</Button>
                                            </Link>
                                            <Button variant="info" size="sm" className="ms-2" onClick={() => handleDownload(group.image)}>Download</Button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">{t('socialList.noSocial') || "Henüz akordiyon eklenmemiş."}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default SocialList;
