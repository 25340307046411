import React from "react";
import "./Overview.css";
import arrowIcon from "../../assets/images/next.svg";
import image from "../../assets/images/founder-image.png";
import HeaderSection from "../HeaderSection/HeaderSection";
import { useTranslation } from "react-i18next";
import { useSiteTexts } from "../../context/SiteTextsContext";
import DOMPurify from 'dompurify'; // Güvenlik için eklediğimiz kütüphane

export default function Founder() {
  const { t } = useTranslation();
  const { siteTexts, loading: siteTextsLoading, error: siteTextsError } = useSiteTexts();

  if (siteTextsLoading) {
    return <div>Loading...</div>;
  }

  if (siteTextsError) {
    return <div className="alert alert-danger" role="alert">{siteTextsError}</div>;
  }

  return (
    <section className="about-overview-section">
      <HeaderSection
        title={t("founder.title")}
        menuText={t('headerSection.about')}
        icon={arrowIcon}
        menuText2={t("founder.menuText2")}
        category="Founder"
      />
      <div className="container">
        <div className="row py-5 d-flex justify-content-between align-items-center">
          <div className="col-md-7">
            <h2 className="overview-title">{siteTexts['founder.subtitle'] || t("founder.subtitle")}</h2>
            <div 
              className="overview-text" 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(siteTexts['founder.content'] || t("founder.content")) }} 
            />
          </div>
          <div className="col-md-4">
            <img className="overview-image" src={image} alt={siteTexts['founder.alt'] || t("founder.alt")} />
          </div>
        </div>
      </div>
    </section>
  );
}