// src/admin/AddEditSiteText.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, Row, Col } from 'react-bootstrap';
import axiosInstance from './axiosConfig'; // Doğru yolu kontrol edin
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const languages = [
    { code: 'en', label: 'English' },
    { code: 'tr', label: 'Türkçe' },
];

const AddEditSiteText = () => {
    const { id } = useParams(); // group_id
    const isEdit = Boolean(id);

    const navigate = useNavigate();

    const [selectedKey, setSelectedKey] = useState(''); // Anahtar girişi
    const [inputType, setInputType] = useState('plain'); // 'plain' veya 'rich'
    const [contents, setContents] = useState({}); // Dil bazlı içerik
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isEdit) {
            const fetchSiteText = async () => {
                try {
                    const response = await axiosInstance.get(`/site-texts/${id}`);
                    const texts = response.data;
                    if (texts.length === 0) {
                        setError('Metin bulunamadı.');
                        return;
                    }

                    // İlk kayıttan key ve active durumunu al
                    setSelectedKey(texts[0].key);
                    setActive(texts[0].active ? true : false);

                    // Tüm diller için içerikleri ayarla
                    const contentsObj = {};
                    texts.forEach(text => {
                        contentsObj[text.language] = text.content;
                    });
                    setContents(contentsObj);

                    // Input type'ı belirlemek için basit bir kontrol (örneğin, HTML tagları içeriyor mu)
                    const sampleContent = texts[0].content;
                    if (/<[a-z][\s\S]*>/i.test(sampleContent)) {
                        setInputType('rich');
                    } else {
                        setInputType('plain');
                    }

                } catch (err) {
                    setError('Metin alınamadı.');
                    console.error('Metin alınamadı:', err);
                }
            };
            fetchSiteText();
        }
    }, [id, isEdit]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Gerekli alanların kontrolü
        if (!selectedKey || !contents || Object.keys(contents).length === 0) {
            setError('Tüm zorunlu alanları doldurmalısınız.');
            setLoading(false);
            return;
        }

        // İçerik dizilerini oluştur
        const langs = Object.keys(contents);
        const conts = langs.map(lang => contents[lang]);

        try {
            if (isEdit) {
                await axiosInstance.put(`/site-texts/${id}`, {
                    key: selectedKey,
                    languages: langs,
                    contents: conts,
                    active
                });
                toast.success('Metin güncellendi.');
            } else {
                await axiosInstance.post('/site-texts', {
                    key: selectedKey,
                    languages: langs,
                    contents: conts,
                    active
                });
                toast.success('Metin eklendi.');
            }
            navigate('/admin/site-texts');
        } catch (err) {
            const errorMessage = err.response?.data?.error || 'Metin kaydedilemedi.';
            const errorDetails = err.response?.data?.details || '';
            setError(`${errorMessage} ${errorDetails}`);
            console.error('Metin kaydedilemedi:', err);
            toast.error(`${errorMessage}`);
        } finally {
            setLoading(false);
        }
    };

    const handleContentChange = (language, value) => {
        setContents(prev => ({
            ...prev,
            [language]: value
        }));
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>{isEdit ? 'Metni Düzenle' : 'Metin Ekle'}</Card.Title>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} noValidate>
                    {/* Anahtar Girişi */}
                    <Form.Group controlId="textKey" className="mb-3">
                        <Form.Label>Anahtar</Form.Label>
                        <Form.Control
                            type="text"
                            value={selectedKey}
                            onChange={(e) => setSelectedKey(e.target.value)}
                            placeholder="Metin için benzersiz anahtar giriniz"
                            required
                            disabled={isEdit} // Düzenleme sırasında anahtar değiştirilemez
                        />
                        {!isEdit && (
                            <Form.Text className="text-muted">
                                Anahtar, metnin benzersiz tanımlayıcısıdır.
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Input Tipi Seçimi */}
                    <Form.Group controlId="inputType" className="mb-3">
                        <Form.Label>İçerik Türü</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                type="radio"
                                label="Düz Metin"
                                name="inputType"
                                value="plain"
                                checked={inputType === 'plain'}
                                onChange={() => setInputType('plain')}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="Zengin Metin"
                                name="inputType"
                                value="rich"
                                checked={inputType === 'rich'}
                                onChange={() => setInputType('rich')}
                            />
                        </div>
                        <Form.Text className="text-muted">
                            İçeriği nasıl girmek istediğinizi seçiniz.
                        </Form.Text>
                    </Form.Group>

                    {/* Diller ve İçerik Girişi */}
                    <Form.Group controlId="textLanguage" className="mb-3">
                        <Form.Label>Diller</Form.Label>
                        {languages.map(lang => (
                            <Row key={lang.code} className="mb-3">
                                <Col xs={3}>
                                    <Form.Label>{lang.label}</Form.Label>
                                </Col>
                                <Col xs={9}>
                                    {inputType === 'rich' ? (
                                        <ReactQuill
                                            theme="snow"
                                            value={contents[lang.code] || ''}
                                            onChange={(value) => handleContentChange(lang.code, value)}
                                        />
                                    ) : (
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={contents[lang.code] || ''}
                                            onChange={(e) => handleContentChange(lang.code, e.target.value)}
                                            placeholder={`${lang.label} içeriği giriniz`}
                                            required
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </Form.Group>

                    {/* Aktiflik */}
                    <Form.Group controlId="textActive" className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label="Aktif"
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                        />
                    </Form.Group>

                    {/* Gönder Butonu */}
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                {isEdit ? 'Güncelleniyor...' : 'Ekleniyor...'}
                            </>
                        ) : (
                            isEdit ? 'Güncelle' : 'Ekle'
                        )}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );

};

export default AddEditSiteText;