// src/admin/HeaderSectionList.js
import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Alert,
  Spinner,
  DropdownButton,
  Dropdown,
  Modal,
  ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';

// Drag & Drop kütüphanesi
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// Kategori seçeneklerini burada tanımlıyoruz
const categoryOptions = [
  'About',
  'Directors',
  'Seniors',
  'Founder',
  'Overview',
  'Energy',
  'Hospitality',
  'Technology',
  'Investments',
  'Press',
  'Social',
  'Art',
  'Education',
  'Society',
  'Environment',
  'Gallery',
];

const HeaderSectionList = () => {
  const [headerSections, setHeaderSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  // Sürükle-bırak sonrası dizilimi localStorage'da korumak için kullanacağımız anahtar
  const LOCAL_STORAGE_KEY = 'headerSectionsOrder';

  // Sıralama bilgisi (hangi alana göre, hangi yönde)
  const [sortConfig, setSortConfig] = useState({
    field: null,      // 'id' veya 'category'
    direction: 'asc', // 'asc' veya 'desc'
  });

  // Yeni eklenen state'ler (Modal)
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  useEffect(() => {
    const fetchHeaderSections = async () => {
      try {
        const response = await axiosInstance.get('/header-sections');
        const data = response.data || [];

        // 1) API'den gelen veriyi state'e at
        setHeaderSections(data);

        // 2) Local Storage'da kayıtlı bir sıralama var mı, varsa ona göre düzenle
        const localOrderString = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (localOrderString) {
          const localOrder = JSON.parse(localOrderString); // [id1, id2, ...] ya da [category1, category2, ...] değil, biz group_id yerine section.id kullanacağız
          // localOrder'a göre data dizisini yeniden sırala
          const sorted = localOrder
            .map((oneId) => data.find((section) => section.id === oneId))
            .filter(Boolean);

          // localOrder'da olmayanları sona ekle
          const remaining = data.filter(
            (section) => !localOrder.includes(section.id)
          );
          setHeaderSections([...sorted, ...remaining]);
        }
      } catch (err) {
        console.error('Header Sections alınamadı:', err);
        setError('Header Sections alınamadı.');
      } finally {
        setLoading(false);
      }
    };
    fetchHeaderSections();
  }, []);

  /**
   * Sütun başlığına tıklayınca sıralama.
   * Aynı sütuna tekrar tıklanırsa 'asc' ↔ 'desc' değişimi.
   */
  const handleSortByColumn = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.field === field && sortConfig.direction === 'desc') {
      direction = 'asc';
    }

    setSortConfig({ field, direction });

    // Diziyi sıralayalım
    const sorted = [...headerSections].sort((a, b) => {
      const valA = getSortableValue(a, field);
      const valB = getSortableValue(b, field);

      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      } else {
        return direction === 'asc'
          ? String(valA).localeCompare(String(valB))
          : String(valB).localeCompare(String(valA));
      }
    });

    setHeaderSections(sorted);
    // Yeni sıralamayı localStorage'a kaydedelim
    const newOrder = sorted.map((sec) => sec.id);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newOrder));
  };

  // Hangi değer üzerinden sıralama yapacağız?
  // field = 'id' veya 'category'
  const getSortableValue = (section, field) => {
    if (field === 'id') {
      return parseInt(section.id) || 0; // ID numerikse
    } else if (field === 'category') {
      return section.category || '';
    }
    return '';
  };

  /**
   * Drag & Drop bitince tabloyu yeniden sıralayıp localStorage'a kaydediyoruz.
   */
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const reordered = Array.from(headerSections);
    const [removed] = reordered.splice(sourceIndex, 1);
    reordered.splice(destinationIndex, 0, removed);

    setHeaderSections(reordered);
    // Local Storage'a yeni sıralamayı kaydet
    const newOrder = reordered.map((sec) => sec.id);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newOrder));
  };

  // Görseli indirme fonksiyonu
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}/${imageName}`; // Arka uçtaki tam URL
    link.setAttribute('download', imageName); // İndirme dosya adı
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Kategori seçimi
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Görsele tıklayınca modal açma
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  // Filtreleme işlemi
  const filteredHeaderSections = selectedCategory
    ? headerSections.filter((section) => section.category === selectedCategory)
    : headerSections;

  return (
    <div>
      <h3>Header Section Yönetimi</h3>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedCategory || 'Kategoriye Göre Filtrele'}
          onSelect={handleCategorySelect}
        >
          <Dropdown.Item eventKey="">Tüm Kategoriler</Dropdown.Item>
          {categoryOptions.map((cat) => (
            <Dropdown.Item key={cat} eventKey={cat}>
              {cat}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        {/* İsterseniz yeni ekleme butonunu açabilirsiniz
        <Link to="/admin/header-sections/add">
          <Button variant="primary">Yeni Header Section Ekle</Button>
        </Link> */}
      </div>

      {/* Drag & Drop sarmalayıcı */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="headerSectionsDroppable">
          {(provided) => (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortByColumn('id')}
                  >
                    ID
                    {sortConfig.field === 'id' &&
                      (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                  </th>
                  <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortByColumn('category')}
                  >
                    Kategori
                    {sortConfig.field === 'category' &&
                      (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                  </th>
                  <th>Arka Plan Görseli</th>
                  <th>Güncelleme Tarihi</th>
                  <th>İşlemler</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {filteredHeaderSections.length > 0 ? (
                  filteredHeaderSections.map((section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={String(section.id)}
                      index={index}
                    >
                      {(dragProvided) => (
                        <tr
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <td>{section.id}</td>
                          <td>{section.category}</td>
                          <td>
                            <img
                              src={`${BACKEND_URL}/${section.background_image}`}
                              alt={section.category}
                              style={{ width: '100px', cursor: 'pointer' }}
                              onClick={() =>
                                handleImageClick(
                                  `${BACKEND_URL}/${section.background_image}`
                                )
                              }
                            />
                          </td>
                          <td>{new Date(section.updated_at).toLocaleString()}</td>
                          <td>
                            <ButtonGroup>
                              <Link
                                to={`/admin/header-sections/edit/${section.category}`}
                              >
                                <Button variant="warning" size="sm" className="me-2">
                                  Düzenle
                                </Button>
                              </Link>
                              {/* 
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleDelete(section.category)}
                              >
                                Sil
                              </Button> 
                              */}
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() => handleDownload(section.background_image)}
                              >
                                Download
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      Gösterilecek Header Section Bulunamadı.
                    </td>
                  </tr>
                )}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      {/* Image Zoom Modal */}
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Görseli İncele</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <img
            src={selectedImage}
            alt="Büyütülmüş Görsel"
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HeaderSectionList;
