import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './i18n';
import { SiteTextsProvider } from "./context/SiteTextsContext";

const removeLoader = () => {
  const loaderWrapper = document.getElementById("loader-wrapper");
  if (loaderWrapper) {
    loaderWrapper.style.opacity = "0";
    setTimeout(() => {
      loaderWrapper.remove();
    }, 500); // Animasyon süresiyle eşleştir
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SiteTextsProvider>
    <App />
  </SiteTextsProvider>
);

// React yüklenirken App içindeki tüm kritik kaynaklar yüklendikten sonra loader'ı kaldır
window.addEventListener("load", removeLoader);
