// src/admin/InvestmentCardList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Image, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';

const InvestmentCardList = () => {

  const [investmentGroups, setInvestmentGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const BACKEND_URL = process.env.REACT_APP_API_URL;

  const fetchInvestments = async () => {
    try {
      const response = await axiosInstance.get(`/investments-card`);
      const allInvestments = response.data;

      // Gruplandırma
      const groups = {};
      allInvestments.forEach(item => {
        if (!groups[item.group_id]) {
          groups[item.group_id] = {
            group_id: item.group_id,
            id_en: null,
            id_tr: null,
            image: null,
            title_en: '',
            title_tr: '',
            button_link_en: '',
            button_link_tr: ''
          };
        }

        if (item.language === 'en') {
          groups[item.group_id].id_en = item.id;
          groups[item.group_id].title_en = item.title;
          groups[item.group_id].button_link_en = item.button_link;
        } else if (item.language === 'tr') {
          groups[item.group_id].id_tr = item.id;
          groups[item.group_id].title_tr = item.title;
          groups[item.group_id].button_link_tr = item.button_link;
        }

        if (item.image && !groups[item.group_id].image) {
          groups[item.group_id].image = item.image;
        }
      });

      const groupedInvestments = Object.values(groups);
      setInvestmentGroups(groupedInvestments);
    } catch (err) {
      setError("Yatırım bölümleri alınamadı.");
      console.error('Yatırım bölümleri alınamadı:', err);
    } finally {
      setLoading(false);
    }
  };

  // Resim indirme fonksiyonu
  const handleDownload = (imagePath) => {
    const link = document.createElement('a');
    link.href = `${BACKEND_URL}${imagePath}`;
    link.target = '_blank';
    link.setAttribute('download', imagePath.split('/').pop());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchInvestments();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Yatırım Yönetimi</h3>
        <Link to="/admin/investments-card/add">
          <Button variant="primary">Yeni Yatırım Ekle</Button>
        </Link>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Resim</th>
            <th>Başlık (EN)</th>
            <th>Başlık (TR)</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {investmentGroups.length > 0 ? (
            investmentGroups.map((group) => (
              <tr key={group.group_id}>
                <td>
                  {group.image ? (
                    <Image src={`${BACKEND_URL}${group.image}`} alt="Yatırım" thumbnail style={{ width: '100px' }} />
                  ) : (
                    "Resim Yok"
                  )}
                </td>
                <td>{group.title_en || "N/A"}</td>
                <td>{group.title_tr || "N/A"}</td>
                <td>
                  {/* İngilizce için Edit butonu */}
                  {group.id_en && (
                    <Link to={`/admin/investments-card/edit/${group.id_en}`}>
                      <Button variant="warning" size="sm">EN Düzenle</Button>
                    </Link>
                  )}
                  {/* Türkçe için Edit butonu */}
                  {group.id_tr && (
                    <Link to={`/admin/investments-card/edit/${group.id_tr}`}>
                      <Button variant="warning" size="sm" className="ms-2">TR Düzenle</Button>
                    </Link>
                  )}
                  {/* İndirme butonu */}
                  <Button variant="info" size="sm" className="ms-2" onClick={() => handleDownload(group.image)}>İndir</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">Henüz yatırım bölümü eklenmedi.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default InvestmentCardList;
