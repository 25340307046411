import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Spinner, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';

const SiteTextsList = () => {
    const [siteTexts, setSiteTexts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedActive, setSelectedActive] = useState('');

    const fetchSiteTexts = async (active = '') => {
        try {
            let query = '/site-texts';
            const params = {};

            if (active !== '') {
                params.active = active;
            }

            const response = await axiosInstance.get(query, { params });
            setSiteTexts(response.data);
        } catch (err) {
            setError('Site metinleri alınamadı.');
            console.error('Site metinleri alınamadı:', err);
            toast.error('Site metinleri alınamadı.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSiteTexts();
        // eslint-disable-next-line
    }, []);

    // const handleDelete = async (groupId) => {
    //     if (window.confirm('Bu metni silmek istediğinize emin misiniz?')) {
    //         try {
    //             await axiosInstance.delete(`/site-texts/${groupId}`);
    //             fetchSiteTexts(selectedActive);
    //             toast.success('Metin silindi.');
    //         } catch (err) {
    //             setError('Metin silinemedi.');
    //             console.error('Metin silinemedi:', err);
    //             toast.error('Metin silinemedi.');
    //         }
    //     }
    // };

    const handleActiveSelect = (active) => {
        setSelectedActive(active);
        fetchSiteTexts(active);
    };

    // Gruplandırılmış metinleri oluşturun
    const groupedTexts = siteTexts.reduce((acc, text) => {
        if (!acc[text.group_id]) {
            acc[text.group_id] = {
                group_id: text.group_id,
                key: text.key,
                active: text.active,
                languages: []
            };
        }
        acc[text.group_id].languages.push({
            language: text.language,
            content: text.content
        });
        return acc;
    }, {});

    const groupedTextsArray = Object.values(groupedTexts);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Site Metinleri</h3>
                <Link to="/admin/site-texts/add">
                    <Button variant="primary">Metin Ekle</Button>
                </Link>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <DropdownButton
                    id="dropdown-active-button"
                    title={selectedActive === '' ? 'Aktif Duruma Göre Filtrele' : (selectedActive === '1' ? 'Aktif' : 'Pasif')}
                    onSelect={(active) => handleActiveSelect(active)}
                >
                    <Dropdown.Item eventKey="">Tüm Durumlar</Dropdown.Item>
                    <Dropdown.Item eventKey="1">Aktif</Dropdown.Item>
                    <Dropdown.Item eventKey="0">Pasif</Dropdown.Item>
                </DropdownButton>
            </div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Anahtar</th>
                        <th>Diller</th>
                        <th>Aktif Durum</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {groupedTextsArray.length > 0 ? (
                        groupedTextsArray.map((group) => (
                            <tr key={group.group_id}>
                                <td>{group.key}</td>
                                <td>
                                    {group.languages.map(lang => (
                                        <div key={lang.language}>
                                            <strong>{lang.language === 'en' ? 'English' : 'Türkçe'}:</strong> {lang.content}
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    {group.active ? (
                                        <Badge bg="success">Aktif</Badge>
                                    ) : (
                                        <Badge bg="secondary">Pasif</Badge>
                                    )}
                                </td>
                                <td>
                                    <Link to={`/admin/site-texts/edit/${group.group_id}`}>
                                        <Button variant="warning" size="sm" className="me-2">
                                            Düzenle
                                        </Button>
                                    </Link>
                                    {/* <Button variant="danger" size="sm" onClick={() => handleDelete(group.group_id)}>
                                        Sil
                                    </Button> */}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center">
                                Henüz metin eklenmemiş.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default SiteTextsList;
