import React from "react";
import "./Footer.css";
import Logo from "../../assets/images/logo-big.png";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-section">
          {/* About Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">{t('footer.about')}</h3>
            <ul className="footer-links">
              <li><Link to={`/${lang}/about/overview`} onClick={scrollToTop}>{t('footer.overview')}</Link></li>
              {/* <li><Link to={`/${lang}/about/timeline`} onClick={scrollToTop}>{t('navbar.timeline')}</Link></li> */}
              <li><Link to={`/${lang}/about/founder`} onClick={scrollToTop}>{t('footer.founder')}</Link></li>
              <li><Link to={`/${lang}/about/directors`} onClick={scrollToTop}>{t('footer.boardOfDirectors')}</Link></li>
              <li><Link to={`/${lang}/about/seniors`} onClick={scrollToTop}>{t('footer.seniorManagement')}</Link></li>
            </ul>
          </div>

          {/* Investments Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">{t('footer.investments')}</h3>
            <ul className="footer-links">
              <li><Link to={`/${lang}/investments/hospitality`} onClick={scrollToTop}>{t('footer.hospitality')}</Link></li>
              <li><Link to={`/${lang}/investments/energy`} onClick={scrollToTop}>{t('footer.energy')}</Link></li>
              <li><Link to={`/${lang}/investments/financial`} onClick={scrollToTop}>{t('navbar.financial')}</Link></li>
              <li><Link to={`/${lang}/investments/technology`} onClick={scrollToTop}>{t('footer.technology')}</Link></li>
            </ul>
          </div>

          {/* Press Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">{t('footer.press')}</h3>
            <ul className="footer-links">
              <li><Link to={`/${lang}/press/news`} onClick={scrollToTop}>{t('footer.news')}</Link></li>
              <li><Link to={`/${lang}/press/gallery`} onClick={scrollToTop}>{t('navbar.gallery')}</Link></li>
            </ul>
          </div>

          {/* Social Responsibility Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">{t('footer.socialResponsibility')}</h3>
            <ul className="footer-links">
              <li><Link to={`/${lang}/social/environment`} onClick={scrollToTop}>{t('footer.environment')}</Link></li>
              <li><Link to={`/${lang}/social/art`} onClick={scrollToTop}>{t('footer.art')}</Link></li>
              <li><Link to={`/${lang}/social/education`} onClick={scrollToTop}>{t('footer.education')}</Link></li>
              <li><Link to={`/${lang}/social/society-economy`} onClick={scrollToTop}>{t('footer.societyEconomy')}</Link></li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="col-md-2 col-sm-6 footer-column">
            <h3 className="footer-title">{t('footer.legal')}</h3>
            <ul className="footer-links">
              <li><Link to={`/${lang}/cookie-policy`} onClick={scrollToTop}>{t('footer.privacy')}</Link></li>
              <li><Link to={"https://mths.ttr.com.tr/firmabilgileri.aspx?mersis=0609004347700011"} target="blank">{t('footer.infoService')}</Link></li>
              <li><Link to={`/${lang}/contact`} onClick={scrollToTop}>{t('footer.contact')}</Link></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom row align-items-center">
          <div className="col-md-6 col-sm-12 footer-logo">
            <img src={Logo} alt={t('footer.about')} />
          </div>
          <div className="col-md-6 col-sm-12 text-md-end text-sm-center footer-copyright">
            <p>{t('footer.copyright')}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
