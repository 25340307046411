import React from 'react'
import HeaderSection from '../HeaderSection/HeaderSection'
import fileIcon from '../../assets/images/file-icon.svg'
import './CookiePolicy.css'
import clarification from '../../assets/documents/clarification.pdf'
import businessClarification from '../../assets/documents/business-clarification.pdf'
import applicationForm from '../../assets/documents/application.pdf'
import { useTranslation } from 'react-i18next'

export default function CookiePolicy() {
    const { t } = useTranslation();
  return (
    <section className='cookie-policy-section'>
        <div>
            <HeaderSection
                title={t('cookiePolicy.title')}
                menuText2={t('cookiePolicy.title')}
                category="About"
            />
            <div className="container">
                <div className="cookie-links">
                    <div className='cookie-file'>
                        <img src={fileIcon} className='cookie-file-icon' alt="" />
                        <a href={businessClarification} className='cookie-file-link' target='blank'> <span className='cookie-file-text'>{t('cookiePolicy.link1')}</span> <span className='cookie-file-subtext'>{t("cookiePolicy.subtext")}</span> </a>
                    </div>
                    <div className='cookie-file'>
                        <img src={fileIcon} className='cookie-file-icon' alt="" />
                        <a href={clarification} className='cookie-file-link' target='blank'> <span className='cookie-file-text'> {t("cookiePolicy.link2")}</span> <span className='cookie-file-subtext'> {t("cookiePolicy.subtext")} </span></a>
                        
                    </div>
                    <div className='cookie-file'>
                        <img src={fileIcon} className='cookie-file-icon' alt="" />
                        <a href={applicationForm} className='cookie-file-link' target='blank'> <span className='cookie-file-text'>{t('cookiePolicy.link3')}</span> <span className='cookie-file-subtext'> {t("cookiePolicy.subtext")} </span></a>
                        
                    </div>
                </div>
                <div className="cookie-text-section">
                    <p className='cookie-text'>{t('cookiePolicy.text1')}</p>
                    <p className='cookie-text'>{t('cookiePolicy.text2')}</p>
                    <p className='cookie-text'>{t('cookiePolicy.text3')}</p>
                    <p className='cookie-text'>{t('cookiePolicy.text4')}</p>
                </div>
            </div>
        </div>
    </section>
  )
}
