// src/admin/Login.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Spinner } from 'react-bootstrap';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axiosInstance.post('/auth/login', { username, password });
            const { token } = response.data;

            // Token'ı localStorage'a kaydetme
            localStorage.setItem('token', token);

            // Admin paneline yönlendirme
            navigate('/admin');
        } catch (err) {
            console.error('Giriş hatası:', err);
            setError(err.response?.data?.error || 'Giriş yapılamadı.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                <Card.Body>
                    <Card.Title>Admin Girişi</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="username" className="mb-3">
                            <Form.Label>Kullanıcı Adı</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Kullanıcı Adınızı Girin"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="password" className="mb-3">
                            <Form.Label>Şifre</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Şifrenizi Girin"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : 'Giriş Yap'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
    },
    card: {
        width: '400px',
        padding: '20px',
    },
};

export default Login;
