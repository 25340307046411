import React from 'react'
import About from '../components/About/About';
import Hero from '../components/Hero/Hero';
import News from '../components/News/News';
import Social from '../components/Social/Social';
import CookieBanner from '../components/Cookie/CookieBanner';
import Popup from '../components/Popup/Popup';

export default function HomePage() {
  return (
    <div>
        <CookieBanner />
        <Hero />
        <Popup />
        <About />
        <News />
        <Social />
    </div>
  )
}
