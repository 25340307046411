// PopupList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';

const PopupList = () => {
  const [popupGroups, setPopupGroups] = useState([]);   // Varsayılan boş dizi
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchPopups = async () => {
    try {
      const response = await axiosInstance.get('/popup');
      console.log('Popup API Response:', response.data);

      // 1) Gelen veri array mi kontrol edelim:
      if (!Array.isArray(response.data)) {
        console.warn('Server did not return an array. Possibly got HTML fallback:', response.data);
        setError('Sunucudan geçersiz veri geldi! Rota bulunamadı veya hata oluştu.');
        setPopupGroups([]); 
      } else {
        // 2) Her şey yolundaysa, diziye atayıp devam
        setPopupGroups(response.data);
      }
    } catch (err) {
      console.error('Pop-up verisi alınamadı:', err);
      setError('Pop-up verisi alınamadı.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPopups();
  }, []);

  const handleDelete = async (groupId) => {
    if (!window.confirm('Bu pop-up grubunu silmek istediğinize emin misiniz?')) return;

    try {
      await axiosInstance.delete(`/popup/${groupId}`);
      setPopupGroups(prev => prev.filter(group => group.group_id !== groupId));
      toast.success('Pop-up grubu başarıyla silindi!');
    } catch (err) {
      console.error('Pop-up grubu silinemedi:', err);
      toast.error('Pop-up grubu silinemedi.');
    }
  };

  if (loading) return <Spinner animation="border" variant="primary" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <h3>Pop-up Yönetimi</h3>
      <Link to="/admin/popup/add">
        <Button variant="primary" className="mb-3">Yeni Pop-up Grubu Ekle</Button>
      </Link>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Group ID</th>
            <th>EN Title</th>
            <th>EN Message</th>
            <th>TR Başlık</th>
            <th>TR Mesaj</th>
            <th>Arka Plan Türü</th>
            <th>Arka Plan Değeri</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {popupGroups.map((group, index) => {
            const en = group.en;
            const tr = group.tr;
            return (
              <tr key={index}>
                <td>{group.group_id}</td>
                <td>{en ? en.title : '-'}</td>
                <td>{en ? en.message : '-'}</td>
                <td>{tr ? tr.title : '-'}</td>
                <td>{tr ? tr.message : '-'}</td>
                <td>{en ? en.backgroundType : '-'}</td>
                <td>
                  {en && en.backgroundType === 'color' ? (
                    <div style={{ width: '50px', height: '20px', backgroundColor: en.backgroundValue }} />
                  ) : (
                    en && en.backgroundValue ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${en.backgroundValue}`}
                        alt="Arka Plan"
                        style={{ width: '50px' }}
                      />
                    ) : '-'
                  )}
                </td>
                <td>{en && en.isVisible ? 'Aktif' : 'Pasif'}</td>
                <td>
                  <Link to={`/admin/popup/edit/${group.group_id}`}>
                    <Button variant="warning" size="sm" className="me-2">Düzenle</Button>
                  </Link>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(group.group_id)}
                  >
                    Sil
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PopupList;
