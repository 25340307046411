// src/admin/OverviewList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Image, Alert, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';

const OverviewList = () => {
    const [overviews, setOverviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [overviewToDelete, setOverviewToDelete] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    // Fetch Overviews
    const fetchOverviews = async () => {
        try {
            const response = await axiosInstance.get('/overview');
            console.log('Overviews:', response.data);
            setOverviews(response.data);
        } catch (err) {
            setError("Failed to fetch overviews.");
            console.error('Failed to fetch overviews:', err);
        } finally {
            setLoading(false);
        }
    };

    // Delete Overview
    const handleDelete = async () => {
        if (!overviewToDelete) return;

        try {
            await axiosInstance.delete(`/overview/${overviewToDelete.id}`);
            toast.success('Overview deleted successfully!');
            setOverviews(overviews.filter(ov => ov.id !== overviewToDelete.id));
        } catch (err) {
            setError('Failed to delete overview.');
            console.error('Failed to delete overview:', err);
            toast.error('Failed to delete overview.');
        } finally {
            setShowDeleteModal(false);
            setOverviewToDelete(null);
        }
    };

    // Confirm Delete
    const confirmDelete = (overview) => {
        setOverviewToDelete(overview);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        fetchOverviews();
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Overview Management</h3>
            </div>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title (EN)</th>
                        <th>Title (TR)</th>
                        <th>Image</th>
                        <th>PDF</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {overviews.length > 0 ? (
                        overviews.map((overview) => (
                            <tr key={overview.id}>
                                <td>{overview.id}</td>
                                <td>{overview.title_en || "N/A"}</td>
                                <td>{overview.title_tr || "N/A"}</td>
                                <td>
                                    {overview.image ? (
                                        <Image src={`${BACKEND_URL}${overview.image}`} alt="Overview" thumbnail style={{ width: '100px' }} />
                                    ) : (
                                        "No Image"
                                    )}
                                </td>
                                <td>
                                    {overview.pdf ? (
                                        <a href={`${BACKEND_URL}${overview.pdf}`} target="_blank" rel="noopener noreferrer">
                                            View PDF
                                        </a>
                                    ) : (
                                        "No PDF"
                                    )}
                                </td>
                                <td>
                                    <Link to={`/admin/overview/edit/${overview.id}`}>
                                        <Button variant="warning" size="sm">Edit</Button>
                                    </Link>
                                    {/* <Button variant="danger" size="sm" className="ms-2" onClick={() => confirmDelete(overview)}>Delete</Button> */}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">No overviews added yet.</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Overview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {overviewToDelete && (
                        <p>Are you sure you want to delete Overview ID <strong>{overviewToDelete.id}</strong>?</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OverviewList;
