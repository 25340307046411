// src/admin/NewsList.jsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Image,
  Alert,
  Spinner,
  DropdownButton,
  Dropdown,
  Badge,
  ButtonGroup,
} from 'react-bootstrap';
import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const NewsList = () => {
  const { t } = useTranslation();
  
  const [newsGroups, setNewsGroups] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  // Sıralama için alan ve yön tutulur
  const [sortConfig, setSortConfig] = useState({
    field: null,      // 'title' veya 'date'
    direction: 'asc', // 'asc' veya 'desc'
  });

  const BACKEND_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line
  }, []);

  const fetchNews = async (category = '') => {
    setLoading(true);
    try {
      const params = category ? { category } : {};
      const response = await axiosInstance.get('/news', { params });
      const allNews = response.data;

      // group_id'ye göre gruplandır
      const groups = {};
      allNews.forEach((newsItem) => {
        if (!groups[newsItem.group_id]) {
          groups[newsItem.group_id] = {
            group_id: newsItem.group_id,
            id_en: null,
            id_tr: null,
            title_en: null,
            title_tr: null,
            content_en: null,
            content_tr: null,
            image: null,
            date: newsItem.date,
            active: newsItem.active
          };
        }
        // Dilleri ayırıyoruz
        if (newsItem.language === 'en') {
          groups[newsItem.group_id].id_en = newsItem.id;
          groups[newsItem.group_id].title_en = newsItem.title;
          groups[newsItem.group_id].content_en = newsItem.content;
        } else if (newsItem.language === 'tr') {
          groups[newsItem.group_id].id_tr = newsItem.id;
          groups[newsItem.group_id].title_tr = newsItem.title;
          groups[newsItem.group_id].content_tr = newsItem.content;
        }
        // Ortak görsel
        if (newsItem.image && !groups[newsItem.group_id].image) {
          groups[newsItem.group_id].image = newsItem.image;
        }
      });

      const groupedNews = Object.values(groups);
      setNewsGroups(groupedNews);
    } catch (err) {
      setError(t('errors.fetchNewsFailed') || 'Haberler alınamadı.');
      console.error('Haberler alınamadı:', err);
      toast.error(t('errors.fetchNewsFailed') || 'Haberler alınamadı.');
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    fetchNews(category);
  };

  const handleDelete = async (groupId) => {
    if (window.confirm(t('newsList.confirmDelete') || "Bu haberi silmek istediğinize emin misiniz?")) {
      try {
        // group_id üzerinden -> bir haberin id'sini seçip siliyoruz
        const group = newsGroups.find((g) => g.group_id === groupId);
        const newsId = group.id_en || group.id_tr;
        await axiosInstance.delete(`/news/${newsId}`);
        // Silme sonrası tabloyu güncelle
        fetchNews(selectedCategory);
        toast.success(t('newsList.newsDeleted') || 'Haber silindi.');
      } catch (err) {
        setError(t('errors.deleteNewsFailed') || 'Haber silinemedi.');
        console.error('Haber silinemedi:', err);
        toast.error(t('errors.deleteNewsFailed') || 'Haber silinemedi.');
      }
    }
  };

  // Görseli indirme fonksiyonu
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}${imageName}`;
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Sütuna tıklayınca sıralama yapar.
   * Eğer aynı sütuna tekrar tıklıyorsak ASC <-> DESC yönünü değiştirir.
   * field: 'title' veya 'date'
   */
  const handleSortByColumn = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.field === field && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ field, direction });

    // Haberleri sıralayalım
    const sorted = [...newsGroups].sort((a, b) => {
      const valA = getSortValue(a, field);
      const valB = getSortValue(b, field);

      if (field === 'date') {
        // Numeric (milisaniye) karşılaştırma
        return direction === 'asc' ? valA - valB : valB - valA;
      } else {
        // String karşılaştırma (Başlık)
        return direction === 'asc'
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }
    });
    setNewsGroups(sorted);
  };

  // Sıralamada kullanacağımız değerleri döndürür
  const getSortValue = (group, field) => {
    if (field === 'title') {
      // Önce EN varsa onu kullan, yoksa TR (string)
      return (group.title_en || group.title_tr || '').toString();
    }
    if (field === 'date') {
      // Tarihi sayısal olarak karşılaştıralım
      return new Date(group.date).getTime();
    }
    return '';
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Haberler</h3>
        <Link to="/admin/news/add">
          <Button variant="primary">Haber Ekle</Button>
        </Link>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <div className="d-flex justify-content-between align-items-center mb-3">
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedCategory || 'Kategoriye Göre Filtrele'}
          onSelect={handleCategorySelect}
        >
          <Dropdown.Item eventKey="">Tüm Kategoriler</Dropdown.Item>
          {[...new Set(newsGroups.flatMap((group) => [group.title_en, group.title_tr]))]
            .filter(Boolean)
            .map((category, index) => (
              <Dropdown.Item key={index} eventKey={category}>
                {category}
              </Dropdown.Item>
            ))}
        </DropdownButton>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Görsel</th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSortByColumn('title')}
            >
              Başlık (EN)
              {sortConfig.field === 'title' &&
                (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
            </th>
            <th>Başlık (TR)</th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => handleSortByColumn('date')}
            >
              Tarih
              {sortConfig.field === 'date' &&
                (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
            </th>
            <th>Aktif Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {newsGroups.length > 0 ? (
            newsGroups.map((group) => (
              <tr key={group.group_id}>
                <td>
                  {group.image ? (
                    <Image
                      src={`${BACKEND_URL}${group.image}`}
                      alt="Haber Görseli"
                      thumbnail
                      style={{ width: '100px' }}
                    />
                  ) : (
                    t('newsList.noImage') || 'Görsel Yok'
                  )}
                </td>
                <td>{group.title_en || t('newsList.noTitle') || 'Başlık Yok'}</td>
                <td>{group.title_tr || t('newsList.noTitle') || 'Başlık Yok'}</td>
                <td>{new Date(group.date).toLocaleDateString()}</td>
                <td>
                  {group.active ? (
                    <Badge bg="success">Aktif</Badge>
                  ) : (
                    <Badge bg="secondary">Pasif</Badge>
                  )}
                </td>
                <td>
                  <ButtonGroup>
                    <Link to={`/admin/news/edit/${group.id_en || group.id_tr}`}>
                      <Button variant="warning" size="sm" className="me-2">
                        Düzenle
                      </Button>
                    </Link>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(group.group_id)}
                    >
                      Sil
                    </Button>
                    {group.image && (
                      <Button
                        variant="info"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleDownload(group.image)}
                      >
                        Download
                      </Button>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                {t('newsList.noNews') || 'Henüz haber eklenmemiş.'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default NewsList;
