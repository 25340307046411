import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import InvestmentCard from '../Investments/InvestmentCard';
import { Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Environment() {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [subtext, setSubtext] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSocials = async () => {
            try {
                const response = await axiosInstance.get(`/social-responsibility`, {
                    params: { category: 'Environment' }
                });
                const filteredData = response.data
                    .filter(card => card.active === 1)
                    .map(card => card[lang]);
                setCardsData(filteredData);
                
                // Set the subtext based on the language
                if (lang === 'en') {
                    setSubtext(
                        'MV Holding has recently been instrumental in the establishment of Mind Your Waste Foundation for environmental cleanliness. MV Holding also contributes to the environment by supporting the Deniztemiz-Turmepa Foundation. Swissotel Buyuk Efes contributes to environmental awareness by planting trees and saving energy.'
                    );
                } else if (lang === 'tr') {
                    setSubtext(
                        'MV Holding, çevre temizliği için Mind Your Waste Vakfı’nın kurulmasında önemli bir rol oynamıştır. MV Holding ayrıca çevreye katkıda bulunmak için Deniztemiz-Turmepa Vakfı’nı desteklemektedir. Swissotel Büyük Efes, ağaç dikerek ve enerji tasarrufu sağlayarak çevre bilincine katkıda bulunmaktadır.'
                    );
                }
            } catch (err) {
                console.error('Veriler alınamadı:', err);
                setError('Veriler alınamadı.');
            } finally {
                setLoading(false);
            }
        };
        fetchSocials();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <div>
            <section className='hospitality-section'>
                <HeaderSection 
                    title={t('navbar.environment')}
                    menuText={t('headerSection.socialResponsibility')} 
                    menuText2={t('navbar.environment')}
                    icon={arrowIcon} 
                    category="Environment"
                />
                <div className="container mt-5">
                    <div className="hospitality-central-section">
                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>
                                    {subtext}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card) => (
                        <InvestmentCard
                            key={card.id}
                            image={`${BACKEND_URL}${card.image}`}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
