import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://mvholding.gercekisler.com/api',
});

// Request interceptor to add the token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('No token found in localStorage'); // Debugging için
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Tek bir response interceptor kullanın
axiosInstance.interceptors.response.use(
  (response) => {
    // Başarılı cevap
    return response;
  },
  (error) => {
    // Hata durumunda
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        if (data.error === 'Token expired') {
          // Token süresi doldu
          alert('Oturum süreniz doldu. Lütfen tekrar giriş yapın.');
        } else if (data.error === 'No token provided') {
          // Token sağlanmadı
          alert('Erişim izni gereklidir. Lütfen giriş yapın.');
        }
        // Tokenı sil ve login sayfasına yönlendir
        localStorage.removeItem('token');
        window.location.href = '/login';
      } else if (status === 403) {
        // Token geçersiz
        alert('Geçersiz erişim izni. Lütfen giriş yapın.');
        localStorage.removeItem('token');
        window.location.href = '/login';
      }

      console.error('Axios Response Error:', error.response);
    } else if (error.request) {
      console.error('Axios Request Error:', error.request);
    } else {
      console.error('General Axios Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
