// src/admin/AddEditPopup.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, ProgressBar } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const AddEditPopup = () => {
  // Ortak Alanlar
  const [isVisible, setIsVisible] = useState(false);
  const [backgroundType, setBackgroundType] = useState('color');

  // Eğer backgroundType = color ise, color bilgisini burada tutarız
  // Eğer image ise, backgroundImage yüklenecek (aşağıda handleImageChange)
  const [backgroundValue, setBackgroundValue] = useState('#ffffff');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [existingBackground, setExistingBackground] = useState('');

  // İngilizce Alanlar
  const [titleEn, setTitleEn] = useState('');
  const [messageEn, setMessageEn] = useState('');

  // Türkçe Alanlar
  const [titleTr, setTitleTr] = useState('');
  const [messageTr, setMessageTr] = useState('');

  // Diğer Durumlar
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams(); // group_id
  const isEdit = Boolean(id);

  useEffect(() => {
    if (isEdit) {
      // group_id ile pop-up grubunu çek
      const fetchPopupGroup = async () => {
        try {
          const response = await axiosInstance.get(`/popup/${id}`);
          const popupGroup = response.data; // Array olarak gelecek: [{}, {}]

          // EN ve TR kayıtlarını ayır
          const enData = popupGroup.find(item => item.language === 'en');
          const trData = popupGroup.find(item => item.language === 'tr');

          if (enData) {
            setTitleEn(enData.title);
            setMessageEn(enData.message);
            setIsVisible(!!enData.isVisible);
            setBackgroundType(enData.backgroundType);
            if (enData.backgroundType === 'color') {
              setBackgroundValue(enData.backgroundValue);
            } else {
              setExistingBackground(enData.backgroundValue);
            }
          }
          if (trData) {
            setTitleTr(trData.title);
            setMessageTr(trData.message);
          }
        } catch (err) {
          setError('Pop-up grubu alınamadı.');
          console.error('Pop-up grubu alınamadı:', err);
        }
      };
      fetchPopupGroup();
    }
  }, [id, isEdit]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const options = { maxSizeMB: 1.5, maxWidthOrHeight: 1920, initialQuality: 0.9, useWebWorker: true };
      try {
        const compressedFile = await imageCompression(selectedFile, options);
        setBackgroundImage(compressedFile);
        toast.info('Görsel sıkıştırıldı ve yüklenecek.');
      } catch (error) {
        console.error('Görsel sıkıştırma hatası:', error);
        setError('Görsel sıkıştırılırken bir hata oluştu.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setUploadProgress(0);

    const formData = new FormData();

    // İngilizce Alanlar
    formData.append('title_en', titleEn);
    formData.append('message_en', messageEn);

    // Türkçe Alanlar
    formData.append('title_tr', titleTr);
    formData.append('message_tr', messageTr);

    // Ortak Alanlar
    formData.append('isVisible', isVisible);
    formData.append('backgroundType', backgroundType);

    if (backgroundType === 'color') {
      formData.append('backgroundValue', backgroundValue);
    } else if (backgroundType === 'image' && backgroundImage) {
      formData.append('backgroundImage', backgroundImage, backgroundImage.name);
    }

    try {
      if (isEdit) {
        await axiosInstance.put(`/popup/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
        toast.success('Pop-up grubu başarıyla güncellendi!');
      } else {
        await axiosInstance.post(`/popup`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
        toast.success('Yeni pop-up grubu başarıyla eklendi!');
      }
      navigate('/admin/popup');
    } catch (err) {
      setError('Pop-up grubu kaydedilemedi.');
      console.error('Pop-up grubu kaydedilemedi:', err);
      toast.error('Pop-up grubu kaydedilemedi.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{isEdit ? 'Pop-up Grubunu Düzenle' : 'Pop-up Grubu Ekle'}</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          {/* İngilizce Alanlar */}
          <h5>İngilizce Bilgiler</h5>
          <Form.Group className="mb-3">
            <Form.Label>Title (EN)</Form.Label>
            <Form.Control type="text" value={titleEn} onChange={(e) => setTitleEn(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Message (EN)</Form.Label>
            <Form.Control as="textarea" rows={3} value={messageEn} onChange={(e) => setMessageEn(e.target.value)} required />
          </Form.Group>
          <hr />

          {/* Türkçe Alanlar */}
          <h5>Türkçe Bilgiler</h5>
          <Form.Group className="mb-3">
            <Form.Label>Başlık (TR)</Form.Label>
            <Form.Control type="text" value={titleTr} onChange={(e) => setTitleTr(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mesaj (TR)</Form.Label>
            <Form.Control as="textarea" rows={3} value={messageTr} onChange={(e) => setMessageTr(e.target.value)} required />
          </Form.Group>
          <hr />

          {/* Ortak Ayarlar */}
          <Form.Group className="mb-3" controlId="popupVisibility">
            <Form.Check type="checkbox" label="Pop-up Göster (isVisible)" checked={isVisible} onChange={(e) => setIsVisible(e.target.checked)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="popupBackgroundType">
            <Form.Label>Arka Plan Türü</Form.Label>
            <Form.Select value={backgroundType} onChange={(e) => setBackgroundType(e.target.value)}>
              <option value="color">Renk</option>
              <option value="image">Görsel</option>
            </Form.Select>
          </Form.Group>

          {backgroundType === 'color' && (
            <Form.Group className="mb-3" controlId="popupBackgroundColor">
              <Form.Label>Arka Plan Rengi</Form.Label>
              <Form.Control type="color" value={backgroundValue} onChange={(e) => setBackgroundValue(e.target.value)} />
            </Form.Group>
          )}

          {backgroundType === 'image' && (
            <Form.Group className="mb-3" controlId="popupBackgroundImage">
              <Form.Label>Arka Plan Görseli</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
              {existingBackground && !backgroundImage && (
                <div className="mt-3">
                  <img
                    src={`${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${existingBackground}`}
                    alt="Mevcut Görsel"
                    className="img-thumbnail"
                    style={{ width: '200px' }}
                  />
                </div>
              )}
              {backgroundImage && (
                <div className="mt-3">
                  <img src={URL.createObjectURL(backgroundImage)} alt="Yeni Görsel" className="img-thumbnail" style={{ width: '200px' }} />
                </div>
              )}
            </Form.Group>
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated className="mb-3" />
          )}

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (isEdit ? 'Güncelleniyor...' : 'Ekleniyor...') : (isEdit ? 'Güncelle' : 'Ekle')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddEditPopup;
