// src/pages/Investments.jsx
import React, { useEffect, useState } from "react";
import "./Investments.css";
import HeaderSection from '../HeaderSection/HeaderSection';
import Card from '../Card/Card';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig'; // Ensure the correct path

export default function Investments() {
    const { t } = useTranslation();
    const { lang } = useOutletContext();
    const navigate = useNavigate();
    const [investmentSections, setInvestmentSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Backend base URL
    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    useEffect(() => {
        const fetchInvestments = async () => {
            try {
                // Fetch investments based on the current language
                const response = await axiosInstance.get(`/investments-card?language=${lang}`);
                console.log('Investments:', response.data);
                setInvestmentSections(response.data);
            } catch (err) {
                setError(t('investments.fetchError') || 'Failed to load investments.');
                console.error('Failed to load investments:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchInvestments();
    }, [lang, t]);

    const handleCardClick = (button_link) => {
        // Navigate to the detailed investment page
        navigate(`/${lang}/investments/${button_link.toLowerCase()}`);
    };

    if (loading) {
        return <div className="loading">{t('loading')}</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <section className='investments-section'>
            <HeaderSection
                title={t('investments.title')}
                menuText={t('headerSection.investments')}
                category="Investments"
            />
            <div className="investments-central-section">
                <div className="container">
                    <div className="investments-card">
                        {investmentSections.map((section, index) => (
                            <div key={section.id} className={`investments-card-item investments-card-item-${index + 1}`}>
                                <Card 
                                    imageSrc={`${BACKEND_URL}${section.image}`} 
                                    title={section.title} 
                                    onClick={() => handleCardClick(section.button_link)} 
                                />
                            </div>
                        ))}                        
                    </div>
                </div>
            </div>
        </section>
    )
}
