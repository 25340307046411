// src/components/HeaderSection/HeaderSection.jsx
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import defaultBackground from '../../assets/images/headers-bg.jpeg'; // Varsayılan görseli içe aktar
import './HeaderSection.css'
import axiosInstance from '../../axiosConfig';

export default function HeaderSection({ title, menuText, menuText2, category, icon }) {
    const [backgroundImage, setBackgroundImage] = useState('');
    const [loading, setLoading] = useState(true);

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchBackgroundImage = async () => {
            try {
                const response = await axiosInstance.get(`/header-sections/${category}`);
                if (response.data && response.data.background_image) {
                    setBackgroundImage(`${BACKEND_URL}/${response.data.background_image}`);
                } else {
                    setBackgroundImage(defaultBackground);
                }
            } catch (err) {
                console.error('Header Section alınamadı:', err);
                setBackgroundImage(defaultBackground); // Hata durumunda varsayılan görseli kullan
            } finally {
                setLoading(false);
            }
        };

        fetchBackgroundImage();
    }, [category, BACKEND_URL]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (
        <>
            <div
                className="overview-top-section"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <h1 className="container">{title}</h1>
            </div>
            <div className="container">
                <div className="row overview-center-section">
                    <div className="col-12 d-flex align-items-center">
                        <p className="menu">{menuText}</p>
                        {icon && (
                            <img
                                src={icon}
                                alt="Icon"
                                style={{ width: '20px', marginLeft: '5px', marginRight: '5px' }}
                            />
                        )}
                        <p className="menu menu2">{menuText2}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
