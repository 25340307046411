// src/admin/AddEditInvestmentCard.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Image } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Ensure you have this utility
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const AddEditInvestmentCard = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);

  const [language, setLanguage] = useState('en'); // Eklendi: Hangi dilin düzenlendiğini takip etmek için
  const [titleEn, setTitleEn] = useState('');
  const [titleTr, setTitleTr] = useState('');
  const [buttonLinkEn, setButtonLinkEn] = useState('');
  const [buttonLinkTr, setButtonLinkTr] = useState('');
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [existingImage, setExistingImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);

  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  useEffect(() => {
    if (isEdit) {
      const fetchInvestment = async () => {
        try {
          const response = await axiosInstance.get(`/investments-card/${id}`);
          const investmentGroup = response.data;

          investmentGroup.forEach(investment => {
            if (investment.language === 'en') {
              setTitleEn(investment.title || '');
              setButtonLinkEn(investment.button_link || '');
              setExistingImage(investment.image || '');
            }
            if (investment.language === 'tr') {
              setTitleTr(investment.title || '');
              setButtonLinkTr(investment.button_link || '');
            }
          });

          // Belirli bir entry'yi düzenlerken dilini belirle
          const entry = investmentGroup.find(investment => investment.id === parseInt(id));
          if (entry) {
            setLanguage(entry.language);
          }

        } catch (err) {
          setError('Yatırım bölümü yüklenemedi.');
          console.error('Yatırım bölümü yüklenemedi:', err);
        }
      };
      fetchInvestment();
    }
  }, [id, isEdit]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileSizeKB = selectedFile.size / 1024;

      if (fileSizeKB > 600) {
        const options = {
          maxSizeMB: 1.5,
          maxWidthOrHeight: 1920,
          initialQuality: 0.8,
          useWebWorker: true,
        };

        try {
          const compressedFile = await imageCompression(selectedFile, options);
          const imageURL = URL.createObjectURL(compressedFile);
          setImage(imageURL);
          setShowCropModal(true);
        } catch (error) {
          console.error("Resim sıkıştırma hatası:", error);
          setError("Resim sıkıştırılamadı.");
        }
      } else {
        const imageURL = URL.createObjectURL(selectedFile);
        setImage(imageURL);
        setCroppedImage(selectedFile);
        setShowCropModal(true);
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(image, croppedAreaPixels, zoom);
      setCroppedImage(croppedBlob);
      setShowCropModal(false);
    } catch (e) {
      console.error(e);
      setError("Resim kırpma başarısız oldu.");
    }
  }, [image, croppedAreaPixels, zoom]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setUploadProgress(0);

    try {
      const formData = new FormData();

      // Sadece ilgili dilin verilerini ekle
      if (language === 'en') {
        formData.append('title_en', titleEn);
        formData.append('button_link_en', buttonLinkEn);
      } else if (language === 'tr') {
        formData.append('title_tr', titleTr);
        formData.append('button_link_tr', buttonLinkTr);
      }

      // Resim
      if (croppedImage) {
        formData.append('image', croppedImage, 'croppedImage.jpeg');
      }

      // Debugging: FormData içeriklerini logla
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      if (isEdit) {
        await axiosInstance.put(`/investments-card/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
        toast.success('Yatırım bölümü başarıyla güncellendi!');
      } else {
        await axiosInstance.post('/investments-card', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
        toast.success('Yatırım bölümü başarıyla eklendi!');
      }

      navigate('/admin/investments-card');
    } catch (err) {
      setError('Yatırım bölümü kaydedilemedi.');
      console.error('Yatırım bölümü kaydedilemedi:', err);
      toast.error('Yatırım bölümü kaydedilemedi.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{isEdit ? 'Yatırım Bölümünü Düzenle' : 'Yatırım Bölümü Ekle'}</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit} noValidate>
          {/* Sadece ilgili dilin sekmesini göster */}
          {language === 'en' && (
            <Form.Group controlId="investmentTitleEn" className="mb-3">
              <Form.Label>Başlık (EN)</Form.Label>
              <Form.Control
                type="text"
                value={titleEn}
                onChange={(e) => setTitleEn(e.target.value)}
                placeholder="İngilizce başlık girin"
                required
              />
            </Form.Group>
          )}
          {language === 'tr' && (
            <Form.Group controlId="investmentTitleTr" className="mb-3">
              <Form.Label>Başlık (TR)</Form.Label>
              <Form.Control
                type="text"
                value={titleTr}
                onChange={(e) => setTitleTr(e.target.value)}
                placeholder="Türkçe başlık girin"
                required
              />
            </Form.Group>
          )}

          <Form.Group controlId="investmentImage" className="mb-3">
            <Form.Label>Yatırım Resmi</Form.Label>
            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
            {existingImage && !image && isEdit && (
              <div className="mt-3">
                <Form.Label>Mevcut Resim:</Form.Label>
                <br />
                <Image src={`${BACKEND_URL}${existingImage}`} alt="Mevcut Yatırım" thumbnail style={{ width: '200px' }} />
              </div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="mt-3">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
              </div>
            )}
            {croppedImage && (
              <div className="mt-3">
                <Form.Label>Kırpılmış Resim:</Form.Label>
                <br />
                <Image src={URL.createObjectURL(croppedImage)} alt="Kırpılmış Yatırım" thumbnail style={{ width: '200px' }} />
              </div>
            )}
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                {isEdit ? "Güncelleniyor..." : "Ekleniyor..."}
              </>
            ) : (
              isEdit ? "Güncelle" : "Ekle"
            )}
          </Button>
        </Form>
      </Card.Body>

      {/* Crop Modal */}
      <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Resmi Kırp</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative', height: '400px' }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={32 / 25}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            Kırp ve Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default AddEditInvestmentCard;
