// src/components/Society.jsx
import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import InvestmentCard from '../Investments/InvestmentCard';
import arrowIcon from '../../assets/images/next.svg';
import { Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Society() {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSocietyData = async () => {
            try {
                const response = await axiosInstance.get(`/social-responsibility`, {
                    params: { category: 'Society' }
                });
                const filteredData = response.data
                    .filter(card => card.active === 1)
                    .map(card => card[lang]);
                setCardsData(filteredData);
            } catch (err) {
                console.error('Veriler alınamadı:', err);
                setError('Veriler alınamadı.');
            } finally {
                setLoading(false);
            }
        };
        fetchSocietyData();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <div>
            <section className='hospitality-section'>
                <HeaderSection 
                    title={t('navbar.societyEconomy')}
                    menuText={t('headerSection.socialResponsibility')}
                    menuText2={t('navbar.societyEconomy')}
                    icon={arrowIcon} 
                    category="Society"
                />
            
                <div className="hospitality-cards-container">
                    {cardsData.map((card) => (
                        <InvestmentCard
                            key={card.id}
                            image={`${BACKEND_URL}${card.image}`}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
