// src/components/Press/PressCard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PressCard.css';
import { useTranslation } from 'react-i18next';

export default function PressCard({ image, title, text, date, link }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleCardClick = () => {
        navigate(link);
        window.scrollTo(0, 0);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            navigate(link);
            window.scrollTo(0, 0);
        }
    };

    return (
        <article 
            className="press-card-component" 
            onClick={handleCardClick}
            role="button"
            tabIndex={0}
            onKeyPress={handleKeyPress}
        >
            <div className="container">
                <div className="row">
                    {/* Conditionally render the image column only if image exists */}
                    {image && (
                        <div className="col-md-5 d-flex justify-content-start align-items-center">
                            <img 
                                className='press-card-image' 
                                src={image} 
                                alt={title} 
                                loading="lazy" 
                            />
                        </div>
                    )}
                    <div className={image ? "col-md-7 d-flex flex-column justify-content-between" : "col-12 d-flex flex-column justify-content-between"}>
                        <header>
                            <p className='press-card-date'>{date}</p>
                            <h2 className='press-card-title'>{title}</h2>
                        </header>
                        <p className='press-card-text'>{text}</p>
                        <button 
                            className="hospitality-button" 
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(link);
                                window.scrollTo(0, 0);
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    navigate(link);
                                    window.scrollTo(0, 0);
                                }
                            }}
                            aria-label={`Read more about ${title}`}
                        >
                            {t('news.readMore') || 'Read More'}
                        </button>
                    </div>
                </div>
            </div>
        </article>
    )
}
