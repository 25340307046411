// src/components/Hero/Hero.jsx
import React, { useState, useEffect, useMemo } from "react";
import "./Hero.css";
import { Alert } from "react-bootstrap";
import logoIcon from "../../assets/images/logo-sade.png";
import axiosInstance from "../../axiosConfig";
import { ReactComponent as TechnologyIcon } from "../../assets/images/computer.svg";
import { ReactComponent as EnergyIcon } from "../../assets/images/energy.svg";
import { ReactComponent as HospitalityIcon } from "../../assets/images/hotel.svg";
import { ReactComponent as HomeSmileIcon } from "../../assets/images/home-smile.svg";
import { ReactComponent as FinanceIcon } from "../../assets/images/finance.svg";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

function Hero() {
  const [activeMenu, setActiveMenu] = useState(1);
  const [heroGroups, setHeroGroups] = useState([]);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.startsWith('tr') ? 'tr' : 'en'; // Kullanıcı dilini belirleme
  const { lang } = useParams();

  const BACKEND_URL = process.env.REACT_APP_API_URL;

  // Menü öğesi id'sine göre group_id'yi belirleme
  const getGroupIdForMenuItem = (menuId) => {
    // Burada her menü öğesi için group_id'yi dinamik olarak belirlemeniz gerekiyor.
    // Örneğin, sabit group_id'ler kullanabilirsiniz veya dinamik olarak yönetebilirsiniz.
    const groupIds = {
      1: '85064f1d-c541-11ef-b62a-008cfaf20aa0', // MV Holding
      2: '85065f47-c541-11ef-b62a-008cfaf20aa0', // Technology
      3: '850660d5-c541-11ef-b62a-008cfaf20aa0', // Energy
      4: '85066201-c541-11ef-b62a-008cfaf20aa0', // Hospitality
      5: '8506632f-c541-11ef-b62a-008cfaf20aa0'
    };
    return groupIds[menuId] || null;
  };

  // Statik Menü Öğeleri
  const staticMenuItems = useMemo(
    () => [
      {
        id: 1,
        name: "menu.mvHolding",
        icon: HomeSmileIcon,
        background: "/",
        title: "The Innovative Force Shaping the Future",
        subtitle:
          "Founded in 1993, MV Holding continues to create value with its innovative investments and sustainable growth approach in the technology, hospitality, and energy sectors.",
        button_link: `/${lang}/about/overview`, // Dil parametresini dinamik olarak ekle
        button_text: "Learn more",
      },
      {
        id: 2,
        name: "menu.technology",
        icon: TechnologyIcon,
        background: "/",
        title: "Technology",
        subtitle:
          "MV Holding invests in transformative technologies, driving progress in communication, fintech, and digital solutions to shape the future.",
        button_link: `/${lang}/investments/technology`, // Dil parametresini dinamik olarak ekle
        button_text: "Explore Technology",
      },
      {
        id: 3,
        name: "menu.energy",
        icon: EnergyIcon,
        background: "/",
        title: "Energy",
        subtitle:
          "MV Holding is committed to advancing renewable and sustainable energy initiatives, powering a cleaner and greener future.",
        button_link: `/${lang}/investments/energy`, // Dil parametresini dinamik olarak ekle
        button_text: "Discover Energy",
      },
      {
        id: 4,
        name: "menu.hospitality",
        icon: HospitalityIcon,
        background: "/",
        title: "Hospitality",
        subtitle:
          "MV Holding invests in the hospitality sector, creating exceptional experiences and setting new standards in comfort and service.",
        button_link: `/${lang}/investments/hospitality`, // Dil parametresini dinamik olarak ekle
        button_text: "Visit Hospitality",
      },
      {
        id: 5,
        name: "navbar.financial",
        icon: FinanceIcon,
        background: "/",
        title: "Finance",
        subtitle:
          "MV Holding invests in the finance sector, fostering innovation and creating solutions that drive economic growth and financial empowerment.",
        button_link: `/${lang}/investments/finance`, // Dil parametresini dinamik olarak ekle
        button_text: "Visit Hospitality",
      },
    ],
    [lang]
  );


  // Backend'den Hero Verilerini Çekme
  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axiosInstance.get(`/hero`, {
          params: { language: currentLanguage }
        });
        setHeroGroups(response.data);
      } catch (err) {
        setError("Hero öğeleri alınamadı.");
        console.error('Hero öğeleri alınamadı:', err);
      }
    };

    fetchHeroData();
  }, [currentLanguage]);

  // Menü öğelerini birleştirme
  const mergedMenuItems = useMemo(() => {
    return staticMenuItems.map((item) => {
      // Hero verilerinden bu menü öğesi için mevcut dildeki veriyi bul
      const hero = heroGroups.find(
        (h) => h.group_id === getGroupIdForMenuItem(item.id) && h.language === currentLanguage
      );

      return {
        ...item,
        background: hero?.background
          ? `${BACKEND_URL}${hero.background}`
          : item.background,
        title: hero?.title || item.title,
        subtitle: hero?.subtitle || item.subtitle,
        button_link: hero?.button_link || item.button_link,
        button_text: hero?.button_text || item.button_text,
      };
    });
  }, [staticMenuItems, heroGroups, BACKEND_URL, currentLanguage]);

  const activeItem =
    mergedMenuItems.find((item) => item.id === activeMenu) || mergedMenuItems[0];

  // Görsel yüklenme durumu kontrolü
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Swipe Handlers
  const handleSwipeLeft = () => {
    setImageLoaded(false); // Görsel değiştiğinde yüklenme durumunu sıfırla
    setActiveMenu((prev) => (prev < mergedMenuItems.length ? prev + 1 : 1));
  };

  const handleSwipeRight = () => {
    setImageLoaded(false);
    setActiveMenu((prev) => (prev > 1 ? prev - 1 : mergedMenuItems.length));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (error) {
    return <Alert variant="danger" className="text-center">{error}</Alert>;
  }

  return (
    <section className="hero-section" {...swipeHandlers}>
      {/* Görsel ve Overlay */}
      <div className="hero-background">
        <img
          src={activeItem.background}
          alt={activeItem.title}
          className={`background-image ${imageLoaded ? "visible" : "hidden"}`}
          onLoad={handleImageLoad}
        />
        <div className="overlay"></div>
      </div>

      {/* Main Hero Content */}
      <div className="container text-white d-flex flex-column justify-content-start align-items-start hero-content">
        <img className="hero-logo" width={100} src={logoIcon} alt="MV Holding Logo" />
        <h1 className="hero-title">{activeItem.title}</h1>
        <p className="hero-subtitle">{activeItem.subtitle}</p>
        <button className="hero-button">
          <a href={activeItem.button_link}>{activeItem.button_text}</a>
        </button>
      </div>

      {/* Hero Menu */}
      <div className="hero-menu">
        {mergedMenuItems.map((item) => (
          <button
            key={item.id}
            className={`menu-item ${activeMenu === item.id ? "active" : ""}`}
            onClick={() => setActiveMenu(item.id)}
          >
            {item.id !== 1 ? (
              <item.icon
                className="menu-icon"
                aria-hidden="true"
              />
            ) : (
              <item.icon
                className="menu-icon menu-icon-home"
                aria-hidden="true"
              />
            )}
            {item.name && <span className="menu-text">{t(item.name)}</span>}
          </button>
        ))}
      </div>
    </section>
  );
}

export default Hero;
