// src/components/About/About.jsx
import React from "react";
import "./About.css";
import topRightImage from "../../assets/images/top-right.svg";
import bottomRightImage from "../../assets/images/bottom-left.svg";
import { useSiteTexts } from '../../context/SiteTextsContext';
import { Spinner, Alert } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import arrowIcon from "../../assets/images/arrow-narrow-right.svg";

function About() {
    const { siteTexts, loading, error } = useSiteTexts();
    const { lang } = useParams();

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <section className="about-section" id="about">
            {/* Top-Right Corner Image */}
            <img
                src={topRightImage}
                alt={siteTexts['about.topRightAlt'] || "Top Right Decoration"}
                className="corner-image top-right"
            />

            {/* Bottom-Right Corner Image */}
            <img
                src={bottomRightImage}
                alt={siteTexts['about.bottomLeftAlt'] || "Bottom Left Decoration"}
                className="corner-image bottom-left"
            />

            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        {/* Başlık */}
                        <div className="row about-title-section">
                            <div className="col-6">
                                <span className="about-highlight">{siteTexts['about.highlight'] || "Highlight"}</span>
                            </div>
                            <div className="col-6 text-end">
                                <Link to={`/${lang}/about/overview`} className="view-all">
                                    <img src={arrowIcon} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="row about-subtitle-section">
                            <div className="col-12">
                                <p className="about-title">
                                    {siteTexts['about.behindVision'] || "Behind Our Vision"}
                                </p>
                            </div>
                        </div>

                        {/* Açıklama */}
                        <p className="about-description">
                            {siteTexts['about.description1'] || "Description 1"}
                        </p>

                        <div className="row about-subtitle-section">
                            <div className="col-12">
                                <p className="about-title">
                                    {siteTexts['about.missionStatement'] || "Our Mission Statement"}
                                </p>
                            </div>
                        </div>

                        {/* Açıklama2 */}
                        <p className="about-description">
                            {siteTexts['about.description2'] || "Description 2"}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
