// src/admin/DirectorsList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Spinner, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axiosConfig';
import { useTranslation } from 'react-i18next';

// Drag & Drop import (@hello-pangea/dnd)
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DirectorsList = () => {
  const [directorsGroups, setDirectorsGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  const fetchDirectors = async () => {
    try {
      const response = await axiosInstance.get(`/directors`);
      const allDirectors = response.data;

      // group_id'ye göre gruplandırma
      const groups = {};
      allDirectors.forEach((director) => {
        if (!groups[director.group_id]) {
          groups[director.group_id] = {
            group_id: director.group_id,
            en: null,
            tr: null,
          };
        }

        if (director.language === 'en') {
          groups[director.group_id].en = director;
        } else if (director.language === 'tr') {
          groups[director.group_id].tr = director;
        }
      });

      // Grupları diziye çevir
      const groupedDirectors = Object.values(groups);

      // 1) State'e orijinal veriyi atıyoruz
      setDirectorsGroups(groupedDirectors);

      // 2) Sonra localStorage'daki sıralamayı kontrol edip diziyi güncelliyoruz
      const localOrderString = localStorage.getItem('directorsOrder');
      if (localOrderString) {
        const localOrder = JSON.parse(localOrderString); // [ group_id1, group_id2, ... ]

        // localOrder'a göre groupedDirectors'ı yeniden sırala
        const sorted = localOrder
          .map((gid) => groupedDirectors.find((g) => g.group_id === gid))
          .filter(Boolean); // undefined olanları çıkar

        // sorted dizisinin uzunluğu, orijinal dizi uzunluğundan az olabilir
        // bu yüzden geri kalanları eklemek isterseniz:
        const remaining = groupedDirectors.filter(
          (g) => !localOrder.includes(g.group_id)
        );
        const finalSortedArray = [...sorted, ...remaining];

        setDirectorsGroups(finalSortedArray);
      }
    } catch (err) {
      console.error('Direktörler alınamadı:', err);
      setError(t('errors.fetchDirectorsFailed') || 'Direktörler alınamadı.');
      toast.error(t('errors.fetchDirectorsFailed') || 'Direktörler alınamadı.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDirectors();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (groupId) => {
    if (!window.confirm('Bu direktör grubunu silmek istediğinizden emin misiniz?')) return;

    try {
      await axiosInstance.delete(`/directors/${groupId}`);
      setDirectorsGroups(directorsGroups.filter((group) => group.group_id !== groupId));
      toast.success('Direktör grubu başarıyla silindi!');
    } catch (err) {
      console.error('Direktör grubu silinemedi:', err);
      setError('Direktör grubu silinemedi.');
      toast.error('Direktör grubu silinemedi.');
    }
  };

  // Görsel indirme fonksiyonu
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}${imageName}`;
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Drag & Drop bitince çağrılacak fonksiyon
  const onDragEnd = (result) => {
    // Eğer bir hedef yoksa veya aynı konuma bırakıldıysa bir şey yapma
    if (!result.destination) return;

    // Kaynak ve hedef index
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Dizinin kopyası üzerinde elemanları yer değiştir
    const reorderedGroups = Array.from(directorsGroups);
    const [removed] = reorderedGroups.splice(sourceIndex, 1);
    reorderedGroups.splice(destinationIndex, 0, removed);

    // State'i güncelle
    setDirectorsGroups(reorderedGroups);

    // Yeni sıralama bilgisini localStorage'a kaydedelim
    const sortedIds = reorderedGroups.map((g) => g.group_id);
    localStorage.setItem('directorsOrder', JSON.stringify(sortedIds));
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      <h3>Direktörler Yönetimi</h3>
      <Link to="/admin/directors/add">
        <Button variant="primary" className="mb-3">Yeni Direktör Ekle</Button>
      </Link>

      {/* DragDropContext ana sarmalayıcı */}
      <DragDropContext onDragEnd={onDragEnd}>
        {/* Droppable ile tablo gövdesini sarmalıyoruz */}
        <Droppable droppableId="directorsDroppable">
          {(provided) => (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID (EN)</th>
                  <th>İsim (EN)</th>
                  <th>Başlık (EN)</th>
                  <th>Bio (EN)</th>
                  <th>Görsel</th>
                  <th>Başlık (TR)</th>
                  <th>Bio (TR)</th>
                  <th>Aktif</th>
                  <th>İşlemler</th>
                </tr>
              </thead>
              {/* tablo gövdesi */}
              <tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {directorsGroups.map((group, index) => (
                  // Her satır Draggable olarak tanımlanır
                  <Draggable
                    key={group.group_id}
                    draggableId={String(group.group_id)}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* Group ID (TR'den alıyoruz) */}
                        <td>{group.tr?.id}</td>
                        {/* İngilizce */}
                        <td>{group.en ? group.en.name : '-'}</td>
                        <td>{group.en ? group.en.title : '-'}</td>
                        <td>
                          {group.en
                            ? group.en.bio.length > 100
                              ? `${group.en.bio.substring(0, 100)}...`
                              : group.en.bio
                            : '-'}
                        </td>
                        {/* Ortak Görsel */}
                        <td>
                          {group.en && group.en.image ? (
                            <Image
                              src={`${BACKEND_URL}${group.en.image}`}
                              alt={group.en.name}
                              thumbnail
                              style={{ width: '100px' }}
                            />
                          ) : '-'}
                        </td>
                        {/* Türkçe */}
                        <td>{group.tr ? group.tr.title : '-'}</td>
                        <td>
                          {group.tr
                            ? group.tr.bio.length > 100
                              ? `${group.tr.bio.substring(0, 100)}...`
                              : group.tr.bio
                            : '-'}
                        </td>
                        {/* Aktif Durumu */}
                        <td>
                          {group.en && group.en.active ? 'Aktif' : 'Pasif'}
                        </td>
                        {/* İşlemler */}
                        <td>
                          <Link to={`/admin/directors/edit/${group.group_id}`}>
                            <Button variant="warning" size="sm" className="me-2">
                              Düzenle
                            </Button>
                          </Link>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(group.group_id)}
                          >
                            Sil
                          </Button>
                          {/* Görseli İndir */}
                          {group.en && group.en.image && (
                            <Button
                              variant="info"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleDownload(group.en.image)}
                            >
                              Download
                            </Button>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DirectorsList;
