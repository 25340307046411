import React from 'react'
import Investments from '../components/Investments/Investments'

export default function InvestmentsPage() {
  return (
    <div>
        <Investments />
    </div>
  )
}
