// src/components/Gallery/Gallery.jsx
import React, { useState, useEffect } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import { useTranslation } from 'react-i18next';
import './Gallery.css';
import { Alert, Spinner } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import arrowIcon from '../../assets/images/next.svg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Lightbox stil dosyasını import edin

export default function Gallery() {
    const { t } = useTranslation();
    const BACKEND_URL = process.env.REACT_APP_API_URL;

    const [galleries, setGalleries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Lightbox için gerekli state'ler
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const fetchGalleries = async () => {
        try {
            const response = await axiosInstance.get('/gallery', {
                params: { active: 1 } // Sadece aktif galerileri al
            });
            setGalleries(response.data);
        } catch (err) {
            console.error('Galeriler alınamadı:', err);
            setError(t('errors.fetchGalleriesFailed') || 'Galeriler alınamadı.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGalleries();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    // Lightbox içinde gösterilecek görsel URL'lerini array olarak oluşturun
    const images = galleries.map(gallery => `${BACKEND_URL}${gallery.image}`);

    return (
        <section className='gallery-section'>
            <HeaderSection
                title={t('headerSection.gallery')}
                menuText={t('navbar.press')}
                menuText2={t('headerSection.gallery')}
                icon={arrowIcon}
                category="Gallery"
            />
            <div className="container">
                <div className="gallery-grid">
                    {galleries.map((gallery, index) => (
                        <div
                            className="gallery-card"
                            key={gallery.id}
                            onClick={() => { setPhotoIndex(index); setIsLightboxOpen(true); }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => { if (e.key === 'Enter') { setPhotoIndex(index); setIsLightboxOpen(true); } }}
                        >
                            <img
                                src={`${BACKEND_URL}${gallery.image}`}
                                alt={`Gallery ${gallery.id}`}
                                className="gallery-image"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Lightbox Bileşeni */}
            {isLightboxOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                    enableZoom={true} // Zoom özelliğini etkinleştir
                    reactModalStyle={{ overlay: { zIndex: 2000 } }} // Modal overlay z-index'ini artırabilirsiniz
                />
            )}
        </section>
    )
}
