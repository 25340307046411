import React from 'react'
import { useLocation } from 'react-router-dom'
import HeaderSection from '../components/HeaderSection/HeaderSection';
import arrowIcon from '../assets/images/next.svg';

export default function DirectorDetail() {
    const location = useLocation();
    const { name, bio, image, title } = location.state || {};
    const BACKEND_URL = process.env.REACT_APP_API_URL;

    return (
        <>
            <section>
                <HeaderSection
                    title={name}
                    menuText="Directors"
                    menuText2={name}
                    icon={arrowIcon}
                    category="Directors"
                />
                <div className="container py-5">
                    <div className="row d-flex justify-content-between align-items-center">
                        {/* Sol taraf: Başlık ve paragraflar */}
                        <div className="col-md-7">
                            <h2 className='overview-title'>{title}</h2>
                            <p className="overview-text">{bio}</p>
                        </div>
                        {/* Sağ taraf: Görsel */}
                        <div className="col-md-4">
                            <img className='overview-image' src={`${BACKEND_URL}${image}`} alt={name} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
