// src/components/Cookie/CookieBanner.jsx
import React, { useState, useEffect } from "react";
import "./CookieBanner.css"; // CSS dosyasını import ediyoruz
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();
    const { lang } = useParams();

    useEffect(() => {
        // Eğer kullanıcı daha önce karar verdiyse, banner'ı göstermeyin
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);

    const handleAllow = () => {
        localStorage.setItem("cookieConsent", "allowed");
        console.log("Cookies allowed");
        setIsVisible(false); // Banner'ı gizle
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "declined");
        console.log("Cookies declined");
        setIsVisible(false); // Banner'ı gizle
    };

    if (!isVisible) {
        return null; // Eğer banner görünür değilse hiçbir şey render edilmez
    }

    // Cookie Policy sayfasını eklemediyseniz, bu bağlantıyı ilgili sayfaya yönlendirecek şekilde ayarlayın.
    // Örneğin, '/en/cookie-policy' ve '/tr/cookie-policy' gibi.

    return (
        <div className="cookie-banner">
            <p className="cookie-banner-text">
                {t('cookieBanner.text')}
                <Link to={`/${lang}/cookie-policy`} className="cookie-banner-link">
                    {t('cookieBanner.policyLink')}
                </Link>
            </p>
            <div className="cookie-banner-button-container">
                <button
                    className="cookie-banner-decline-button"
                    onClick={handleDecline}
                >
                    {t('cookieBanner.decline')}
                </button>
                <button
                    className="cookie-banner-allow-button"
                    onClick={handleAllow}
                >
                    {t('cookieBanner.allow')}
                </button>
            </div>
        </div>
    );
};

export default CookieBanner;
