import React from 'react'
import Directors from '../components/About/Directors'

export default function DirectorsPage() {
  return (
    <div>
        <Directors />
    </div>
  )
}
