// src/admin/AddEditSocial.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axiosConfig';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';
import imageCompression from 'browser-image-compression';

const AddEditSocial = () => {
    const { id } = useParams();
    const isEdit = Boolean(id);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    const [groupId, setGroupId] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [contentEn, setContentEn] = useState('');
    const [titleTr, setTitleTr] = useState('');
    const [contentTr, setContentTr] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(null); // Görsel URL'si (compressed or original)
    const [backgroundFile, setBackgroundFile] = useState(null); // Görsel dosyası (compressed or original)
    const [croppedImage, setCroppedImage] = useState(null); // Kırpılmış görsel blob'u
    const [existingBackgroundImage, setExistingBackgroundImage] = useState(''); // Mevcut arka plan görseli
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // İlerleme çubuğu için
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [activeTab, setActiveTab] = useState('en');

    useEffect(() => {
        if (isEdit) {
            const fetchSocial = async () => {
                try {
                    const response = await axiosInstance.get(`/social/${id}`);
                    const social = response.data;

                    const socialEn = social.find(item => item.language === 'en');
                    const socialTr = social.find(item => item.language === 'tr');

                    if (socialEn) {
                        setTitleEn(socialEn.title);
                        setContentEn(socialEn.content);
                        setBackgroundImage(`${BACKEND_URL}${socialEn.background_image}`);
                        setExistingBackgroundImage(socialEn.background_image); // Mevcut arka plan görselini ayarla
                        setGroupId(socialEn.group_id);
                    }

                    if (socialTr) {
                        setTitleTr(socialTr.title);
                        setContentTr(socialTr.content);
                        // Arka plan görseli paylaşılıyor, tekrar ayarlamaya gerek yok
                    }
                } catch (err) {
                    setError('Akordiyon alınamadı.');
                    console.error('Akordiyon alınamadı:', err);
                }
            };
            fetchSocial();
        }
    }, [id, isEdit, BACKEND_URL]);

    const handleBackgroundChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile.size / 1024;

            if (fileSizeKB > 600) {
                // 600 KB'den büyükse sıkıştırma işlemi
                const options = {
                    maxSizeMB: 1.5,
                    maxWidthOrHeight: 1920,
                    initialQuality: 0.9,
                    useWebWorker: true,
                };

                try {
                    const compressedFile = await imageCompression(selectedFile, options);
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    console.log("Sıkıştırılmış Görsel URL'si:", compressedImageURL);
                    setBackgroundFile(compressedFile);
                    setBackgroundImage(compressedImageURL);
                    toast.info('Görsel sıkıştırıldı ve kırpma işlemi için hazır.');
                } catch (error) {
                    console.error("Görsel sıkıştırma hatası:", error);
                    setError("Görsel sıkıştırılırken bir hata oluştu.");
                }
            } else {
                // 600 KB'den küçükse sıkıştırma yapmadan direkt olarak kullan
                const originalImageURL = URL.createObjectURL(selectedFile);
                console.log("Orijinal Görsel URL'si:", originalImageURL);
                setBackgroundFile(selectedFile);
                setBackgroundImage(originalImageURL);
                toast.info('Görsel seçildi ve kırpma işlemi için hazır.');
            }

            setShowCropModal(true);
        }
    };

    const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(backgroundImage, croppedAreaPixels);
            setCroppedImage(croppedBlob);
            setShowCropModal(false);
            toast.success('Görsel başarıyla kırpıldı ve kaydedildi.');
        } catch (e) {
            console.error(e);
            toast.error('Görsel kırpma sırasında bir hata oluştu.');
            setError('Görsel kırpma sırasında bir hata oluştu.');
        }
    }, [backgroundImage, croppedAreaPixels]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('title_en', titleEn);
        formData.append('content_en', contentEn);
        formData.append('title_tr', titleTr);
        formData.append('content_tr', contentTr);
        if (croppedImage) {
            formData.append('background_image', croppedImage, 'croppedImage.jpeg');
        }

        try {
            if (isEdit) {
                await axiosInstance.put(`/social/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Akordiyon başarıyla güncellendi!');
                navigate('/admin/social');
            }
        } catch (err) {
            setError('Akordiyon kaydedilemedi.');
            console.error('Akordiyon kaydedilemedi:', err);
            toast.error('Akordiyon kaydedilemedi.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>{isEdit ? 'Akordiyon Düzenle' : 'Akordiyon Eklemeye İzin Verilmiyor'}</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {isEdit ? (
                        <Form onSubmit={handleSubmit}>
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                                className="mb-3"
                            >
                                <Tab eventKey="en" title="English">
                                    <Form.Group controlId="socialTitleEn" className="mb-3">
                                        <Form.Label>Başlık (EN)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={titleEn}
                                            onChange={(e) => setTitleEn(e.target.value)}
                                            required
                                            placeholder="Enter title in English"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="socialContentEn" className="mb-3">
                                        <Form.Label>İçerik (EN)</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            value={contentEn}
                                            onChange={(e) => setContentEn(e.target.value)}
                                            required
                                            placeholder="Enter content in English"
                                        />
                                    </Form.Group>
                                </Tab>
                                <Tab eventKey="tr" title="Türkçe">
                                    <Form.Group controlId="socialTitleTr" className="mb-3">
                                        <Form.Label>Başlık (TR)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={titleTr}
                                            onChange={(e) => setTitleTr(e.target.value)}
                                            required
                                            placeholder="Başlık giriniz (TR)"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="socialContentTr" className="mb-3">
                                        <Form.Label>İçerik (TR)</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            value={contentTr}
                                            onChange={(e) => setContentTr(e.target.value)}
                                            required
                                            placeholder="İçerik giriniz (TR)"
                                        />
                                    </Form.Group>
                                </Tab>
                            </Tabs>

                            <Form.Group controlId="socialBackgroundImage" className="mb-3">
                                <Form.Label>Arka Plan Resmi</Form.Label>
                                <Form.Control type="file" accept="image/*" onChange={handleBackgroundChange} />
                                {existingBackgroundImage && !backgroundFile && !croppedImage && (
                                    <div className="mt-3">
                                        <Form.Label>Mevcut Arka Plan:</Form.Label>
                                        <br />
                                        <img src={`${BACKEND_URL}${existingBackgroundImage}`} alt="Mevcut Arka Plan" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}
                                {uploadProgress > 0 && uploadProgress < 100 && (
                                    <div className="mt-3">
                                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                                    </div>
                                )}
                                {croppedImage && (
                                    <div className="mt-3">
                                        <Form.Label>Seçilen ve Kırpılan Arka Plan:</Form.Label>
                                        <br />
                                        <img src={URL.createObjectURL(croppedImage)} alt="Kırpılan Arka Plan" className="img-thumbnail" style={{ width: '200px' }} />
                                    </div>
                                )}
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        {isEdit ? 'Güncelleniyor...' : 'Ekleniyor...'}
                                    </>
                                ) : (
                                    'Güncelle'
                                )}
                            </Button>
                        </Form>
                    ) : (
                        <Alert variant="info">Yeni akordiyon eklemeye izin verilmemektedir.</Alert>
                    )}
                </Card.Body>
            </Card>

            {/* Crop Modal */}
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Görseli Kırp</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative', height: '400px' }}>
                    <Cropper
                        image={backgroundImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={7 / 5}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropCompleteHandler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={handleCropSave}>
                        Kırp ve Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default AddEditSocial;
