// src/components/Directors.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import './Directors.css';
import { Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useTranslation } from 'react-i18next';

export default function Directors() {
    const navigate = useNavigate();
    const [directors, setDirectors] = useState([]);
    const [error, setError] = useState('');
    const { lang } = useParams();  // Dil parametresini alıyoruz
    const { t } = useTranslation();
    const BACKEND_URL = process.env.REACT_APP_API_URL;

    // Her director objesinin diline göre doğru veriyi almak için
    const handleCardClick = (director) => {
        navigate(`/${lang}/about/directors/${director.id}`, {
            state: {
                name: director.name,
                image: director.image,
                bio: director.bio,
                title: director.title,
            },
        });
    };

    useEffect(() => {
        const fetchDirectors = async () => {
            try {
                const response = await axiosInstance.get(`/directors`, {
                    params: { category: 'About' }
                });
                // Sadece belirli dilde ve aktif olan direktörleri filtrele
                const filteredDirectors = response.data.filter(director => 
                    director.language === lang && director.active === 1
                );
                setDirectors(filteredDirectors);
            } catch (err) {
                console.error('Direktörler alınamadı:', err);
                setError('Direktörler alınamadı.');
            }
        };

        fetchDirectors();
    }, [lang]);

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <section className="about-overview-section">
            <HeaderSection
                title={t('navbar.boardOfDirectors')}
                menuText={t('headerSection.about')}
                menuText2={t('navbar.boardOfDirectors')}
                category="Directors"
                icon={arrowIcon}
            />
            <div className="container">
                <div className="directors-grid">
                    {directors.map((director) => (
                        <div className="director-card" key={director.id} onClick={() => handleCardClick(director)}>
                            <img
                                src={`${BACKEND_URL}${director.image}`}
                                alt={director.name}
                                className="director-image"
                            />
                            <div className="director-layer"></div>
                            <div className="director-info">
                                <p className="director-name">{director.name}</p>
                                <p className="director-title">{director.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
