// src/admin/AddEditDirector.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axiosConfig';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Kırpma için yardımcı fonksiyon
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid'; // UUID oluşturmak için

const AddEditDirector = () => {
    const { id } = useParams();
    const isEdit = Boolean(id);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    // İngilizce Bilgiler
    const [nameEn, setNameEn] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [bioEn, setBioEn] = useState('');

    // Türkçe Bilgiler
    const [nameTr, setNameTr] = useState('');
    const [titleTr, setTitleTr] = useState('');
    const [bioTr, setBioTr] = useState('');

    // Ortak Görsel Durumları
    const [image, setImage] = useState(null); // Görsel URL'si (sıkıştırılmış veya orijinal)
    const [backgroundFile, setBackgroundFile] = useState(null); // Görsel dosyası (sıkıştırılmış veya orijinal)
    const [croppedImage, setCroppedImage] = useState(null); // Kırpılmış görsel blob'u

    const [existingImage, setExistingImage] = useState('');

    const [active, setActive] = useState(true); // Aktif durum ekledik

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // İlerleme çubuğu için

    // Kırpma için Ortak Durumlar
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const [groupId, setGroupId] = useState('');

    useEffect(() => {
        if (isEdit) {
            const fetchDirector = async () => {
                try {
                    const response = await axiosInstance.get(`/directors/${id}`);
                    const directors = response.data;

                    const directorEn = directors.find(d => d.language === 'en');
                    const directorTr = directors.find(d => d.language === 'tr');

                    if (directorEn) {
                        setNameEn(directorEn.name);
                        setTitleEn(directorEn.title);
                        setBioEn(directorEn.bio);
                        setImage(`${BACKEND_URL}${directorEn.image}`);
                        setExistingImage(directorEn.image);
                        setGroupId(directorEn.group_id);
                        setActive(directorEn.active ? true : false); // Aktif durumu ayarla
                    }

                    if (directorTr) {
                        setNameTr(directorTr.name);
                        setTitleTr(directorTr.title);
                        setBioTr(directorTr.bio);
                    }
                } catch (err) {
                    setError('Direktör alınamadı.');
                    console.error('Direktör alınamadı:', err);
                }
            };
            fetchDirector();
        }
    }, [id, isEdit, BACKEND_URL]);

    // Ortak Görsel Değişimi
    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile.size / 1024;

            if (fileSizeKB > 700) {
                // 700 KB'den büyükse sıkıştırma işlemi
                const options = {
                    maxSizeMB: 1.0,
                    maxWidthOrHeight: 1920,
                    initialQuality: 0.95,
                    useWebWorker: true,
                };

                try {
                    const compressedFile = await imageCompression(selectedFile, options);
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    console.log("Sıkıştırılmış Görsel URL'si:", compressedImageURL);
                    setBackgroundFile(compressedFile);
                    setImage(compressedImageURL);
                    toast.info('Görsel sıkıştırıldı ve kırpma işlemi için hazır.');
                } catch (error) {
                    console.error("Görsel sıkıştırma hatası:", error);
                    setError("Görsel sıkıştırılırken bir hata oluştu.");
                }
            } else {
                // 700 KB'den küçükse sıkıştırma yapmadan direkt olarak kullan
                const originalImageURL = URL.createObjectURL(selectedFile);
                console.log("Orijinal Görsel URL'si:", originalImageURL);
                setBackgroundFile(selectedFile);
                setImage(originalImageURL);
                toast.info('Görsel seçildi ve kırpma işlemi için hazır.');
            }

            setShowCropModal(true);
        }
    };

    // Kırpma Tamamlandı
    const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(image, croppedAreaPixels);
            setCroppedImage(croppedBlob);
            setShowCropModal(false);
            toast.success('Görsel başarıyla kırpıldı ve kaydedildi.');
        } catch (e) {
            console.error(e);
            toast.error('Görsel kırpma sırasında bir hata oluştu.');
            setError('Görsel kırpma sırasında bir hata oluştu.');
        }
    }, [image, croppedAreaPixels]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);

        const formData = new FormData();

        // İngilizce Veriler
        formData.append('name_en', nameEn);
        formData.append('title_en', titleEn);
        formData.append('bio_en', bioEn);

        // Türkçe Veriler
        formData.append('name_tr', nameTr);
        formData.append('title_tr', titleTr);
        formData.append('bio_tr', bioTr);

        // Aktif Durum
        formData.append('active', active);

        // Ortak Görsel
        if (croppedImage) {
            formData.append('image', croppedImage, 'croppedImage.jpeg');
        }

        try {
            if (isEdit) {
                await axiosInstance.put(`/directors/${groupId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Direktör grubu başarıyla güncellendi!');
            } else {
                // Yeni bir group_id oluştur
                const newGroupId = uuidv4();
                formData.append('group_id', newGroupId);
                await axiosInstance.post(`/directors`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Yeni direktör grubu başarıyla eklendi!');
            }
            navigate('/admin/directors');
        } catch (err) {
            setError('Direktör grubu kaydedilemedi.');
            console.error('Direktör grubu kaydedilemedi:', err);
            toast.error('Direktör grubu kaydedilemedi.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>{isEdit ? 'Direktör Grubunu Düzenle' : 'Direktör Grubu Ekle'}</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Tabs
                            defaultActiveKey="en"
                            id="director-tabs"
                            className="mb-3"
                        >
                            <Tab eventKey="en" title="English">
                                <Form.Group controlId="directorNameEn" className="mb-3">
                                    <Form.Label>Name (EN)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nameEn}
                                        onChange={(e) => setNameEn(e.target.value)}
                                        required
                                        placeholder="Enter name in English"
                                    />
                                </Form.Group>

                                <Form.Group controlId="directorTitleEn" className="mb-3">
                                    <Form.Label>Title (EN)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={titleEn}
                                        onChange={(e) => setTitleEn(e.target.value)}
                                        required
                                        placeholder="Enter title in English"
                                    />
                                </Form.Group>

                                <Form.Group controlId="directorBioEn" className="mb-3">
                                    <Form.Label>Bio (EN)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={bioEn}
                                        onChange={(e) => setBioEn(e.target.value)}
                                        required
                                        placeholder="Enter bio in English"
                                    />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="tr" title="Türkçe">
                                <Form.Group controlId="directorNameTr" className="mb-3">
                                    <Form.Label>İsim (TR)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nameTr}
                                        onChange={(e) => setNameTr(e.target.value)}
                                        required
                                        placeholder="İsim giriniz (TR)"
                                    />
                                </Form.Group>

                                <Form.Group controlId="directorTitleTr" className="mb-3">
                                    <Form.Label>Başlık (TR)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={titleTr}
                                        onChange={(e) => setTitleTr(e.target.value)}
                                        required
                                        placeholder="Başlık giriniz (TR)"
                                    />
                                </Form.Group>

                                <Form.Group controlId="directorBioTr" className="mb-3">
                                    <Form.Label>Bio (TR)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={bioTr}
                                        onChange={(e) => setBioTr(e.target.value)}
                                        required
                                        placeholder="Bio giriniz (TR)"
                                    />
                                </Form.Group>
                            </Tab>
                        </Tabs>

                        {/* Aktif Durum Kontrolü */}
                        <Form.Group controlId="directorActive" className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                label="Aktif" // İsteğe bağlı olarak çevirilebilir
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                            />
                        </Form.Group>

                        {/* Ortak Görsel Yükleme */}
                        <Form.Group controlId="directorImage" className="mb-3">
                            <Form.Label>Görsel</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                            <Form.Text className="text-muted">
                                Lütfen her iki dil için de kullanılacak ortak bir portre görseli yükleyiniz.
                            </Form.Text>
                            {existingImage && !backgroundFile && !croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Mevcut Görsel:</Form.Label>
                                    <br />
                                    <img src={`${BACKEND_URL}${existingImage}`} alt="Mevcut" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <div className="mt-3">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                                </div>
                            )}
                            {croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Kırpılan Görsel:</Form.Label>
                                    <br />
                                    <img src={URL.createObjectURL(croppedImage)} alt="Kırpılan" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                        </Form.Group>

                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    {isEdit ? 'Güncelleniyor...' : 'Ekleniyor...'}
                                </>
                            ) : (
                                isEdit ? 'Güncelle' : 'Ekle'
                            )}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Ortak Crop Modal */}
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Görseli Kırp</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative', height: '400px' }}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={5 / 7}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropCompleteHandler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={handleCropSave}>
                        Kırp ve Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );

};

export default AddEditDirector;
