import React from 'react'
import Founder from '../components/About/Founder'

export default function FounderPage() {
  return (
    <div>
        <Founder />
    </div>
  )
}
