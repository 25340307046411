import React from 'react'
import Overview from '../components/About/Overview'

export default function OverviewPage() {
  return (
    <div>
        <Overview />
    </div>
  )
}
