// src/components/Seniors/Seniors.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import { Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useTranslation } from 'react-i18next';

export default function Seniors() {
    const navigate = useNavigate();
    const [seniors, setSeniors] = useState([]);
    const [error, setError] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    const handleCardClick = (senior) => {
        navigate(`/${lang}/about/seniors/${senior.id}`, {
            state: {
                name: senior.name,
                image: senior.image,
                bio: senior.bio,
                title: senior.title,
            },
        });
    };

    useEffect(() => {
        const fetchSeniors = async () => {
            try {
                const response = await axiosInstance.get(`/seniors`, {
                    params: { category: 'About' },
                });
                const filteredData = response.data
                    .filter(senior => senior.active === 1)
                    .map(senior => senior[lang]); // İstenirse senior[lang] olarak değiştirilebilir

                setSeniors(filteredData);
            } catch (err) {
                console.error('Üst yönetim üyeleri alınamadı:', err);
                setError('Üst yönetim üyeleri alınamadı.');
            }
        };

        fetchSeniors();
    }, [lang]);

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <section className="about-overview-section">
            <HeaderSection
                title={t('navbar.seniorManagement')}
                menuText={t('headerSection.about')}
                menuText2={t('navbar.seniorManagement')}
                category="Seniors"
                icon={arrowIcon}
            />
            <div className="container">
                <div className="seniors-grid">
                    {seniors.map((senior) => (
                        <div className="senior-card" key={senior.id} onClick={() => handleCardClick(senior)}>
                            <img
                                src={`${BACKEND_URL}${senior.image}`}
                                alt={senior.name}
                                className="senior-image"
                            />
                            <div className="director-layer"></div>
                            <div className="director-info">
                                <p className="director-name">{senior.name}</p>
                                <p className="director-title">{senior.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}