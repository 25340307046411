// src/admin/SeniorsList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Spinner, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from './axiosConfig';
import { useTranslation } from 'react-i18next';

// Drag & Drop import
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const SeniorsList = () => {
  const [seniorsGroups, setSeniorsGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  const fetchSeniors = async () => {
    try {
      const response = await axiosInstance.get(`/seniors`);
      const allSeniors = response.data;
      console.log('allSeniors:', allSeniors);

      // Orijinal API verisini state'e kaydet
      setSeniorsGroups(allSeniors);

      // Local Storage'dan kayıtlı sıra var mı kontrol et
      const localOrderString = localStorage.getItem('seniorsOrder');
      if (localOrderString) {
        const localOrder = JSON.parse(localOrderString); // [group_id1, group_id2, ...]
        // Bu sıraya uygun olacak şekilde allSeniors dizisini yeniden sırala
        const sorted = localOrder
          .map((gid) => allSeniors.find((item) => item.group_id === gid))
          .filter(Boolean);

        // localOrder’da olmayan grup_id’ler varsa, onları da sona ekliyoruz
        const remaining = allSeniors.filter(
          (item) => !localOrder.includes(item.group_id)
        );
        const finalOrder = [...sorted, ...remaining];
        setSeniorsGroups(finalOrder);
      }
    } catch (err) {
      console.error('Üst yönetim üyeleri alınamadı:', err);
      setError(t('errors.fetchSeniorsFailed') || 'Üst yönetim üyeleri alınamadı.');
      toast.error(t('errors.fetchSeniorsFailed') || 'Üst yönetim üyeleri alınamadı.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // IIFE (Immediately Invoked Function Expression) veya
    // fetchSeniors() -> .then() yapısıyla da yazabilirsiniz.
    (async () => {
      await fetchSeniors();
    })();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (groupId) => {
    if (!window.confirm('Bu üst yönetim üyesi grubunu silmek istediğinizden emin misiniz?'))
      return;
    try {
      await axiosInstance.delete(`/seniors/${groupId}`);
      setSeniorsGroups(seniorsGroups.filter((group) => group.group_id !== groupId));
      toast.success('Üst yönetim üyesi grubu başarıyla silindi!');
    } catch (err) {
      console.error('Üst yönetim üyesi grubu silinemedi:', err);
      setError(t('errors.deleteSeniorGroupFailed') || 'Üst yönetim üyesi grubu silinemedi.');
      toast.error(t('errors.deleteSeniorGroupFailed') || 'Üst yönetim üyesi grubu silinemedi.');
    }
  };

  // Görseli indirme fonksiyonu
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}${imageName}`;
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Drag & Drop bitince çağrılacak fonksiyon
  const onDragEnd = (result) => {
    // Eğer bir hedef yoksa veya aynı konuma bırakıldıysa bir şey yapma
    if (!result.destination) return;

    // Kaynak ve hedef index
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Dizinin kopyası üzerinde elemanları yer değiştir
    const reordered = Array.from(seniorsGroups);
    const [removed] = reordered.splice(sourceIndex, 1);
    reordered.splice(destinationIndex, 0, removed);

    // State'i güncelle
    setSeniorsGroups(reordered);

    // Local Storage'a yeni sıralamayı kaydet
    const newOrder = reordered.map((item) => item.group_id);
    localStorage.setItem('seniorsOrder', JSON.stringify(newOrder));
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      <h3>Üst Yönetim Yönetimi</h3>
      <Link to="/admin/seniors/add">
        <Button variant="primary" className="mb-3">
          Yeni Üst Yönetim Üyesi Grubu Ekle
        </Button>
      </Link>

      {/* DragDropContext ana sarmalayıcı */}
      <DragDropContext onDragEnd={onDragEnd}>
        {/* Droppable ile tablo gövdesini sarmalıyoruz */}
        <Droppable droppableId="seniorsDroppable">
          {(provided) => (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>İsim</th>
                  <th>Başlık (EN)</th>
                  <th>Görsel</th>
                  <th>Başlık (TR)</th>
                  <th>Aktif</th>
                  <th>İşlemler</th>
                </tr>
              </thead>

              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {seniorsGroups.map((group, index) => (
                  // Her satırı Draggable olarak tanımlıyoruz
                  <Draggable
                    key={group.group_id}
                    draggableId={String(group.group_id)}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* ID */}
                        <td>{group.tr?.id}</td>
                        {/* İngilizce (name, title) */}
                        <td>{group.en ? group.en.name : '-'}</td>
                        <td>{group.en ? group.en.title : '-'}</td>
                        {/* Görsel */}
                        <td>
                          {group.en && group.en.image ? (
                            <Image
                              src={`${BACKEND_URL}${group.en.image}`}
                              alt={group.en.name}
                              thumbnail
                              style={{ width: '60px' }}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        {/* Türkçe (title) */}
                        <td>{group.tr ? group.tr.title : '-'}</td>
                        {/* Aktif/Pasif */}
                        <td>
                          {group.active ? (
                            <Badge bg="success">Aktif</Badge>
                          ) : (
                            <Badge bg="secondary">Pasif</Badge>
                          )}
                        </td>
                        {/* İşlemler */}
                        <td>
                          <Link to={`/admin/seniors/edit/${group.group_id}`}>
                            <Button variant="warning" size="sm" className="me-2">
                              Düzenle
                            </Button>
                          </Link>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(group.group_id)}
                          >
                            Sil
                          </Button>
                          {/* Görseli İndir */}
                          {group.en && group.en.image && (
                            <Button
                              variant="info"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleDownload(group.en.image)}
                            >
                              Download
                            </Button>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SeniorsList;
