import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaNewspaper } from 'react-icons/fa';
import { AiOutlineArrowRight } from 'react-icons/ai';
import './Welcome.css';

const Welcome = () => {
  const navigate = useNavigate();

  const goToNews = () => {
    navigate('/admin/news');
  };

  return (
    <div className="welcome-background">
      <Container className="welcome-container text-center">
        <div className="welcome-content">
          <FaNewspaper className="welcome-icon" />
          <h1 className="welcome-title">Admin Paneline Hoş Geldiniz!</h1>
          <p className="welcome-text">
            Haberlerinizi kolayca yönetin, yeni haberler ekleyin ve düzenleyin.
            Güçlü ve kullanıcı dostu bir arayüz ile işleriniz hiç olmadığı kadar kolay!
          </p>
          <Button className="welcome-button" onClick={goToNews}>
            <span>Haberleri Yönet</span>
            <AiOutlineArrowRight size={20} />
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Welcome;
