// src/admin/Art.jsx
import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import InvestmentCard from '../Investments/InvestmentCard';
import { Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Art() {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [subtext, setSubtext] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSocials = async () => {
            try {
                const response = await axiosInstance.get(`/social-responsibility`, {
                    params: { category: 'Art' }
                });
                const filteredData = response.data
                    .filter(card => card.active === 1)
                    .map(card => card[lang])
                    .filter(card => card !== null && card !== undefined); // Null veya undefined olanları çıkar

                setCardsData(filteredData);

                // Set the subtext based on the language
                if (lang === 'en') {
                    setSubtext(
                        'MV Holding continues to support art with the permanent art exhibition in Swissotel Buyuk Efes Izmir. The ceramic wall panel of Bedri Rahmi Eyüboğlu, a leading artist of Turkish Contemporary Art, as well as the works of other reputable artists such as Attila Galatali, Nasip Iyem, Beril Anilanmert, Sadi Calik, Cevat Sakir Kabaagacli, Erdogan Ersen and Jale Yilmabasar were restored with special attention during the renovation project ensuring that they receive the respect they deserve and are now being exhibited throughout the hotel in line with its new architectural concept. The rest of the collection consists of modern Turkish artists’ works custom-made to conform with the Hotel’s artistic background and new concept. The Hotel now hosts an art exhibition thanks to this collection including the works of various artists, such as Devrim Erbil, Seckin Pirim, Kemal Onsoy, Alev Gozonar, Mithat Sen, Gunes Cinar and Yasam Sasmazer. A symbol of Izmir, Swissotel Buyuk Efes contributes to the enrichment of the city’s cultural heritage by displaying the world famous bronze statue of Fernando Botero, the worldwide recognized painter and sculptor from Colombia.'
                    );
                } else if (lang === 'tr') {
                    setSubtext(
                        'MV Holding, Swissotel Büyük Efes İzmir’deki kalıcı sanat sergisi ile sanatı desteklemeye devam ediyor. Türk Çağdaş Sanatının önde gelen sanatçısı Bedri Rahmi Eyüboğlu’nun seramik duvar panosunun yanı sıra Attila Galatali, Nasip Iyem, Beril Anilanmert, Sadi Çalık, Cevat Şakir Kabağaçlı, Erdoğan Ersen ve Jale Yılmabaşar gibi diğer saygın sanatçıların eserleri, yenileme projesi sırasında özel bir özenle restore edilerek, hak ettikleri saygıyı görmelerini sağlamış ve artık otelin yeni mimari konsepti ile uyumlu olarak otelin her yerinde sergilenmektedir. Koleksiyonun geri kalanı, Otel’in sanatsal geçmişi ve yeni konseptiyle uyumlu olarak özel olarak yapılmış modern Türk sanatçılarının eserlerinden oluşmaktadır. Otel, Devrim Erbil, Seckin Pirim, Kemal Onsoy, Alev Gozonar, Mithat Sen, Gunes Cinar ve Yasam Sasmazer gibi çeşitli sanatçıların eserlerinden oluşan bu koleksiyon sayesinde bir sanat sergisine ev sahipliği yapmaktadır. İzmir’in simgesi olan Swissotel Büyük Efes, dünyaca ünlü Kolombiyalı ressam ve heykeltıraş Fernando Botero’nun bronz heykelini sergileyerek şehrin kültürel mirasının zenginleşmesine katkıda bulunmaktadır.'
                    );
                }
            } catch (err) {
                console.error('Veriler alınamadı:', err);
                setError('Veriler alınamadı.');
            } finally {
                setLoading(false);
            }
        };
        fetchSocials();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <div>
            <section className='hospitality-section'>
                <HeaderSection 
                    title={t('navbar.art')} 
                    menuText={t('headerSection.socialResponsibility')}
                    menuText2={t('navbar.art')}
                    icon={arrowIcon} 
                    category="Art" 
                />
                <div className="container mt-5">
                    <div className="hospitality-central-section">
                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>
                                    {subtext}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card) => (
                        <InvestmentCard
                            key={card.id}
                            image={card.image ? `${BACKEND_URL}${card.image}` : ''}
                            title={card.title || 'Başlık Yok'}
                            text={card.text || 'Açıklama Yok'}
                            link={card.link || '#'}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
