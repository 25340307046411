// src/admin/SocialResponsibilityList.jsx
import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Alert,
  Spinner,
  DropdownButton,
  Dropdown,
  Image,
  Badge,
  ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from './axiosConfig';
import { useTranslation } from 'react-i18next';

// Drag & Drop import
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const SocialResponsibilityList = () => {
  const [socials, setSocials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const { t } = useTranslation();

  // Hangi alana göre sıralandığını (id, category, title) ve yönünü (asc/desc) tutuyoruz
  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: 'asc',
  });

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  /**
   * Verileri çekme ve localStorage'daki sıralamayı uygulama
   */
  const fetchSocials = async (category = '') => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/social-responsibility`, {
        params: category ? { category } : {},
      });
      const fetchedSocials = response.data;

      // 1) Gelen verileri state'e yaz
      setSocials(fetchedSocials);

      // 2) localStorage'da sıralama var mı? Varsa uygula
      const localOrderString = localStorage.getItem('socialResponsibilityOrder');
      if (localOrderString) {
        const localOrder = JSON.parse(localOrderString); // [group_id1, group_id2, ...]
        // localOrder'daki sıraya göre fetchedSocials dizisini yeniden sırala
        const sorted = localOrder
          .map((gid) => fetchedSocials.find((item) => item.group_id === gid))
          .filter(Boolean);

        // localOrder'da olmayan group_id'leri sona ekle
        const remaining = fetchedSocials.filter(
          (item) => !localOrder.includes(item.group_id)
        );
        setSocials([...sorted, ...remaining]);
      }
    } catch (err) {
      console.error('Sosyal sorumluluklar alınamadı:', err);
      setError(t('errors.fetchSocialsFailed') || 'Sosyal sorumluluklar alınamadı.');
      toast.error(t('errors.fetchSocialsFailed') || 'Sosyal sorumluluklar alınamadı.');
    } finally {
      setLoading(false);
    }
  };

  // Component mount edildiğinde verileri çekiyoruz
  useEffect(() => {
    fetchSocials();
    // eslint-disable-next-line
  }, []);

  /**
   * Sütun başlıklarına tıklayınca sıralama yapar.
   * Aynı sütuna tekrar tıklanırsa yönü (asc <-> desc) değiştirir.
   */
  const handleSortByColumn = (field) => {
    let direction = 'asc';
    if (sortConfig.field === field && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.field === field && sortConfig.direction === 'desc') {
      direction = 'asc';
    }

    setSortConfig({ field, direction });

    // Diziyi sıralayalım
    const sorted = [...socials];
    sorted.sort((a, b) => {
      const valA = getSortableValue(a, field);
      const valB = getSortableValue(b, field);

      // Numerik mi string mi kontrol edelim
      if (typeof valA === 'number' && typeof valB === 'number') {
        return direction === 'asc' ? valA - valB : valB - valA;
      } else {
        return direction === 'asc'
          ? String(valA).localeCompare(String(valB))
          : String(valB).localeCompare(String(valA));
      }
    });

    // Güncellenmiş diziyi state'e yaz
    setSocials(sorted);

    // LocalStorage'a yeni sıralamayı kaydet
    const newOrder = sorted.map((item) => item.group_id);
    localStorage.setItem('socialResponsibilityOrder', JSON.stringify(newOrder));
  };

  /**
   * Sıralanacak alanın gerçek değerini döndürür.
   */
  const getSortableValue = (group, field) => {
    if (field === 'id') {
      return parseInt(group.tr?.id) || 0;
    } else if (field === 'category') {
      return group.en?.category || '';
    } else if (field === 'title') {
      return group.en?.title || '';
    }
    return '';
  };

  /**
   * Drag & Drop bitince çağrılır.
   * Satırların yerini değiştirecek ve localStorage'a kaydedecek.
   */
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const reordered = Array.from(socials);
    const [removed] = reordered.splice(sourceIndex, 1);
    reordered.splice(destinationIndex, 0, removed);

    setSocials(reordered);

    // Local storage'a kaydet
    const newOrder = reordered.map((item) => item.group_id);
    localStorage.setItem('socialResponsibilityOrder', JSON.stringify(newOrder));
  };

  /**
   * Bir grup silme işlemi
   */
  const handleDelete = async (groupId) => {
    if (
      !window.confirm('Bu sosyal sorumluluk grubunu silmek istediğinizden emin misiniz?')
    )
      return;

    try {
      await axiosInstance.delete(`/social-responsibility/${groupId}`);
      setSocials(socials.filter((group) => group.group_id !== groupId));
      toast.success('Sosyal sorumluluk grubu başarıyla silindi!');
    } catch (err) {
      console.error('Sosyal sorumluluk grubu silinemedi:', err);
      setError(t('errors.deleteSocialGroupFailed') || 'Sosyal sorumluluk grubu silinemedi.');
      toast.error(
        t('errors.deleteSocialGroupFailed') || 'Sosyal sorumluluk grubu silinemedi.'
      );
    }
  };

  /**
   * Kategori seçildiğinde veriyi yeniden çeker
   */
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    fetchSocials(category);
  };

  /**
   * Görsel indirme fonksiyonu
   */
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}${imageName}`;
    link.setAttribute('download', imageName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  // Kategorileri dinamik olarak almak için benzersiz kategorileri alıyoruz
  const categories = [
    ...new Set(
      socials.flatMap((group) => [
        group.en?.category, // Optional chaining ile güvenli erişim
        group.tr?.category, // Optional chaining ile güvenli erişim
      ])
    ),
  ].filter(Boolean);

  return (
    <div>
      <h3>Sosyal Sorumluluk Yönetimi</h3>

      {/* Kategori Filtreleme + Yeni Ekle Butonu */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedCategory || 'Kategoriye Göre Filtrele'}
          onSelect={handleCategorySelect}
        >
          <Dropdown.Item eventKey="">Tüm Kategoriler</Dropdown.Item>
          {categories.map((category, index) => (
            <Dropdown.Item key={index} eventKey={category}>
              {category}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Link to="/admin/social-responsibility/add">
          <Button variant="primary">Yeni Sosyal Sorumluluk Grubu Ekle</Button>
        </Link>
      </div>

      {/* Drag & Drop sarmalayıcı */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="socialsDroppable">
          {(provided) => (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortByColumn('id')}
                  >
                    ID
                    {/* Sort göstergesi */}
                    {sortConfig.field === 'id' &&
                      (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                  </th>
                  <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortByColumn('category')}
                  >
                    Kategori (EN)
                    {sortConfig.field === 'category' &&
                      (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                  </th>
                  <th
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortByColumn('title')}
                  >
                    Başlık (EN)
                    {sortConfig.field === 'title' &&
                      (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                  </th>
                  <th>Görsel</th>
                  <th>Başlık (TR)</th>
                  <th>Aktif</th>
                  <th>İşlemler</th>
                </tr>
              </thead>

              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {socials.map((group, index) => (
                  <Draggable
                    key={group.group_id}
                    draggableId={String(group.group_id)}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {/* ID */}
                        <td>{group.tr?.id || group.en?.id || '-'}</td>
                        {/* Kategori (EN) */}
                        <td>{group.en ? group.en.category : '-'}</td>
                        {/* Başlık (EN) */}
                        <td>{group.en ? group.en.title : '-'}</td>
                        {/* Görsel */}
                        <td>
                          {group.en && group.en.image ? (
                            <Image
                              src={`${BACKEND_URL}${group.en.image}`}
                              alt={group.en.title}
                              thumbnail
                              style={{ width: '100px' }}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        {/* Başlık (TR) */}
                        <td>{group.tr ? group.tr.title : '-'}</td>
                        {/* Aktif/Pasif */}
                        <td>
                          {group.active ? (
                            <Badge bg="success">Aktif</Badge>
                          ) : (
                            <Badge bg="secondary">Pasif</Badge>
                          )}
                        </td>
                        {/* İşlemler */}
                        <td>
                          <ButtonGroup>
                            <Link
                              to={`/admin/social-responsibility/edit/${group.group_id}`}
                            >
                              <Button variant="warning" size="sm" className="me-2">
                                Düzenle
                              </Button>
                            </Link>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDelete(group.group_id)}
                            >
                              Sil
                            </Button>
                            {group.en && group.en.image && (
                              <Button
                                variant="info"
                                size="sm"
                                className="ms-2"
                                onClick={() => handleDownload(group.en.image)}
                              >
                                Download
                              </Button>
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SocialResponsibilityList;
