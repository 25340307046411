// src/contexts/SiteTextsContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosConfig';
import { useTranslation } from 'react-i18next';

const SiteTextsContext = createContext();

export const SiteTextsProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [siteTexts, setSiteTexts] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchSiteTexts = async (language) => {
        try {
            const response = await axiosInstance.get('/site-texts', {
                params: { language }
            });
            const texts = {};
            response.data.forEach(text => {
                texts[text.key] = text.content;
            });
            setSiteTexts(texts);
        } catch (err) {
            console.error('Site metinleri alınamadı:', err);
            setError('Site metinleri alınamadı.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchSiteTexts(i18n.language);
    }, [i18n.language]);

    return (
        <SiteTextsContext.Provider value={{ siteTexts, loading, error }}>
            {children}
        </SiteTextsContext.Provider>
    );
};

export const useSiteTexts = () => useContext(SiteTextsContext);
