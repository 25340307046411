// src/admin/AddEditHero.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Tabs, Tab } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Kırpma için yardımcı fonksiyon
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const AddEditHero = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);

  const [titleEn, setTitleEn] = useState('');
  const [subtitleEn, setSubtitleEn] = useState('');
  const [titleTr, setTitleTr] = useState('');
  const [subtitleTr, setSubtitleTr] = useState('');
  const [icon, setIcon] = useState('/uploads/images/logo-big.png'); // Sabit ikon veya dinamik olarak yönetilebilir
  const [buttonLink, setButtonLink] = useState('/#about'); // Sabit buton linki veya dinamik
  const [buttonText, setButtonText] = useState('Learn more'); // Sabit buton metni veya dinamik
  const [image, setImage] = useState(null); // Görsel URL'si
  const [croppedImage, setCroppedImage] = useState(null); // Kırpılmış görsel blob'u
  const [existingImage, setExistingImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [activeTab, setActiveTab] = useState('en');
  const [date, setDate] = useState('');

  // Yeni eklenen durum değişkenleri
  const [idEn, setIdEn] = useState(null);
  const [idTr, setIdTr] = useState(null);

  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  useEffect(() => {
    if (isEdit) {
      const fetchHero = async () => {
        try {
          const response = await axiosInstance.get(`/hero/${id}`);
          let heroGroup = response.data; // Array veya tek bir obje

          console.log("Hero Group Data:", heroGroup); // Debugging amacıyla ekledik

          // Gelen veriyi diziye dönüştür
          if (!Array.isArray(heroGroup)) {
            heroGroup = [heroGroup];
          }

          const heroEn = heroGroup.find(hero => hero.language === 'en');
          const heroTr = heroGroup.find(hero => hero.language === 'tr');

          if (heroEn) {
            setTitleEn(heroEn.title || '');
            setSubtitleEn(heroEn.subtitle || '');
            setExistingImage(heroEn.background || '');
            setIcon(heroEn.icon || '/uploads/images/logo-big.png');
            setButtonLink(heroEn.button_link || '/#about');
            setButtonText(heroEn.button_text || 'Learn more');
            setDate(heroEn.created_at ? heroEn.created_at.split('T')[0] : '');
            setIdEn(heroEn.id);
          }

          if (heroTr) {
            setTitleTr(heroTr.title || '');
            setSubtitleTr(heroTr.subtitle || '');
            setIdTr(heroTr.id);
          }
        } catch (err) {
          setError('Hero öğesi alınamadı.');
          console.error('Hero öğesi alınamadı:', err);
        }
      };
      fetchHero();
    }
  }, [id, isEdit, BACKEND_URL]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileSizeKB = selectedFile.size / 1024; // Dosya boyutunu KB cinsinden hesapla

      if (fileSizeKB > 600) {
        // 600 KB'den büyükse sıkıştırma işlemi
        const options = {
          maxSizeMB: 1.5, // Maksimum boyut (MB)
          maxWidthOrHeight: 1920, // Maksimum genişlik veya yükseklik
          initialQuality: 0.8, // Başlangıç kalitesi
          useWebWorker: true,
        };

        try {
          const compressedFile = await imageCompression(selectedFile, options);
          const imageURL = URL.createObjectURL(compressedFile);
          setImage(imageURL);
          setShowCropModal(true);
        } catch (error) {
          console.error("Görsel sıkıştırma hatası:", error);
          setError("Görsel sıkıştırılırken bir hata oluştu.");
        }
      } else {
        // 600 KB'den küçükse sıkıştırma yapmadan direkt olarak kullan
        const imageURL = URL.createObjectURL(selectedFile);
        setImage(imageURL);
        setCroppedImage(selectedFile); // Direkt olarak seçilen dosyayı kırpılmış görsel olarak ayarla
        setShowCropModal(true);
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(image, croppedAreaPixels, zoom);
      setCroppedImage(croppedBlob);
      setShowCropModal(false);
    } catch (e) {
      console.error(e);
      setError("Görsel kırpma başarısız.");
    }
  }, [image, croppedAreaPixels, zoom]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setUploadProgress(0);

    try {
      // İngilizce Hero Güncelleme
      if (idEn) {
        const formDataEn = new FormData();
        formDataEn.append('icon', icon);
        formDataEn.append('button_link', buttonLink);
        formDataEn.append('button_text', buttonText);
        formDataEn.append('title_en', titleEn);
        formDataEn.append('subtitle_en', subtitleEn);
        if (croppedImage) {
          formDataEn.append('background', croppedImage, 'croppedImage.jpeg');
        }

        await axiosInstance.put(`/hero/${idEn}`, formDataEn, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
      }

      // Türkçe Hero Güncelleme
      if (idTr) {
        const formDataTr = new FormData();
        formDataTr.append('icon', icon);
        formDataTr.append('button_link', buttonLink);
        formDataTr.append('button_text', buttonText);
        formDataTr.append('title_tr', titleTr);
        formDataTr.append('subtitle_tr', subtitleTr);
        if (croppedImage) {
          formDataTr.append('background', croppedImage, 'croppedImage.jpeg');
        }

        await axiosInstance.put(`/hero/${idTr}`, formDataTr, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });
      }

      toast.success('Hero öğesi başarıyla güncellendi!');
      navigate('/admin/hero');
    } catch (err) {
      setError('Hero öğesi kaydedilemedi.');
      console.error('Hero öğesi kaydedilemedi:', err);
      toast.error('Hero öğesi kaydedilemedi.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{isEdit ? 'Hero Düzenle' : 'Hero Ekle'}</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit} noValidate>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="en" title="English">
              <Form.Group controlId="heroTitleEn" className="mb-3">
                <Form.Label>Başlık (EN)</Form.Label>
                <Form.Control
                  type="text"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                  placeholder={"Enter title in English"}
                />
              </Form.Group>

              <Form.Group controlId="heroSubtitleEn" className="mb-3">
                <Form.Label>Alt Başlık (EN)</Form.Label>
                <Form.Control
                  type="text"
                  value={subtitleEn}
                  onChange={(e) => setSubtitleEn(e.target.value)}
                  placeholder={"Enter subtitle in English"}
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="tr" title="Türkçe">
              <Form.Group controlId="heroTitleTr" className="mb-3">
                <Form.Label>Başlık (TR)</Form.Label>
                <Form.Control
                  type="text"
                  value={titleTr}
                  onChange={(e) => setTitleTr(e.target.value)}
                  placeholder={"Başlık (TR)"}
                />
              </Form.Group>

              <Form.Group controlId="heroSubtitleTr" className="mb-3">
                <Form.Label>Alt Başlık (TR)</Form.Label>
                <Form.Control
                  type="text"
                  value={subtitleTr}
                  onChange={(e) => setSubtitleTr(e.target.value)}
                  placeholder={"Alt başlık (TR)"}
                />
              </Form.Group>
            </Tab>
          </Tabs>

          <Form.Group controlId="heroIcon" className="mb-3">
            <Form.Label>İkon</Form.Label>
            <Form.Control
              type="text"
              value={icon}
              onChange={(e) => setIcon(e.target.value)}
              placeholder={"Icon path or URL"}
            />
          </Form.Group>

          <Form.Group controlId="heroButtonLink" className="mb-3">
            <Form.Label>Buton Linki</Form.Label>
            <Form.Control
              type="text"
              value={buttonLink}
              onChange={(e) => setButtonLink(e.target.value)}
              placeholder={"Button link"}
            />
          </Form.Group>

          <Form.Group controlId="heroButtonText" className="mb-3">
            <Form.Label>Buton Metni</Form.Label>
            <Form.Control
              type="text"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              placeholder={"Button text"}
            />
          </Form.Group>

          <Form.Group controlId="heroDate" className="mb-3">
            <Form.Label>Tarih</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="heroBackground" className="mb-3">
            <Form.Label>Arka Plan Resmi</Form.Label>
            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
            {existingImage && !image && isEdit && (
              <div className="mt-3">
                <Form.Label>Mevcut Arka Plan:</Form.Label>
                <br />
                <img src={`${BACKEND_URL}${existingImage}`} alt={"Mevcut Arka Plan"} className="img-thumbnail" style={{ width: '200px' }} />
              </div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="mt-3">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
              </div>
            )}
            {croppedImage && (
              <div className="mt-3">
                <Form.Label>Kırpılmış Arka Plan:</Form.Label>
                <br />
                <img src={URL.createObjectURL(croppedImage)} alt={"Kırpılmış Arka Plan"} className="img-thumbnail" style={{ width: '200px' }} />
              </div>
            )}
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                {isEdit ? "Güncelleniyor..." : "Ekleniyor..."}
              </>
            ) : (
              isEdit ? "Güncelle" : "Ekle"
            )}
          </Button>
        </Form>
      </Card.Body>

      {/* Crop Modal */}
      <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{"Görsel Kırpma"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative', height: '400px' }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9} // İstediğiniz oranı belirleyin
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropModal(false)}>
            {"İptal"}
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            {"Kırp ve Kaydet"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default AddEditHero;
