// src/components/Financial.js
import React, { useState, useEffect } from 'react';
import InvestmentCard from '../Investments/InvestmentCard';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import { Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Financial() {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchInvestments = async () => {
            try {
                const response = await axiosInstance.get(`/investments`, {
                    params: { category: 'Finance' },
                });
                const filteredData = response.data
                    .filter(card => card.active === 1)
                    .map(card => card[lang]);

                setCardsData(filteredData);
            } catch (err) {
                console.error('Yatırımlar alınamadı:', err);
                setError('Yatırımlar alınamadı.');
            } finally {
                setLoading(false);
            }
        };

        fetchInvestments();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <div>
            <section className='hospitality-section'>
                <HeaderSection
                    title={t('headerSection.financial')}
                    menuText={t('investments.title')}
                    menuText2={t('headerSection.financial')}
                    category="Finance"
                    icon={arrowIcon}
                />
                <div className="hospitality-cards-container">
                    {cardsData.length > 0 ? (
                        cardsData.map((card) => (
                            <InvestmentCard
                                key={card.id}
                                image={`${BACKEND_URL}${card.image}`}
                                title={card.title}
                                text={card.text}
                                link={card.link}
                            />
                        ))
                    ) : (
                        <p className="no-data-message text-center" style={{margin: "100px 0"}}>{t('noDataMessage')}</p>
                    )}
                </div>
            </section>
        </div>
    );
}

