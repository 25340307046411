import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import arrowIcon from '../../assets/images/next.svg';
import InvestmentCard from '../Investments/InvestmentCard';
import { Spinner, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Education() {
    const [cardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [subtext, setSubtext] = useState('');
    const { lang } = useParams();
    const { t } = useTranslation();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSocials = async () => {
            try {
                const response = await axiosInstance.get(`/social-responsibility`, {
                    params: { category: 'Education' }
                });
                const filteredData = response.data
                    .filter(card => card.active === 1)
                    .map(card => card[lang]);
                setCardsData(filteredData);
                
                // Set the subtext based on the language
                if (lang === 'en') {
                    setSubtext(
                        'MV Holding aims to contribute to sustainable development primarily in the fields of Education, Environment, Society, and Art by supporting Turkey’s leading foundations, nongovernmental organizations, and initiatives. Murat Vargi pioneered the establishment of TED Istanbul College Foundation in 1997 and he has been supporting the College since then. Murat Vargi is a member of the Board of Trustees of TED Istanbul College. The Foundation provides scholarships to students under the name “TED Torch of Education”. In 2008, the “Birgul Vargi” building, which includes laboratories, university consulting offices, and a library, was opened in TED Istanbul College campus. The Anatolian High School and the Primary School built in Diyarbakir Cermik by KVK in memory of Ercan Demirkol, an employee who lost his life in a plane crash. The school was opened in 2008 and a student dormitory with a bed capacity of 136 was put into service in the same year. MV Holding also supports the Education Reform Initiative at Sabanci University.'
                    );
                } else if (lang === 'tr') {
                    setSubtext(
                        'MV Holding, Eğitim, Çevre, Toplum ve Sanat alanlarında sürdürülebilir kalkınmaya katkıda bulunmayı hedeflemektedir ve Türkiye’nin önde gelen vakıflarını, sivil toplum kuruluşlarını ve girişimlerini desteklemektedir. Murat Vargi, 1997 yılında TED İstanbul Koleji Vakfı’nın kurulmasına öncülük etmiştir ve o zamandan bu yana Kolej’i desteklemektedir. Murat Vargi, TED İstanbul Koleji Mütevelli Heyeti Üyesidir. Vakıf, öğrencilere “TED Eğitim Meşalesi” adıyla burslar sağlamaktadır. 2008 yılında TED İstanbul Koleji kampüsünde, laboratuvarlar, üniversite danışmanlık ofisleri ve bir kütüphaneyi içeren “Birgül Vargi” binası açılmıştır. KVK tarafından Diyarbakır Çermik’te, hayatını kaybeden bir çalışanı Ercan Demirkol anısına yapılan Anadolu Lisesi ve İlkokul açılmıştır. 2008 yılında okul açılmış ve aynı yıl 136 yatak kapasiteli öğrenci yurdu hizmete girmiştir. MV Holding ayrıca Sabancı Üniversitesi’nde Eğitim Reformu Girişimi’ni desteklemektedir.'
                    );
                }
            } catch (err) {
                console.error('Veriler alınamadı:', err);
                setError('Veriler alınamadı.');
            } finally {
                setLoading(false);
            }
        };
        fetchSocials();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    return (
        <div>
            <section className='hospitality-section'>
                <HeaderSection 
                    title={t('navbar.education')}
                    menuText={t('headerSection.socialResponsibility')} 
                    menuText2={t('navbar.education')}
                    icon={arrowIcon} 
                    category="Education"
                />
                <div className="container mt-5">
                    <div className="hospitality-central-section">
                        <div className="row">
                            <div className="col-12">
                                <p className='hospitality-subtext'>
                                    {subtext}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hospitality-cards-container">
                    {cardsData.map((card) => (
                        <InvestmentCard
                            key={card.id}
                            image={`${BACKEND_URL}${card.image}`}
                            title={card.title}
                            text={card.text}
                            link={card.link}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
