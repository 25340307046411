// src/components/Social/Social.jsx
import React, { useState, useEffect } from 'react';
import './Social.css';
import arrowUp from '../../assets/images/arrowUp.svg';
import arrowDown from '../../assets/images/arrowDown.svg';
import { Spinner } from 'react-bootstrap';
import axiosInstance from '../../axiosConfig';
import { useSiteTexts } from '../../context/SiteTextsContext';
import { Link, useParams } from 'react-router-dom';
import arrowIcon from "../../assets/images/arrow-narrow-right.svg";

function AccordionItem({ title, backgroundImage, content, isActive, onClick }) {
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        if (isActive) {
            setHeight('640px'); // İstediğiniz yüksekliği buraya koyabilirsiniz
        } else {
            setHeight('0px');
        }
    }, [isActive]);

    return (
        <div className={`social-accordion-item ${isActive ? 'expanded' : ''}`}>
            {!isActive && (
                <button
                    className={`social-accordion-header ${isActive ? 'active' : ''}`}
                    onClick={onClick}
                    aria-expanded={isActive}
                    aria-controls={`accordion-content-${title}`}
                >
                    {title}
                    <span>
                        <img src={arrowDown} alt="Arrow Down" />
                    </span>
                </button>
            )}
            <div
                id={`accordion-content-${title}`}
                className={`social-accordion-content ${isActive ? 'open' : 'closed'}`}
                style={{ backgroundImage: `url(${backgroundImage})`, height: height }}
            >
                <div className="social-accordion-header-content">
                    <h2 className="social-accordion-title">{title}</h2>
                    <button
                        type="button"
                        className="close-icon"
                        onClick={onClick}
                        aria-label="Close Accordion"
                    >
                        <img src={arrowUp} alt="Arrow Up" />
                    </button>
                </div>
                <div className="social-accordion-footer">
                    <p>{content}</p>
                </div>
            </div>
        </div>
    );
}

export default function Social() {
    const { siteTexts, loading } = useSiteTexts();
    const [activeAccordion, setActiveAccordion] = useState(null); // group_id kullanarak yönetilecek
    const [socialGroups, setSocialGroups] = useState([]); // Gruplandırılmış akordiyonlar
    const { lang } = useParams();

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001'; // Varsayılan değer

    const handleAccordionClick = (groupId) => {
        setActiveAccordion((prev) => (prev === groupId ? null : groupId));
    };

    useEffect(() => {
        const fetchAccordions = async () => {
            try {
                const response = await axiosInstance.get(`/social`, {
                    params: { language: lang }
                });
                const allAccordions = response.data;

                // group_id'ye göre gruplandırma
                const groups = {};
                allAccordions.forEach(acc => {
                    if (!groups[acc.group_id]) {
                        groups[acc.group_id] = {
                            group_id: acc.group_id,
                            title: acc.title,
                            background_image: acc.background_image,
                            content: acc.content
                        };
                    }
                });

                // groups objesini diziye çevir
                let groupedSocial = Object.values(groups);

                // İstediğiniz sıraya göre diziyi sort’la
                const desiredOrder = [
                    '618f6bd6-c5d1-11ef-b62a-008cfaf20aa0', // Environment/Çevre
                    '618f5e2f-c5d1-11ef-b62a-008cfaf20aa0', // Art/Sanat
                    '618f6d5a-c5d1-11ef-b62a-008cfaf20aa0', // Education/Eğitim
                    '618f6959-c5d1-11ef-b62a-008cfaf20aa0', // Society/Toplum
                ];

                groupedSocial = groupedSocial.sort((a, b) => {
                    return (
                        desiredOrder.indexOf(a.group_id) - desiredOrder.indexOf(b.group_id)
                    );
                });
                setSocialGroups(groupedSocial);
            } catch (err) {
                console.error('Akordiyonlar alınamadı:', err);
            }
        };

        fetchAccordions();
    }, [lang]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (
        <div className="social-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        {/* Header */}
                        <div className="row social-title-section">
                            <div className="col-md-8 col-7">
                                <span className="social-highlight">{siteTexts['social.title']}</span>
                            </div>
                            <div className="col-md-4 col-5 text-end">
                                <Link to={`/${lang}/social/environment`} className="view-all" onClick={() => window.scrollTo(0, 0)}>
                                    <img src={arrowIcon} alt="" />
                                </Link>
                            </div>
                        </div>

                        <div className="row social-subtitle-section">
                            <div className="col-12">
                                <p className="social-title">
                                    {siteTexts['social.subtitle']}
                                </p>
                            </div>
                        </div>

                        {/* Description */}
                        <p className="social-description">
                            {siteTexts['social.description']}
                        </p>
                        {/* Accordion */}
                        <div className="accordion-section">
                            {socialGroups.map((group) => (
                                <AccordionItem
                                    key={group.group_id}
                                    title={group.title}
                                    backgroundImage={`${BACKEND_URL}${group.background_image}`}
                                    content={group.content}
                                    isActive={activeAccordion === group.group_id}
                                    onClick={() => handleAccordionClick(group.group_id)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
