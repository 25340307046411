import React from 'react'
import Contact from '../components/Contact/Contact'

export default function ContactPage() {
  return (
    <div>
        <Contact />
    </div>
  )
}
