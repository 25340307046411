// src/components/Layout.jsx
import React, { useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import { useTranslation } from 'react-i18next';

export default function Layout() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && ['en', 'tr'].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      // Desteklenen dillerden biri değilse varsayılan dil olarak İngilizceye yönlendir
      navigate('/en', { replace: true });
    }
  }, [lang, i18n, navigate]);

  return (
    <>
      <Navbar />
      <Outlet context={{ lang }} />
      <Footer />
    </>
  );
}
