// src/pages/Overview.jsx
import React, { useState, useEffect } from 'react';
import './Overview.css';
import arrowIcon from '../../assets/images/next.svg';
import HeaderSection from '../HeaderSection/HeaderSection';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosConfig'; // Doğru yolun sağlandığından emin olun
import DOMPurify from 'dompurify';
import fileIcon from '../../assets/images/file-icon.svg';

export default function Overview() {
    const { t, i18n } = useTranslation();
    const [overview, setOverview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchOverview = async () => {
            try {
                const response = await axiosInstance.get('/overview');
                // Eğer backend bir dizi dönerse ilk elemanı al
                const data = Array.isArray(response.data) ? response.data[0] : response.data;
                setOverview(data);
            } catch (err) {
                setError('Overview yüklenemedi.');
                console.error('Overview yüklenemedi:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchOverview();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !overview) {
        return <div className="alert alert-danger" role="alert">{error || 'Overview bulunamadı.'}</div>;
    }

    const currentLanguage = i18n.language || 'en';

    const title = currentLanguage === 'tr' ? overview.title_tr : overview.title_en;
    const content = currentLanguage === 'tr' ? overview.content_tr : overview.content_en;
    const pdfUrl = overview.pdf ? `${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${overview.pdf}` : null;

    return (
        <section className='about-overview-section'>
            <HeaderSection
                title={title}
                menuText={t('headerSection.about')}
                category="Overview"
                icon={arrowIcon}
            />
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6">
                        <h2 className='overview-title'>{title}</h2>
                        <div
                            className="overview-text"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
                        />
                        <div className='cookie-file'>
                            <img src={fileIcon} className='cookie-file-icon' alt="" />
                            {pdfUrl ? (
                                <a href={pdfUrl} className='cookie-file-link' target='_blank' rel='noopener noreferrer'>
                                    <span className='cookie-file-text'>{t('navbar.timeline')}</span>
                                    <span className='cookie-file-subtext'> {t("cookiePolicy.subtext")} </span>
                                </a>
                            ) : (
                                <span className='cookie-file-link text-muted'>
                                    <span className='cookie-file-text'>{t('navbar.timeline')}</span>
                                    <span className='cookie-file-subtext'> {t("cookiePolicy.subtext")} </span>
                                    <span className='text-danger ms-2'>(PDF Bulunamadı)</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img
                            className='overview-image'
                            src={`${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${overview.image}`}
                            alt={title}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
