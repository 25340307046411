// src/admin/EditOverview.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Image } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Helper function for cropping
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill'; // Quill component
import 'react-quill/dist/quill.snow.css'; // Quill CSS

const EditOverview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [overview, setOverview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [image, setImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [existingImage, setExistingImage] = useState('');
    const [pdf, setPdf] = useState(null);
    const [existingPdf, setExistingPdf] = useState('');
    const [uploadPdfProgress, setUploadPdfProgress] = useState(0);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    // Quill Content States
    const [contentEn, setContentEn] = useState('');
    const [contentTr, setContentTr] = useState('');

    useEffect(() => {
        const fetchOverview = async () => {
            try {
                const response = await axiosInstance.get(`/overview/${id}`);
                setOverview(response.data);
                setExistingImage(response.data.image);
                setExistingPdf(response.data.pdf);
                setContentEn(response.data.content_en || '');
                setContentTr(response.data.content_tr || '');
            } catch (err) {
                setError('Failed to load overview.');
                console.error('Failed to load overview:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchOverview();
    }, [id]);

    // Handle Image Change
    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile.size / 1024;

            if (fileSizeKB > 600) {
                const options = {
                    maxSizeMB: 1.5,
                    maxWidthOrHeight: 1920,
                    initialQuality: 0.8,
                    useWebWorker: true,
                };

                try {
                    const compressedFile = await imageCompression(selectedFile, options);
                    const imageURL = URL.createObjectURL(compressedFile);
                    setImage(imageURL);
                    setCroppedImage(compressedFile);
                    setShowCropModal(true);
                } catch (error) {
                    console.error("Image compression error:", error);
                    setError("Failed to compress image.");
                }
            } else {
                const imageURL = URL.createObjectURL(selectedFile);
                setImage(imageURL);
                setCroppedImage(selectedFile);
                setShowCropModal(true);
            }
        }
    };

    // Handle PDF Change
    const handlePdfChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedPdf = e.target.files[0];
            if (selectedPdf.type !== 'application/pdf') {
                setError("Only PDF files are allowed.");
                return;
            }
            setPdf(selectedPdf);
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(image, croppedAreaPixels, zoom);
            setCroppedImage(croppedBlob);
            setShowCropModal(false);
        } catch (e) {
            console.error(e);
            setError("Failed to crop image.");
        }
    }, [image, croppedAreaPixels, zoom]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);
        setUploadPdfProgress(0);

        try {
            const formData = new FormData();
            formData.append('title_en', overview.title_en);
            formData.append('title_tr', overview.title_tr);
            formData.append('content_en', contentEn);
            formData.append('content_tr', contentTr);

            // Append Image if updated
            if (croppedImage) {
                formData.append('image', croppedImage, 'croppedImage.jpeg');
            }

            // Append PDF if updated
            if (pdf) {
                formData.append('pdf', pdf, pdf.name);
            }

            // Debug: Log FormData entries
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}:`, pair[1]);
            }

            await axiosInstance.put(`/overview/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    // Assuming image and PDF are uploaded simultaneously, split progress
                    setUploadProgress(Math.min(percentCompleted, 100));
                },
            });
            toast.success('Overview updated successfully!');
            navigate('/admin/overview');
        } catch (err) {
            setError('Failed to update overview.');
            console.error('Failed to update overview:', err);
            toast.error('Failed to update overview.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Edit Overview</Card.Title>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} noValidate>
                    {/* English Title */}
                    <Form.Group controlId="overviewTitleEn" className="mb-3">
                        <Form.Label>Title (EN)</Form.Label>
                        <Form.Control
                            type="text"
                            value={overview.title_en}
                            onChange={(e) => setOverview({ ...overview, title_en: e.target.value })}
                            placeholder="Enter English title"
                            required
                        />
                    </Form.Group>
                    {/* Turkish Title */}
                    <Form.Group controlId="overviewTitleTr" className="mb-3">
                        <Form.Label>Title (TR)</Form.Label>
                        <Form.Control
                            type="text"
                            value={overview.title_tr}
                            onChange={(e) => setOverview({ ...overview, title_tr: e.target.value })}
                            placeholder="Enter Turkish title"
                            required
                        />
                    </Form.Group>
                    {/* English Content */}
                    <Form.Group controlId="overviewContentEn" className="mb-3">
                        <Form.Label>Content (EN)</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={contentEn}
                            onChange={setContentEn}
                            placeholder="Enter English content"
                        />
                    </Form.Group>
                    {/* Turkish Content */}
                    <Form.Group controlId="overviewContentTr" className="mb-3">
                        <Form.Label>Content (TR)</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={contentTr}
                            onChange={setContentTr}
                            placeholder="Enter Turkish content"
                        />
                    </Form.Group>

                    {/* Image Upload */}
                    <Form.Group controlId="overviewImage" className="mb-3">
                        <Form.Label>Overview Image</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                        {/* Existing Image */}
                        {existingImage && !image && (
                            <div className="mt-3">
                                <Form.Label>Existing Image:</Form.Label>
                                <br />
                                <Image src={`${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${existingImage}`} alt="Existing Overview" thumbnail style={{ width: '200px' }} />
                            </div>
                        )}
                        {/* Upload Progress */}
                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <div className="mt-3">
                                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                            </div>
                        )}
                        {/* Cropped Image Preview */}
                        {croppedImage && (
                            <div className="mt-3">
                                <Form.Label>Cropped Image:</Form.Label>
                                <br />
                                <Image src={URL.createObjectURL(croppedImage)} alt="Cropped Overview" thumbnail style={{ width: '200px' }} />
                            </div>
                        )}
                    </Form.Group>

                    {/* PDF Upload */}
                    <Form.Group controlId="overviewPdf" className="mb-3">
                        <Form.Label>Overview PDF</Form.Label>
                        <Form.Control type="file" accept="application/pdf" onChange={handlePdfChange} />
                        {/* Existing PDF */}
                        {existingPdf && !pdf && (
                            <div className="mt-3">
                                <Form.Label>Existing PDF:</Form.Label>
                                <br />
                                <a href={`${process.env.REACT_APP_API_URL || 'http://localhost:5001'}${existingPdf}`} target="_blank" rel="noopener noreferrer">
                                    View Existing PDF
                                </a>
                            </div>
                        )}
                        {/* Upload PDF Progress */}
                        {uploadPdfProgress > 0 && uploadPdfProgress < 100 && (
                            <div className="mt-3">
                                <ProgressBar now={uploadPdfProgress} label={`${uploadPdfProgress}%`} animated />
                            </div>
                        )}
                        {/* New PDF Preview */}
                        {pdf && (
                            <div className="mt-3">
                                <Form.Label>Selected PDF:</Form.Label>
                                <br />
                                <a href={URL.createObjectURL(pdf)} target="_blank" rel="noopener noreferrer">
                                    {pdf.name}
                                </a>
                            </div>
                        )}
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                Updating...
                            </>
                        ) : (
                            "Update"
                        )}
                    </Button>
                </Form>
            </Card.Body>

            {/* Image Crop Modal */}
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative', height: '400px' }}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={7 / 5}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCropSave}>
                        Crop & Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );

};

export default EditOverview;
