// src/components/News/News.jsx
import React, { useRef, useEffect, useState } from "react";
import "./News.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ReactComponent as PrevIcon } from "../../assets/images/prev.svg";
import { ReactComponent as NextIcon } from "../../assets/images/next.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Alert } from "react-bootstrap";
import axiosInstance from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import arrowIcon from "../../assets/images/arrow-narrow-right.svg";
import { useSiteTexts } from "../../context/SiteTextsContext";

export default function News() {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { lang } = useParams();
    const { siteTexts, loading: siteTextsLoading, error: siteTextsError } = useSiteTexts();

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Function to fetch news from the API
        const fetchNews = async () => {
            try {
                const response = await axiosInstance.get(`/news`, {
                    params: { language: lang } // Add language parameter
                });

                const filteredNews = response.data.filter(newsItem => 
                    newsItem.active === 1 &&
                    newsItem.image && // Ensure image exists
                    !newsItem.image.toLowerCase().endsWith('default.jpg') // Exclude default.png
                );

                // Sort the news by date in descending order (newest first)
                const sortedNews = filteredNews.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Take the latest 10 news items
                const limitedNews = sortedNews.slice(0, 10);

                // Update the state with the filtered, sorted, and limited news
                setNews(limitedNews);
            } catch (err) {
                console.error('Haberler alınamadı:', err);
                const errorMessage = t('errors.fetchPressNewsFailed') || "Haberler alınamadı.";
                setError(errorMessage);
                toast.error(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, [lang, t]);

    useEffect(() => {
        if (
            swiperRef.current &&
            swiperRef.current.params.navigation
        ) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;

            // Initialize navigation
            swiperRef.current.navigation.destroy();
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, [news]);

    const handleSlideClick = (id) => {
        navigate(`/${lang}/press/news/${id}`);
        window.scrollTo(0, 0);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger" className="text-center">{error}</Alert>;
    }

    if (siteTextsLoading) {
        return <div>Loading...</div>;
      }
    
      if (siteTextsError) {
        return <div className="alert alert-danger" role="alert">{siteTextsError}</div>;
      }

    return (
        <section className="news-section">
            <div className="container">
                {/* Header */}
                <div className="row news-title-section">
                    <div className="col-6">
                        <span className="news-highlight">{t('news.title') || "News"}</span>
                    </div>
                    <div className="col-6 text-end">
                        <Link to={`/${lang}/press/news`} className="view-all">
                            <img src={arrowIcon} alt="" />
                        </Link>
                    </div>
                </div>

                <div className="row news-subtitle-section">
                    <div className="col-12">
                        <p className="news-title">{siteTexts['news.subtitle'] || t('news.subtitle')}</p>
                    </div>
                </div>

                {/* Description */}
                <p className="news-description">
                    {siteTexts['news.description'] || t('news.description')}
                </p>

                {/* Slider Container for Positioning */}
                <div className="news-slider-container">
                    {/* Slider */}
                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        modules={[Navigation, Pagination]}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            768: { slidesPerView: 2 }, // Show two slides on desktop
                        }}
                        className="news-slider"
                    >
                        {news.map((newsItem) => (
                            <SwiperSlide key={newsItem.id} className="news-slide">
                                <div
                                    className="news-card"
                                    onClick={() => handleSlideClick(newsItem.id)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') handleSlideClick(newsItem.id);
                                    }}
                                >
                                    <img
                                        src={newsItem.image ? `${BACKEND_URL}${newsItem.image}` : '/path/to/placeholder.jpg'}
                                        alt={newsItem.title}
                                        className="news-image"
                                        loading="lazy" // Add lazy loading for performance
                                    />
                                    <div className="news-overlay">
                                        <span className="news-date">
                                            {new Intl.DateTimeFormat(lang === 'tr' ? 'tr-TR' : 'en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(newsItem.date))}
                                        </span>
                                    </div>
                                    <p className="news-card-title">{newsItem.title}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Custom Navigation Buttons */}
                    <button ref={prevRef} className="custom-nav custom-prev" aria-label="Previous Slide">
                        <PrevIcon />
                    </button>
                    <button ref={nextRef} className="custom-nav custom-next" aria-label="Next Slide">
                        <NextIcon />
                    </button>
                </div>
            </div>
        </section>
    );
}
