import React from 'react'
import Seniors from '../components/About/Seniors'

export default function SeniorsPage() {
  return (
    <div>
        <Seniors />
    </div>
  )
}
