import React from 'react'
import Press from '../components/Press/Press'

export default function PressPage() {
  return (
    <div>
        <Press />
    </div>
  )
}
