// src/components/Popup/Popup.jsx
import React, { useState, useEffect } from 'react';
import './Popup.css';
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';

const Popup = () => {
  const [popupData, setPopupData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  
  // URL'de :lang parametresi tanımladığımızı varsayıyoruz (örn. /en/home, /tr/home, vb.)
  // veya /:lang/popup, projenize göre değişebilir.
  const { lang } = useParams();
  
  useEffect(() => {
    // Aktif pop-up verilerini backend'den çek (dil parametresiyle)
    const fetchPopup = async () => {
      try {
        // lang yoksa varsayılan (örn. en)
        const currentLang = lang || 'en';
        // ?lang=currentLang şeklinde sorgu gönderiyoruz
        const response = await axiosInstance.get('/popup/active', {
          params: { lang: currentLang }
        });

        // Eğer veri geldiyse ve isVisible = 1 ise gösterelim
        if (response.data && response.data.isVisible) {
          setPopupData(response.data);
          setIsVisible(true);
        }
      } catch (error) {
        console.error('Pop-up verileri alınamadı:', error);
      }
    };

    fetchPopup();
  }, [lang]); // lang değiştikçe yeniden çek

  const handleClose = () => {
    setIsVisible(false);
  };

  // Pop-up görünmüyorsa veya veri yoksa null dön
  if (!isVisible || !popupData) {
    return null;
  }

  // Popup verileri
  const { backgroundType, backgroundValue, title, message } = popupData;

  const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  // Stil ayarlama
  const popupStyle =
    backgroundType === 'color'
      ? { backgroundColor: backgroundValue }
      : {
          backgroundImage: `url(${BACKEND_URL}${backgroundValue})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };

  return (
    <div className="popup-overlay">
      <div className="popup-content" style={popupStyle}>
        <button className="popup-close" onClick={handleClose}>
          &times;
        </button>
        <h2 className="popup-title">{title}</h2>
        <p className="popup-message">{message}</p>
      </div>
    </div>
  );
};

export default Popup;
