// AddEditHeaderSection.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Kırpma için yardımcı fonksiyon
import imageCompression from 'browser-image-compression';

const AddEditHeaderSection = () => {
    const [category, setCategory] = useState('');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [existingImage, setExistingImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const navigate = useNavigate();
    const { category: routeCategory } = useParams();

    const isEdit = Boolean(routeCategory);

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    // Kategori seçeneklerini burada tanımlıyoruz
    const categoryOptions = [
        'About',
        'Directors',
        'Seniors',
        'Founder',
        'Overview',
        'Energy',
        'Hospitality',
        'Technology',
        'Investments',
        'News',
        'Social',
        'Art',
        'Education',
        'Society',
        'Environment',
        'Gallery'
    ];

    useEffect(() => {
        if (isEdit) {
            const fetchHeaderSection = async () => {
                try {
                    const response = await axiosInstance.get(`/header-sections/${routeCategory}`);
                    setCategory(response.data.category);
                    setExistingImage(`${BACKEND_URL}/${response.data.background_image}`);
                } catch (err) {
                    setError('Header Section alınamadı.');
                    console.error('Header Section alınamadı:', err);
                }
            };
            fetchHeaderSection();
        }
    }, [isEdit, routeCategory, BACKEND_URL]);

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const options = {
                maxSizeMB: 1.5, // Maksimum dosya boyutunu artırın (örneğin, 1.5 MB)
                maxWidthOrHeight: 1920, // Görselin maksimum genişlik/yüksekliğini koruyun
                initialQuality: 0.9, // Görselin kalite seviyesini artırın (0-1 arasında)
                useWebWorker: true, // Performansı artırmak için Web Worker kullanın
            };

            try {
                const compressedFile = await imageCompression(selectedFile, options);
                const imageURL = URL.createObjectURL(compressedFile);
                console.log("Sıkıştırılmış Görsel URL'si:", imageURL);
                setBackgroundImage(imageURL);
                setShowCropModal(true);
            } catch (error) {
                console.error("Görsel sıkıştırma hatası:", error);
                setError("Görsel sıkıştırılırken bir hata oluştu.");
            }
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(backgroundImage, croppedAreaPixels, zoom);
            setCroppedImage(croppedBlob);
            setShowCropModal(false);
        } catch (e) {
            console.error(e);
            toast.error('Görsel kırpma sırasında bir hata oluştu.');
            setError('Görsel kırpma sırasında bir hata oluştu.');
        }
    }, [backgroundImage, croppedAreaPixels, zoom]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('category', category);
        if (croppedImage) {
            formData.append('background_image', croppedImage, 'croppedImage.jpeg');
        }

        try {
            if (isEdit) {
                await axiosInstance.put(`/header-sections/${routeCategory}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Header Section başarıyla güncellendi!');
            } else {
                await axiosInstance.post('/header-sections', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Yeni Header Section başarıyla eklendi!');
            }
            navigate('/admin/header-sections');
        } catch (err) {
            setError('Header Section kaydedilemedi.');
            console.error('Header Section kaydedilemedi:', err);
            toast.error('Header Section kaydedilemedi.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>{isEdit ? 'Header Section Düzenle' : 'Header Section Ekle'}</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="category" className="mb-3">
                            <Form.Label>Kategori</Form.Label>
                            <Form.Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                                disabled={isEdit} // Kategoriyi düzenlenebilir hale getirmek istemiyorsanız disable edebilirsiniz
                            >
                                <option value="">Kategori seçiniz</option>
                                {categoryOptions.map((cat) => (
                                    <option key={cat} value={cat}>
                                        {cat}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="backgroundImage" className="mb-3">
                            <Form.Label>Arka Plan Görseli</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                            {existingImage && !backgroundImage && !croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Mevcut Görsel:</Form.Label>
                                    <br />
                                    <img src={existingImage} alt="Mevcut Görsel" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <div className="mt-3">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                                </div>
                            )}
                            {croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Seçilen ve Kırpılan Görsel:</Form.Label>
                                    <br />
                                    <img src={URL.createObjectURL(croppedImage)} alt="Kırpılan Görsel" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    {isEdit ? 'Güncelleniyor...' : 'Ekleniyor...'}
                                </>
                            ) : (
                                isEdit ? 'Güncelle' : 'Ekle'
                            )}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Crop Modal */}
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Görseli Kırp</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative', height: '400px' }}>
                    <Cropper
                        image={backgroundImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 1} // Örneğin, 4:1 oranında kırpma
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={handleCropSave}>
                        Kırp ve Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddEditHeaderSection;
