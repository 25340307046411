// src/admin/AddEditGallery.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Image } from 'react-bootstrap';
import axiosInstance from './axiosConfig';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const AddEditGallery = () => {
    const { id } = useParams();
    const isEdit = Boolean(id);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_API_URL;

    // Varsayılan kategori değeri
    const DEFAULT_CATEGORY = 'default_category'; // Buraya backend'in beklediği varsayılan kategori değerini giriniz

    const [gallery, setGallery] = useState(null);
    const [active, setActive] = useState(true);
    const [image, setImage] = useState(null); // Seçilen veya sıkıştırılmış görsel
    const [existingImage, setExistingImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (isEdit) {
            const fetchGallery = async () => {
                try {
                    const response = await axiosInstance.get(`/gallery/${id}`);
                    const fetchedGallery = response.data;
                    setGallery(fetchedGallery);
                    setActive(fetchedGallery.active ? true : false);
                    setExistingImage(fetchedGallery.image);
                } catch (err) {
                    setError(t('errors.fetchGalleryFailed') || 'Galeri alınamadı.');
                    console.error('Galeri alınamadı:', err);
                }
            };
            fetchGallery();
        }
    }, [id, isEdit, t]);

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile.size / 1024; // Dosya boyutunu KB cinsinden hesapla

            // 600 KB'den büyükse sıkıştırma işlemi
            if (fileSizeKB > 600) {
                try {
                    // Sıkıştırma için uygun seçenekler
                    const options = {
                        maxSizeMB: 1.5, // Maksimum boyut (MB)
                        maxWidthOrHeight: 1920, // Maksimum genişlik veya yükseklik
                        initialQuality: 0.9, // Başlangıç kalitesi
                        useWebWorker: true,
                    };

                    const compressedFile = await imageCompression(selectedFile, options);
                    setImage(compressedFile); // Sıkıştırılmış dosyayı ayarla
                } catch (error) {
                    console.error("Görsel sıkıştırma hatası:", error);
                    setError(t('errors.imageCompressionFailed') || "Görsel sıkıştırma başarısız.");
                }
            } else {
                // 600 KB'den küçükse sıkıştırma yapmadan direkt olarak kullan
                setImage(selectedFile); // Direkt olarak seçilen dosyayı ayarla
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('active', active);

        if (isEdit && gallery) {
            formData.append('id', gallery.id);
            // Düzenleme yapılıyorsa mevcut kategoriyi kullan
            formData.append('category', gallery.category || DEFAULT_CATEGORY);
        } else {
            // Eklemeye yapılıyorsa varsayılan kategoriyi kullan
            formData.append('category', DEFAULT_CATEGORY);
        }

        if (image) {
            formData.append('image', image, 'galleryImage.jpeg');
        }

        try {
            if (isEdit) {
                await axiosInstance.put(`/gallery/${gallery.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                });
                toast.success(t('messages.galleryUpdated') || "Galeri güncellendi.");
            } else {
                await axiosInstance.post('/gallery', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                });
                toast.success(t('messages.galleryAdded') || "Galeri eklendi.");
            }
            navigate('/admin/gallery');
        } catch (err) {
            const errorMessage = err.response?.data?.error || t('errors.saveGalleryFailed') || "Galeri kaydedilemedi.";
            const errorDetails = err.response?.data?.details || "";
            setError(`${errorMessage} ${errorDetails}`);
            console.error('Galeri kaydedilemedi:', err);
            toast.error(`${errorMessage}`);
        } finally {
            setLoading(false);
        }
    };

    // Cleanup object URLs to prevent memory leaks
    useEffect(() => {
        return () => {
            if (image && typeof image === 'string') {
                URL.revokeObjectURL(image);
            }
            if (existingImage) {
                URL.revokeObjectURL(existingImage);
            }
        };
    }, [image, existingImage]);

    return (
        <Card>
            <Card.Body>
                <Card.Title>{isEdit ? t('galleryForm.editGallery') || "Galeri Düzenle" : t('galleryForm.addGallery') || "Galeri Ekle"}</Card.Title>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} noValidate>
                    {/* Aktif Durum Kontrolü */}
                    <Form.Group controlId="galleryActive" className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={t('galleryForm.active') || "Aktif"} // İsteğe bağlı olarak çevirilebilir
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                        />
                    </Form.Group>

                    {/* Görsel Yükleme */}
                    <Form.Group controlId="galleryImage" className="mb-3">
                        <Form.Label>{t('galleryForm.image') || "Görsel"}</Form.Label>
                        <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                        {/* Mevcut Görsel */}
                        {existingImage && !image && isEdit && (
                            <div className="mt-3">
                                <Form.Label>{t('galleryForm.existingImage') || "Mevcut Görsel"}</Form.Label>
                                <br />
                                <Image src={`${BACKEND_URL}${existingImage}`} alt="Mevcut Görsel" thumbnail style={{ width: '200px' }} />
                            </div>
                        )}
                        {/* Yükleme İlerlemesi */}
                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <div className="mt-3">
                                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                            </div>
                        )}
                        {/* Seçilen Görsel */}
                        {image && typeof image === 'object' && (
                            <div className="mt-3">
                                <Form.Label>{t('galleryForm.selectedImage') || "Seçilen Görsel"}</Form.Label>
                                <br />
                                <Image src={URL.createObjectURL(image)} alt="Seçilen Görsel" thumbnail style={{ width: '200px' }} />
                            </div>
                        )}
                        {image && typeof image === 'string' && (
                            <div className="mt-3">
                                <Form.Label>{t('galleryForm.selectedImage') || "Seçilen Görsel"}</Form.Label>
                                <br />
                                <Image src={image} alt="Seçilen Görsel" thumbnail style={{ width: '200px' }} />
                            </div>
                        )}
                    </Form.Group>

                    {/* Gönder Butonu */}
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                {isEdit ? t('galleryForm.updating') || "Güncelleniyor..." : t('galleryForm.adding') || "Ekleniyor..."}
                            </>
                        ) : (
                            isEdit ? t('galleryForm.update') || "Güncelle" : t('galleryForm.add') || "Ekle"
                        )}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};
export default AddEditGallery;
