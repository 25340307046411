// src/admin/GalleryList.jsx

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Alert, Spinner, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { toast } from 'react-toastify';

const GalleryList = () => {
    const [galleries, setGalleries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const [selectedCategory, setSelectedCategory] = useState('');

    const BACKEND_URL = process.env.REACT_APP_API_URL;

    const fetchGalleries = async (category = '') => {
        try {
            let query = '/gallery';
            const params = {};

            if (category) {
                params.category = category;
            }

            const response = await axiosInstance.get(query, { params });
            setGalleries(response.data);
        } catch (err) {
            setError("Galeriler alınamadı.");
            console.error('Galeriler alınamadı:', err);
            toast.error("Galeriler alınamadı.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGalleries();
        // eslint-disable-next-line
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm("Bu galeriyi silmek istediğinize emin misiniz?")) {
            try {
                await axiosInstance.delete(`/gallery/${id}`);
                fetchGalleries();
                toast.success("Galeri silindi.");
            } catch (err) {
                setError("Galeri silinemedi.");
                console.error('Galeri silinemedi:', err);
                toast.error("Galeri silinemedi.");
            }
        }
    };

    // Görseli indirme fonksiyonu
    const handleDownload = (imageName) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = `${BACKEND_URL}${imageName}`;   // Arka uçtaki tam URL
        link.setAttribute('download', imageName);   // İndirme dosya adı
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const handleCategorySelect = (category) => {
    //     setSelectedCategory(category);
    //     fetchGalleries(category);
    // };

    // Tooltip bileşeni
    const renderTooltip = (props, message) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    return (
        <div>
            {/* Üst Başlık ve "Galeri Ekle" Butonu */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Galeri</h3>
                <Link to="/admin/gallery/add">
                    <Button variant="primary">Görsel Ekle</Button>
                </Link>
            </div>

            {/* Hata Mesajı */}
            {error && <Alert variant="danger">{error}</Alert>}

            {/* Kategori Filtre Dropdown */}
            {/* <div className="d-flex justify-content-start align-items-center mb-4">
                <DropdownButton
                    id="dropdown-basic-button"
                    title={selectedCategory || "Kategoriye Göre Filtrele"}
                    onSelect={handleCategorySelect}
                    variant="secondary"
                >
                    <Dropdown.Item eventKey="">Tüm Kategoriler</Dropdown.Item>
                    {[...new Set(galleries.map(g => g.category))].map((category, index) => (
                        <Dropdown.Item key={index} eventKey={category}>{category}</Dropdown.Item>
                    ))}
                </DropdownButton>
            </div> */}

            {/* Galeri Listesi - Grid Düzeni */}
            <Row xs={1} sm={2} md={3} lg={4} className="g-4">
                {galleries.length > 0 ? (
                    galleries.map((gallery) => (
                        <Col key={gallery.id}>
                            <Card className="h-100">
                                {gallery.image ? (
                                    <Card.Img variant="top" src={`${BACKEND_URL}${gallery.image}`} alt={`Gallery ${gallery.id}`} style={{ objectFit: 'cover', height: '200px' }} />
                                ) : (
                                    <Card.Img variant="top" src="https://via.placeholder.com/200x200?text=No+Image" alt="No Image" style={{ objectFit: 'cover', height: '200px' }} />
                                )}
                                <Card.Body className="d-flex flex-column">
                                    <div className="mb-2">
                                        {gallery.active ? (
                                            <Badge bg="success">Aktif</Badge>
                                        ) : (
                                            <Badge bg="secondary">Pasif</Badge>
                                        )}
                                    </div>
                                    <div className="mt-auto">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => renderTooltip(props, "Düzenle")}
                                        >
                                            <Link to={`/admin/gallery/edit/${gallery.id}`}>
                                                <Button variant="warning" size="sm" className="me-2">
                                                    Düzenle
                                                </Button>
                                            </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => renderTooltip(props, "Sil")}
                                        >
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDelete(gallery.id)}
                                                className="me-2"
                                            >
                                                Sil
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={(props) => renderTooltip(props, "İndir")}
                                        >
                                            <Button variant="info" size="sm" onClick={() => handleDownload(gallery.image)}>
                                                İndir
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <Col>
                        <Alert variant="info">Henüz galeri eklenmemiş.</Alert>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default GalleryList;
