// src/admin/HeroList.jsx
import React, { useEffect, useState } from 'react';
import { Table, Button, Image, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';

const HeroList = () => {

  const [heroGroups, setHeroGroups] = useState([]); // Her group_id için bir grup
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const BACKEND_URL = process.env.REACT_APP_API_URL;

  const fetchHeroes = async () => {
    try {
      const response = await axiosInstance.get(`/hero`);
      const allHeroes = response.data;

      // group_id'ye göre gruplandır
      const groups = {};
      allHeroes.forEach(heroItem => {
        if (!groups[heroItem.group_id]) {
          groups[heroItem.group_id] = {
            group_id: heroItem.group_id,
            id_en: null,
            id_tr: null,
            image: null
          };
        }
        if (heroItem.language === 'en') {
          groups[heroItem.group_id].id_en = heroItem.id;
          groups[heroItem.group_id].title_en = heroItem.title;
          groups[heroItem.group_id].subtitle_en = heroItem.subtitle;
        } else if (heroItem.language === 'tr') {
          groups[heroItem.group_id].id_tr = heroItem.id;
          groups[heroItem.group_id].title_tr = heroItem.title;
          groups[heroItem.group_id].subtitle_tr = heroItem.subtitle;
        }
        if (heroItem.background && !groups[heroItem.group_id].image) {
          groups[heroItem.group_id].image = heroItem.background;
        }
      });

      const groupedHeroes = Object.values(groups);
      setHeroGroups(groupedHeroes);
    } catch (err) {
      setError("Hero öğeleri alınamadı.");
      console.error('Hero öğeleri alınamadı:', err);
    } finally {
      setLoading(false);
    }
  };

  // Görseli indirme fonksiyonu
  const handleDownload = (imageName) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${BACKEND_URL}${imageName}`;   // Arka uçtaki tam URL
    link.setAttribute('download', imageName);   // İndirme dosya adı
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchHeroes();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>{"Hero Yönetimi"}</h3>
        {/* "Yeni Hero Ekle" Butonu Kaldırıldı */}
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Görsel</th>
            <th>Başlık (EN)</th>
            <th>Başlık (TR)</th>
            <th>Alt Başlık (EN)</th>
            <th>Alt Başlık (TR)</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {heroGroups.length > 0 ? (
            heroGroups.map((group) => (
              <tr key={group.group_id}>
                <td>
                  {group.image ? (
                    <Image src={`${BACKEND_URL}${group.image}`} alt="Hero Görseli" thumbnail style={{ width: '100px' }} />
                  ) : (
                    "Görsel Yok"
                  )}
                </td>
                <td>{group.title_en || "Başlık Yok"}</td>
                <td>{group.title_tr || "Başlık Yok"}</td>
                <td>{group.subtitle_en || "Alt Başlık Yok"}</td>
                <td>{group.subtitle_tr || "Alt Başlık Yok"}</td>
                <td>
                  {(group.id_en || group.id_tr) ? (
                    <>
                      <Link to={`/admin/hero/edit/${group.id_en || group.id_tr}`}>
                        <Button variant="warning" size="sm">Düzenle</Button>
                      </Link>
                      <Button variant="info" size="sm" className="ms-2" onClick={() => handleDownload(group.image)}>Download</Button>
                    </>

                  ) : (
                    <span>{"Eksik Hero"}</span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">{"Henüz hero eklenmemiş."}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default HeroList;
