// src/components/Press/NewsDetail.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSection from '../HeaderSection/HeaderSection';
import { Spinner, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NewsDetail.css'; // CSS dosyanızı ekleyin
import placeholder from '../../assets/images/beysu.png'; // Placeholder görseli ekleyin
import arrowIcon from '../../assets/images/next.svg'; // İkon ekleyin
import axiosInstance from '../../axiosConfig';

export default function NewsDetail() {
  const { id, lang } = useParams(); // URL'den hem id hem de lang parametresini al
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BACKEND_URL = process.env.REACT_APP_API_URL; // Backend URL'nizi burada tanımlayın

  useEffect(() => {
    // Haber detaylarını çekmek için fonksiyon
    const fetchNewsDetail = async () => {
      try {
        const response = await axiosInstance.get(`/news/${id}`, {
          params: { language: lang } // Dil parametresini ekle
        });
        setNewsItem(response.data);
        console.log('Haber:', response.data);
        setLoading(false);
      } catch (err) {
        console.error('Haber alınamadı:', err);
        setError('Haber alınırken bir hata oluştu.');
        toast.error('Haber alınırken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchNewsDetail();
  }, [id, lang]);

  const formatDate = (dateString, lang) => {
    if (!dateString) {
      console.warn("Date string is missing");
      return "Tarih Yok";
    }
  
    // Tarih formatını kontrol etmek için ISO 8601 regex
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (!iso8601Regex.test(dateString)) {
      console.warn("Date string is not in ISO 8601 format:", dateString);
      return "Geçersiz Tarih Formatı";
    }
  
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) {
      console.warn("Invalid date string:", dateString);
      return "Geçersiz Tarih";
    }
  
    // Tarihi istenen dil ve formatta döndür
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(lang === 'tr' ? 'tr-TR' : 'en-US', options).format(date);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger" className="text-center">{error}</Alert>;
  }

  return (
    <>
      <section className='news-detail-section'>
        <div className='news-detail-container'>
          <div className='news-detail-image-container'>
            {newsItem.image ? (
              <img 
                className='news-detail-image' 
                src={`${BACKEND_URL}${newsItem.image}`} // Mutlak URL ile görseli ayarlayın
                alt={newsItem.title} 
              />
            ) : (
              <img className='news-detail-image' src={placeholder} alt="Placeholder" />
            )}
          </div>
          <HeaderSection 
            menuText={lang === 'tr' ? 'Haberler' : 'News'}
            menuText2={newsItem.title} 
            category="Press"
            icon={arrowIcon}
          />
          <div className="container">
            <div className='news-detail-content'>
              <h1 className='news-detail-title'>{newsItem.title}</h1>
              <div className="news-detail-divider"></div>
              <p className='news-detail-date'>{formatDate(newsItem.date, lang)}</p>
              <div 
                className='news-detail-text' 
                dangerouslySetInnerHTML={{ __html: newsItem.content }} 
              ></div>
              <div className="news-detail-divider"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
