// src/App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OverviewPage from './pages/OverviewPage';
import FounderPage from './pages/FounderPage';
import DirectorsPage from './pages/DirectorsPage';
import DirectorDetail from './pages/DirectorDetail';
import SeniorsPage from './pages/SeniorsPage';
import SeniorDetail from './pages/SeniorDetail';
import InvestmentsPage from './pages/InvestmentsPage';
import Hospitality from './components/Investments/Hospitality';
import Technology from './components/Investments/Technology';
import Energy from './components/Investments/Energy';
import PressPage from './pages/PressPage';
import Education from './components/Social/Education';
import Society from './components/Social/Society';
import Environment from './components/Social/Environment';
import Art from './components/Social/Art';
import ContactPage from './pages/ContactPage';
import NewsDetail from './components/News/NewsDetail';
import Login from './components/Admin/Login';
import Admin from './components/Admin/Admin';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/Admin/PrivateRoute';
import Layout from './components/Layout'; // Yeni oluşturulan Layout bileşeni
import CookiePolicy from './components/Cookie/CookiePolicy';
import ScrollToTop from './components/ScrollToTop';
import Gallery from './components/Gallery/Gallery';
import Financial from './components/Investments/Financial';

function AppRoutes() {
  return (
    <Routes>
      {/* Ana Sayfa için yönlendirme */}
      <Route path="/" element={<Navigate to="/en" replace />} />

      {/* Admin ve Login Rotası - Dil Desteği Dışında */}
      <Route path="/admin/*" element={
        <PrivateRoute>
          <Admin />
        </PrivateRoute>
      } />
      <Route path="/login" element={<Login />} />

      {/* Dil Bazlı Rotalar */}
      <Route path="/:lang/*" element={<Layout />}>
        <Route index element={<HomePage />} />
        {/* About Sayfası */}
        <Route path="about/overview" element={<OverviewPage />} />
        <Route path="about/founder" element={<FounderPage />} />
        <Route path="about/directors" element={<DirectorsPage />} />
        <Route path="about/directors/:id" element={<DirectorDetail />} />
        <Route path="about/seniors" element={<SeniorsPage />} />
        <Route path="about/seniors/:id" element={<SeniorDetail />} />

        {/* Investments Sayfası */}
        <Route path="investments" element={<InvestmentsPage />} />
        <Route path="investments/hospitality" element={<Hospitality />} />
        <Route path="investments/technology" element={<Technology />} />
        <Route path="investments/energy" element={<Energy />} />
        <Route path="investments/financial" element={<Financial />} />

        {/* Press Sayfası */}
        <Route path="press/news" element={<PressPage />} />
        <Route path="press/news/:id" element={<NewsDetail />} />
        <Route path="press/gallery" element={<Gallery />} />

        {/* Social Responsibility Sayfası */}
        <Route path="social/education" element={<Education />} />
        <Route path="social/society-economy" element={<Society />} />
        <Route path="social/environment" element={<Environment />} />
        <Route path="social/art" element={<Art />} />

        {/* Contact Sayfası */}
        <Route path="contact" element={<ContactPage />} />

        <Route path="cookie-policy" element={<CookiePolicy />} />
      </Route>

      {/* Diğer tüm yollar için yönlendirme */}
      <Route path="*" element={<Navigate to="/en" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <AppRoutes />
        <ScrollToTop />
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
