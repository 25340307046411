// src/components/Navbar/Navbar.jsx
import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../assets/images/logo-big.png";
import usFlag from "../../assets/images/us_flag.svg";
import trFlag from "../../assets/images/tr_flag.svg";
import flagIcon from "../../assets/images/flag.svg";
import buildIcon from "../../assets/images/building.svg";
import briefIcon from "../../assets/images/briefcase.svg";
import usersIcon from "../../assets/images/users.svg";
import hospitalIcon from "../../assets/images/hospital.svg";
import techIcon from "../../assets/images/technology.svg";
import energyIcon from "../../assets/images/bolt.svg";
import educationIcon from "../../assets/images/education.svg";
import societyIcon from "../../assets/images/toplum.svg";
import environmentIcon from "../../assets/images/environment.svg";
import artIcon from "../../assets/images/art.svg";
import arrowIcon from "../../assets/images/chevron-down.svg";
import galleryIcon from "../../assets/images/image-01.svg";
import newsIcon from "../../assets/images/file-02.svg";
import financialIcon from "../../assets/images/financial.svg";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function DropdownItem({ Icon, title, description, href }) {
  return (
    <Link className="dropdown-item" to={href}>
      {/* İkon */}
      <img src={Icon} alt="" className="dropdown-icon" />
      {/* Metinler */}
      <div className="dropdown-text">
        <span className="dropdown-main">{title}</span>
        <small className="dropdown-sub">{description}</small>
      </div>
    </Link>
  );
}

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const isActive = (path) => location.pathname === path ? "active" : "";

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    if (path === 'en' || path === 'tr') {
      setLanguage(path);
      i18n.changeLanguage(path);
    } else {
      setLanguage('en');
    }
  }, [location, i18n]);

  const toggleLanguage = () => {
    const newLang = language === "en" ? "tr" : "en";
    let newPath = location.pathname;

    if (location.pathname.startsWith(`/${language}`)) {
      newPath = location.pathname.replace(`/${language}`, `/${newLang}`);
    } else {
      newPath = `/${newLang}${location.pathname}`;
    }

    setLanguage(newLang);
    i18n.changeLanguage(newLang);
    navigate(newPath);
  };

  const languageIcon = language === "en" ? trFlag : usFlag;
  const languageText = language === "en" ? "Türkçe" : "English";

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-white lang-${language}`}>
      <div className="container">
        {/* Left: Logo */}
        <Link className="navbar-brand d-flex align-items-center" to={`/${language}`}>
          <img src={Logo} alt="MV Holding Logo" className="navbar-logo" />
        </Link>

        {/* Mobile Button (Toggler) */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Content */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            {/* Menu Links */}
            <li className="nav-item">
              <Link className={`nav-link ${isActive(`/${language}`)}`} to={`/${language}`}>
                {t('navbar.home')}
              </Link>
            </li>

            {/* About Dropdown */}
            <li className="nav-item dropdown">
              <>
                <Link className="nav-link" to={`/${language}/about/overview`}>
                  {t('navbar.about')}
                </Link>
                <button
                  id="aboutDropdownToggle"
                  className="dropdown-toggle-button"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-1" />
                </button>
              </>
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="aboutDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={flagIcon}
                    title={t('navbar.overview')}
                    //description={language === "en" ? "MV Holding was founded in 1993 by Murat Vargı" : "MV Holding 1993 yılında Murat Vargı tarafından kuruldu"} // Çeviri
                    href={`/${language}/about/overview`}
                  />
                </li>
                {/* <li>
                  <DropdownItem
                    Icon={flagIcon}
                    title={t('navbar.timeline')}
                    //description={language === "en" ? "MV Holding was founded in 1993 by Murat Vargı" : "MV Holding 1993 yılında Murat Vargı tarafından kuruldu"} // Çeviri
                    href={`/${language}/about/timeline`}
                  />
                </li> */}
                <li>
                  <DropdownItem
                    Icon={buildIcon}
                    title={t('navbar.founder')}
                    //description={language === "en" ? "Murat Vargi is the Founder and Honorary President" : "Murat Vargı, Kurucu ve Onursal Başkan"} // Çeviri
                    href={`/${language}/about/founder`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={briefIcon}
                    title={t('navbar.boardOfDirectors')}
                    //description={language === "en" ? "You can find more information about Board" : "Yönetim Kurulu hakkında daha fazla bilgi.."} // Çeviri
                    href={`/${language}/about/directors`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={usersIcon}
                    title={t('navbar.seniorManagement')}
                    //description={language === "en" ? "You can find more information about Senior" : "Üst Yönetim hakkında daha fazla bilgi.."} // Çeviri
                    href={`/${language}/about/seniors`}
                  />
                </li>
              </ul>
            </li>

            {/* Investments Dropdown */}
            <li className="nav-item dropdown">
              <Link className="nav-link" to={`/${language}/investments`}>
                {t('navbar.investments')}
              </Link>
              <button
                id="investmentsDropdownToggle"
                className="dropdown-toggle-button"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="investmentsDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={hospitalIcon}
                    title={t('navbar.hospitality')}
                    //description={language === "en" ? "Our hospitality investments" : "Konaklama yatırımları"} // Çeviri
                    href={`/${language}/investments/hospitality`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={energyIcon}
                    title={t('navbar.energy')}
                    //description={language === "en" ? "Sustainable energy investments" : "Sürdürülebilir enerji yatırımları"} // Çeviri
                    href={`/${language}/investments/energy`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={financialIcon}
                    title={t('navbar.financial')}
                    //description={language === "en" ? "Financial investments" : "Finansal Yatırımlar"} // Çeviri
                    href={`/${language}/investments/financial`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={techIcon}
                    title={t('navbar.technology')}
                    //description={language === "en" ? "Innovative tech investments" : "Yenilikçi teknoloji yatırımları"} // Çeviri
                    href={`/${language}/investments/technology`}
                  />
                </li>
              </ul>
            </li>

            {/* Social Responsibility Dropdown */}
            <li className="nav-item dropdown">
              <div className="nav-link">
                {t('navbar.socialResponsibility')}
              </div>
              <button
                id="socialDropdownToggle"
                className="dropdown-toggle-button"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-3" />
              </button>
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="socialDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={environmentIcon}
                    title={t('navbar.environment')}
                    //description={language === "en" ? "MV Holding has recently been instrumental" : "MV Holding, son zamanlarda önemli katkılarda bulunmuştur"} // Çeviri
                    href={`/${language}/social/environment`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={artIcon}
                    title={t('navbar.art')}
                    //description={language === "en" ? "MV Holding continues to support art with" : "MV Holding, sanatı desteklemeye devam etmektedir"} // Çeviri
                    href={`/${language}/social/art`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={educationIcon}
                    title={t('navbar.education')}
                    //description={language === "en" ? "MV Holding aims to contribute to sustainable dev" : "MV Holding, sürdürülebilir gelişime katkıda bulunmayı hedefliyor"} // Çeviri
                    href={`/${language}/social/education`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={societyIcon}
                    title={t('navbar.societyEconomy')}
                    //description={language === "en" ? "MV Holding allocates a significant amount" : "MV Holding, önemli miktarda kaynak ayırmaktadır"} // Çeviri
                    href={`/${language}/social/society-economy`}
                  />
                </li>
              </ul>
            </li>

            {/* Press Sayfası */}
            <li className="nav-item dropdown">
              <Link className="nav-link" to={`/${language}/press/news`}>
                {t('navbar.press')}
              </Link>
              <button
                id="pressDropdownToggle"
                className="dropdown-toggle-button"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={arrowIcon} alt="Toggle Dropdown" className="dropdown-arrow dropdown-arrow-5" />
              </button>
              <ul
                className="dropdown-menu dropdown-with-icons"
                aria-labelledby="pressDropdownToggle"
              >
                <li>
                  <DropdownItem
                    Icon={newsIcon}
                    title={t('navbar.press')}
                    //description={language === "en" ? "Stay updated with the latest news and announcements.": "En son haberler ve duyurularla güncel kalın." // Çeviri}
                    href={`/${language}/press/news`}
                  />
                </li>
                <li>
                  <DropdownItem
                    Icon={galleryIcon}
                    title={t('navbar.gallery')}
                    //description={language === "en" ? "Explore captivating images and moments captured from our events." : "Etkinliklerimizden yakalanan büyüleyici görselleri keşfedin." }
                    href={`/${language}/press/gallery`}
                  />
                </li>
              </ul>
            </li>

            {/* Contact Sayfası */}
            <li className="nav-item">
              <Link className="nav-link" to={`/${language}/contact`}>
                {t('navbar.contact')}
              </Link>
            </li>
          </ul>

          {/* Sağ: Dil Değiştirici */}
          <div className="d-flex align-items-center language-section">
            <button
              className="btn btn-link nav-link language-toggle"
              onClick={toggleLanguage}
            >
              <div className="d-flex align-items-center">
                <img
                  src={languageIcon}
                  alt={language === "en" ? "Türkçe" : "English"}
                  className="language-icon me-1"
                />
                {languageText}
              </div>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
