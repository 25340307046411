import React, { useState } from 'react';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosConfig';
import { useSiteTexts } from '../../context/SiteTextsContext';

export default function Contact() {
    const { t } = useTranslation();
    const { siteTexts, loading: siteTextsLoading, error: siteTextsError } = useSiteTexts();

    // Form alanları için state'ler
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
        privacyCheck: false,
    });

    // İstek durumu, hata ve başarı mesajları için state'ler
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Input değişimlerini yakalamak için
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Form gönderimi
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            // Backend endpoint'in URL'sini kendine göre ayarla
            await axiosInstance.post('/contact', formData);

            setSuccess(t('contactPage.messageSuccess') || 'Mesaj başarıyla gönderildi!');
            // Formu resetle
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                subject: '',
                message: '',
                privacyCheck: false,
            });
        } catch (err) {
            console.error('Form gönderim hatası:', err);
            setError(t('contactPage.messageError') || 'Mesaj gönderilirken bir hata oluştu!');
        } finally {
            setLoading(false);
        }
    };

    // Eğer site metinleri yükleniyorsa veya hata varsa uygun mesaj göster
    if (siteTextsLoading) {
        return <div>Loading...</div>;
    }

    if (siteTextsError) {
        return <div className="alert alert-danger" role="alert">{siteTextsError}</div>;
    }

    return (
        <section className="contact-section">
            <div className="container">
                <div className='contact-section-inner'>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="contact-title">MV Holding</h1>
                            <p className="contact-subtext">
                                {t('contactPage.subtext')}
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        {/* Form Kısmı */}
                        <div className="col-md-5 order-2 order-md-1">
                            {/* Başarı/Hata Mesajları */}
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )}
                            {success && (
                                <div className="alert alert-success" role="alert">
                                    {success}
                                </div>
                            )}
                            {/* Form */}
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6 col-12">
                                        <label htmlFor="firstName">{t('contactPage.firstName')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            name="firstName"
                                            placeholder={t('contactPage.firstName')}
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 col-12">
                                        <label htmlFor="lastName">{t('contactPage.lastName')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            name="lastName"
                                            placeholder={t('contactPage.lastName')}
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">{t('contactPage.email')}</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="mail@gmail.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">{t('contactPage.subject')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        name="subject"
                                        placeholder={t('contactPage.subject')}
                                        value={formData.subject}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">{t('contactPage.message')}</label>
                                    <textarea
                                        className="form-control form-control-message"
                                        id="message"
                                        name="message"
                                        rows="5"
                                        placeholder={t('contactPage.message')}
                                        value={formData.message}
                                        onChange={handleChange}
                                        disabled={loading}
                                    ></textarea>
                                </div>
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="privacyCheck"
                                        name="privacyCheck"
                                        checked={formData.privacyCheck}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                    <label className="form-check-label" htmlFor="privacyCheck">
                                        {siteTexts['contactPage.privacy'] || t('contactPage.privacy')}
                                    </label>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-danger btn-block"
                                    disabled={loading}
                                >
                                    {loading
                                        ? t('contactPage.sending') || 'Gönderiliyor...'
                                        : t('contactPage.sendMessage')}
                                </button>
                            </form>
                        </div>

                        {/* Harita ve İletişim Bilgileri */}
                        <div className="col-md-7 order-1 order-md-2">
                            <div className="map-container">
                                <iframe
                                    title="Google Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.4574848690622!2d29.099908900000003!3d40.9714622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac64e406a620b%3A0x6e301c2627283671!2sMV%20Holding%20A.%C5%9E!5e0!3m2!1str!2str!4v1735540160319!5m2!1str!2str"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                ></iframe>
                            </div>
                            <div className="contact-adress-info">
                                <div className="contact-adress">
                                    <h5>{t('contactPage.address')}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: siteTexts['contactPage.address']}}></p>
                                </div>
                                <div className="contact-adress">
                                    <h5>{t('contactPage.phone')}</h5>
                                    <p>{siteTexts['contactPage.phone']}</p>
                                </div>
                                <div className="contact-adress">
                                    <h5>{t('contactPage.emailContact')}</h5>
                                    <p>{siteTexts['contactPage.emailContact']}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
