// src/admin/AddEditSocialResponsibility.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Card, Alert, Spinner, ProgressBar, Modal, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axiosConfig';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Daha önce oluşturulan cropImage yardımcı fonksiyonunu kullanıyoruz.
import imageCompression from 'browser-image-compression'; // Yeni eklenen
import { v4 as uuidv4 } from 'uuid'; // UUID oluşturmak için

const AddEditSocialResponsibility = () => {
    const { id } = useParams();
    const isEdit = Boolean(id);
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

    // Ortak Bilgiler
    const [category, setCategory] = useState('');

    // İngilizce Bilgiler
    const [titleEn, setTitleEn] = useState('');
    const [textEn, setTextEn] = useState('');
    const [linkEn, setLinkEn] = useState('');

    // Türkçe Bilgiler
    const [titleTr, setTitleTr] = useState('');
    const [textTr, setTextTr] = useState('');
    const [linkTr, setLinkTr] = useState('');

    // Aktif Durum
    const [active, setActive] = useState(true);

    // Ortak Görsel Durumları
    const [image, setImage] = useState(null); // Görsel URL'si (sıkıştırılmış veya orijinal)
    const [backgroundFile, setBackgroundFile] = useState(null); // Görsel dosyası (sıkıştırılmış veya orijinal)
    const [croppedImage, setCroppedImage] = useState(null); // Kırpılmış görsel blob'u

    const [existingImage, setExistingImage] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // İlerleme çubuğu için

    // Kırpma için Ortak Durumlar
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);

    const [groupId, setGroupId] = useState('');

    useEffect(() => {
        if (isEdit) {
            const fetchSocialGroup = async () => {
                try {
                    const response = await axiosInstance.get(`/social-responsibility/${id}`);
                    const socials = response.data;

                    const socialEn = socials.find(s => s.language === 'en');
                    const socialTr = socials.find(s => s.language === 'tr');

                    if (socialEn) {
                        setGroupId(socialEn.group_id);
                        setCategory(socialEn.category);
                        setTitleEn(socialEn.title);
                        setTextEn(socialEn.text);
                        setLinkEn(socialEn.link);
                        setImage(`${BACKEND_URL}${socialEn.image}`);
                        setExistingImage(socialEn.image);
                        setActive(socialEn.active ? true : false); // Aktif durumu ayarla
                    }

                    if (socialTr) {
                        setTitleTr(socialTr.title);
                        setTextTr(socialTr.text);
                        setLinkTr(socialTr.link);
                    }
                } catch (err) {
                    setError('Sosyal sorumluluk grubu alınamadı.');
                    console.error('Sosyal sorumluluk grubu alınamadı:', err);
                }
            };
            fetchSocialGroup();
        }
    }, [id, isEdit, BACKEND_URL]);

    // Ortak Görsel Değişimi
    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const fileSizeKB = selectedFile.size / 1024;

            if (fileSizeKB > 600) {
                // 600 KB'den büyükse sıkıştırma işlemi
                const options = {
                    maxSizeMB: 1.0,
                    maxWidthOrHeight: 1920,
                    initialQuality: 0.95,
                    useWebWorker: true,
                };

                try {
                    const compressedFile = await imageCompression(selectedFile, options);
                    const compressedImageURL = URL.createObjectURL(compressedFile);
                    console.log("Sıkıştırılmış Görsel URL'si:", compressedImageURL);
                    setBackgroundFile(compressedFile);
                    setImage(compressedImageURL);
                    toast.info('Görsel sıkıştırıldı ve kırpma işlemi için hazır.');
                } catch (error) {
                    console.error("Görsel sıkıştırma hatası:", error);
                    setError("Görsel sıkıştırılırken bir hata oluştu.");
                }
            } else {
                // 600 KB'den küçükse sıkıştırma yapmadan direkt olarak kullan
                const originalImageURL = URL.createObjectURL(selectedFile);
                console.log("Orijinal Görsel URL'si:", originalImageURL);
                setBackgroundFile(selectedFile);
                setImage(originalImageURL);
                toast.info('Görsel seçildi ve kırpma işlemi için hazır.');
            }

            setShowCropModal(true);
        }
    };

    // Kırpma Tamamlandı
    const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = useCallback(async () => {
        try {
            const croppedBlob = await getCroppedImg(image, croppedAreaPixels);
            setCroppedImage(croppedBlob);
            setShowCropModal(false);
            toast.success('Görsel başarıyla kırpıldı ve kaydedildi.');
        } catch (e) {
            console.error(e);
            toast.error('Görsel kırpma sırasında bir hata oluştu.');
            setError('Görsel kırpma sırasında bir hata oluştu.');
        }
    }, [image, croppedAreaPixels]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setUploadProgress(0);

        const formData = new FormData();

        // Ortak Bilgiler
        formData.append('category', category);

        // Aktif Durum
        formData.append('active', active);

        // Dillerin Kontrolü ve Verilerin Eklenmesi
        if (titleEn || textEn || linkEn) {
            formData.append('title_en', titleEn);
            formData.append('text_en', textEn);
            formData.append('link_en', linkEn);
        }

        if (titleTr || textTr || linkTr) {
            formData.append('title_tr', titleTr);
            formData.append('text_tr', textTr);
            formData.append('link_tr', linkTr);
        }

        // Ortak Görsel
        if (croppedImage) {
            formData.append('image', croppedImage, 'croppedImage.jpeg');
        }

        // group_id ekleme
        if (!isEdit) {
            const newGroupId = uuidv4();
            formData.append('group_id', newGroupId);
        } else {
            formData.append('group_id', groupId);
        }

        try {
            if (isEdit) {
                await axiosInstance.put(`/social-responsibility/${groupId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Sosyal sorumluluk grubu başarıyla güncellendi!');
            } else {
                await axiosInstance.post(`/social-responsibility`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                });
                toast.success('Yeni sosyal sorumluluk grubu başarıyla eklendi!');
            }
            navigate('/admin/social-responsibility');
        } catch (err) {
            setError('Sosyal sorumluluk grubu kaydedilemedi.');
            console.error('Sosyal sorumluluk grubu kaydedilemedi:', err);
            toast.error('Sosyal sorumluluk grubu kaydedilemedi.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>{isEdit ? 'Sosyal Sorumluluk Grubunu Düzenle' : 'Sosyal Sorumluluk Grubu Ekle'}</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        {/* Kategori Seçimi */}
                        <Form.Group controlId="socialCategory" className="mb-3">
                            <Form.Label>Kategori</Form.Label>
                            <Form.Control
                                as="select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="">Kategori seçiniz</option>
                                <option value="Art">Art</option>
                                <option value="Education">Education</option>
                                <option value="Environment">Environment</option>
                                <option value="Society">Society</option>
                            </Form.Control>
                        </Form.Group>

                        <Tabs
                            defaultActiveKey="en"
                            id="social-responsibility-tabs"
                            className="mb-3"
                        >
                            <Tab eventKey="en" title="English">
                                <Form.Group controlId="socialTitleEn" className="mb-3">
                                    <Form.Label>Title (EN)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={titleEn}
                                        onChange={(e) => setTitleEn(e.target.value)}
                                        placeholder="Enter title in English"
                                    />
                                </Form.Group>

                                <Form.Group controlId="socialTextEn" className="mb-3">
                                    <Form.Label>Text (EN)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={textEn}
                                        onChange={(e) => setTextEn(e.target.value)}
                                        placeholder="Enter text in English"
                                    />
                                </Form.Group>

                                <Form.Group controlId="socialLinkEn" className="mb-3">
                                    <Form.Label>Link (EN)</Form.Label>
                                    <Form.Control
                                        type="url"
                                        value={linkEn}
                                        onChange={(e) => setLinkEn(e.target.value)}
                                        placeholder="Enter link in English"
                                    />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="tr" title="Türkçe">
                                <Form.Group controlId="socialTitleTr" className="mb-3">
                                    <Form.Label>Başlık (TR)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={titleTr}
                                        onChange={(e) => setTitleTr(e.target.value)}
                                        placeholder="Başlık giriniz (TR)"
                                    />
                                </Form.Group>

                                <Form.Group controlId="socialTextTr" className="mb-3">
                                    <Form.Label>Metin (TR)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={textTr}
                                        onChange={(e) => setTextTr(e.target.value)}
                                        placeholder="Metin giriniz (TR)"
                                    />
                                </Form.Group>

                                <Form.Group controlId="socialLinkTr" className="mb-3">
                                    <Form.Label>Link (TR)</Form.Label>
                                    <Form.Control
                                        type="url"
                                        value={linkTr}
                                        onChange={(e) => setLinkTr(e.target.value)}
                                        placeholder="Link giriniz (TR)"
                                    />
                                </Form.Group>
                            </Tab>
                        </Tabs>

                        {/* Aktif Durum Kontrolü */}
                        <Form.Group controlId="socialActive" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Aktif" // İsteğe bağlı olarak çevirilebilir
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                            />
                        </Form.Group>

                        {/* Ortak Görsel Yükleme */}
                        <Form.Group controlId="socialImage" className="mb-3">
                            <Form.Label>Görsel</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                            {existingImage && !backgroundFile && !croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Mevcut Görsel:</Form.Label>
                                    <br />
                                    <img src={`${BACKEND_URL}${existingImage}`} alt="Mevcut Görsel" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                            {uploadProgress > 0 && uploadProgress < 100 && (
                                <div className="mt-3">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
                                </div>
                            )}
                            {croppedImage && (
                                <div className="mt-3">
                                    <Form.Label>Seçilen ve Kırpılan Görsel:</Form.Label>
                                    <br />
                                    <img src={URL.createObjectURL(croppedImage)} alt="Kırpılan Görsel" className="img-thumbnail" style={{ width: '200px' }} />
                                </div>
                            )}
                        </Form.Group>

                        {/* Submit ve İptal Butonları */}
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="secondary"
                                onClick={() => navigate('/admin/social-responsibility')}
                                className="me-2"
                                disabled={loading}
                            >
                                İptal
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        {isEdit ? 'Güncelleniyor...' : 'Ekleniyor...'}
                                    </>
                                ) : (
                                    isEdit ? 'Güncelle' : 'Ekle'
                                )}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            {/* Crop Modal */}
            <Modal show={showCropModal} onHide={() => setShowCropModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Görseli Kırp</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative', height: '400px' }}>
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 3} // İhtiyacınıza göre oranı ayarlayın
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropCompleteHandler}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCropModal(false)}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={handleCropSave}>
                        Kırp ve Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default AddEditSocialResponsibility;
