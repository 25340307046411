// src/admin/Admin.jsx
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Nav, Container, Button, Row, Col } from 'react-bootstrap';
import {
  FaNewspaper,
  FaUserFriends,
  FaHome,
  FaUserTie,
  FaChartLine,
  FaHeart,
  FaBullhorn,
  FaSignOutAlt,
  FaPhotoVideo,
  FaFont,
  FaBars, // Hamburger icon
  FaList,
  FaHeadSideVirus,
  FaAddressBook, // For Accordions
} from 'react-icons/fa';
import NewsList from './NewsList';
import AddEditNews from './AddEditNews';
import Welcome from './Welcome';
import logo from '../../assets/images/logo-panel.png';
import HeroList from './HeroList';
import AddEditHero from './AddEditHero';
import AddEditSocial from './AddEditSocial';
import SocialList from './SocialList';
import DirectorsList from './DirectorsList';
import AddEditDirector from './AddEditDirector';
import AddEditSenior from './AddEditSenior';
import SeniorsList from './SeniorsList';
import AddEditInvestment from './AddEditInvestment';
import InvestmentsList from './InvestmentsList';
import AddEditSocialResponsibility from './AddEditSocialResponsibility';
import SocialResponsibilityList from './SocialResponsibilityList';
import HeaderSectionList from './HeaderSectionList';
import AddEditHeaderSection from './AddEditHeaderSection';
import './Admin.css';
import PopupList from './PopupList';
import AddEditPopup from './AddEditPopup';
import GalleryList from './GalleryList';
import AddEditGallery from './AddEditGallery';
import SiteTextsList from './SiteTextsList';
import AddEditSiteText from './AddEditSiteText';
import InvestmentCardList from './InvestmentCardList';
import AddEditInvestmentCard from './AddEditInvestmentCard';
import { NavLink } from 'react-router-dom';
import OverviewList from './OverviewList';
import EditOverview from './EditOverview';


const Admin = () => {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Lock body scroll when sidebar is open on small screens
  useEffect(() => {
    if (sidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [sidebarVisible]);

  const handleLogout = () => {
    // Implement your logout logic here
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <Container fluid className="admin-container">
      <Row>
        {/* Sidebar */}
        <Col
          xs={12}
          md={3}
          lg={2}
          className={`admin-sidebar p-0 ${sidebarVisible ? 'active' : ''}`}
        >
          <div className="admin-sidebar-header d-flex align-items-center justify-content-center p-4">
            <Link to="/admin" onClick={closeSidebar}>
              <img src={logo} alt="Logo" className="admin-logo" />
            </Link>
          </div>
          <Nav className="flex-column admin-sidebar-nav">
            <Nav.Link
              as={NavLink}
              to="/admin/hero"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaHome className="admin-sidebar-icon" />
              Hero
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/social"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaList className="admin-sidebar-icon" />
              Akordiyonlar
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/overview"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaAddressBook className="admin-sidebar-icon" />
              Genel Bakış
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/directors"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaUserFriends className="admin-sidebar-icon" />
              Yönetim Kurulu
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/seniors"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaUserTie className="admin-sidebar-icon" />
              Üst Yönetim
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/investments-card"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaChartLine className="admin-sidebar-icon" />
              Yatırımlar Intro
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/investments-panel"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaChartLine className="admin-sidebar-icon" />
              Yatırımlar
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/social-responsibility"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaHeart className="admin-sidebar-icon" />
              Sosyal Sorumluluk
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/news"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaNewspaper className="admin-sidebar-icon" />
              Haberler
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/header-sections"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaHeadSideVirus className="admin-sidebar-icon" />
              Header
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/popup"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaBullhorn className="admin-sidebar-icon" />
              Pop-up
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/gallery"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaPhotoVideo className="admin-sidebar-icon" />
              Galeri
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/site-texts"
              className="admin-sidebar-link"
              onClick={closeSidebar}
              end
            >
              <FaFont className="admin-sidebar-icon" />
              Site Metinleri
            </Nav.Link>
          </Nav>
          <div className="admin-sidebar-footer p-4">
            <Button
              variant="primary"
              onClick={handleLogout}
              className="admin-logout-button w-100 d-flex align-items-center justify-content-center"
            >
              <FaSignOutAlt className="me-2" />
              Çıkış Yap
            </Button>
          </div>
        </Col>

        {/* Main Content */}
        <Col xs={12} md={9} lg={10} className="admin-main-content">
          {/* Header with Toggle Button */}
          <div className="admin-header d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center">
              {/* Toggle Button for Small Screens */}
              <Button
                variant="link"
                className="d-md-none me-3 toggle-sidebar-button"
                onClick={toggleSidebar}
                aria-label="Toggle Sidebar"
              >
                <FaBars size={24} />
              </Button>
              <h4>Admin Paneli</h4>
            </div>
            <div className="admin-user-info">
              <span>Hoşgeldiniz, Admin</span>
            </div>
          </div>
          <div className="admin-content p-4">
            {/* Overlay */}
            {sidebarVisible && (
              <div className="sidebar-overlay" onClick={closeSidebar}></div>
            )}
            <Routes>
              {/* Default Route */}
              <Route path="/" element={<Welcome />} />

              {/* Haberler */}
              <Route path="news" element={<NewsList />} />
              <Route path="news/add" element={<AddEditNews />} />
              <Route path="news/edit/:id" element={<AddEditNews />} />

              {/* Hero */}
              <Route path="hero" element={<HeroList />} />
              <Route path="hero/add" element={<AddEditHero />} />
              <Route path="hero/edit/:id" element={<AddEditHero />} />

              {/* Akordiyonlar */}
              <Route path="social" element={<SocialList />} />
              <Route path="social/add" element={<AddEditSocial />} />
              <Route path="social/edit/:id" element={<AddEditSocial />} />

              {/* Direktörler */}
              <Route path="directors" element={<DirectorsList />} />
              <Route path="directors/add" element={<AddEditDirector />} />
              <Route path="directors/edit/:id" element={<AddEditDirector />} />

              {/* Üst Yönetim */}
              <Route path="seniors" element={<SeniorsList />} />
              <Route path="seniors/add" element={<AddEditSenior />} />
              <Route path="seniors/edit/:id" element={<AddEditSenior />} />

              {/* Yatırımlar */}
              <Route path="investments-panel" element={<InvestmentsList />} />
              <Route path="investments-panel/add" element={<AddEditInvestment />} />
              <Route path="investments-panel/edit/:id" element={<AddEditInvestment />} />

              {/* Sosyal Sorumluluk */}
              <Route
                path="social-responsibility"
                element={<SocialResponsibilityList />}
              />
              <Route
                path="social-responsibility/add"
                element={<AddEditSocialResponsibility />}
              />
              <Route
                path="social-responsibility/edit/:id"
                element={<AddEditSocialResponsibility />}
              />

              {/* Header Sections */}
              <Route path="header-sections" element={<HeaderSectionList />} />
              <Route path="header-sections/add" element={<AddEditHeaderSection />} />
              <Route
                path="header-sections/edit/:category"
                element={<AddEditHeaderSection />}
              />

              {/* Pop-up */}
              <Route path="popup" element={<PopupList />} />
              <Route path="popup/add" element={<AddEditPopup />} />
              <Route path="popup/edit/:id" element={<AddEditPopup />} />

              {/* Gallery */}
              <Route path="gallery" element={<GalleryList />} />
              <Route path="gallery/add" element={<AddEditGallery />} />
              <Route path="gallery/edit/:id" element={<AddEditGallery />} />

              {/* Text */}
              <Route path="site-texts" element={<SiteTextsList />} />
              <Route path="site-texts/add" element={<AddEditSiteText />} />
              <Route path="site-texts/edit/:id" element={<AddEditSiteText />} />

              {/* Investment Card */}
              <Route path="investments-card" element={<InvestmentCardList />} />
              <Route path="investments-card/add" element={<AddEditInvestmentCard />} />
              <Route
                path="investments-card/edit/:id"
                element={<AddEditInvestmentCard />}
              />

              {/* Overview */}
              <Route path="overview" element={<OverviewList />} />
              <Route path="overview/edit/:id" element={<EditOverview />} />

              {/* 404 or Default Route */}
              <Route path="*" element={<Welcome />} />
            </Routes>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
