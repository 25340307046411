// src/components/Press/Press.jsx
import React, { useState, useEffect } from 'react';
import HeaderSection from '../HeaderSection/HeaderSection';
import PressCard from './PressCard';
import { Alert } from 'react-bootstrap'; // Import Alert
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS
import axiosInstance from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Press() {
  const { lang } = useParams();
  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);

  const BACKEND_URL = process.env.REACT_APP_API_URL; // Define your backend URL here

  useEffect(() => {
    // Function to fetch news from the API
    const fetchNews = async () => {
      try {
        const response = await axiosInstance.get(`/news`, {
          params: { language: lang },
        });
        const filteredNews = response.data.filter(newsItem => 
          newsItem.active === 1
        );
        setNews(filteredNews);
      } catch (err) {
        console.error('Haberler alınamadı:', err);
        setError(t('errors.fetchPressNewsFailed') || 'Haberler alınamadı.');
        toast.error(t('errors.fetchPressNewsFailed') || 'Haberler alınamadı.');
      }
    };

    fetchNews();
  }, [lang, t]);

  // Helper function to extract the first paragraph's text
  const getSummary = (htmlString, maxLength = 300) => {
    if (!htmlString) return 'Bu haberin özeti bulunmamaktadır.';

    // Remove HTML tags and special characters like &nbsp;
    const plainText = htmlString
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/&nbsp;/g, ' ')
      .trim();

    // Truncate the text
    if (plainText.length > maxLength) {
      return `${plainText.substring(0, maxLength)}...`;
    }

    return plainText || 'Bu haberin özeti bulunmamaktadır.';
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat(lang === 'tr' ? 'tr-TR' : 'en-US', options).format(date); // Language-based date format
  };

  if (error) {
    return <Alert variant="danger" className="text-center">{error}</Alert>;
  }

  return (
    <section className="press-section">
      <HeaderSection title={t('press.title')} menuText={t('press.menu')} category="News" />
      <div className="container">
        {news.map((newsItem) => {
          // Check if the image ends with 'default.jpg' (case-insensitive)
          const isDefaultImage = newsItem.image && newsItem.image.toLowerCase().endsWith('default.jpg');

          return (
            <PressCard
              key={newsItem.id} // Unique key for each PressCard
              image={!isDefaultImage && newsItem.image ? `${BACKEND_URL}${newsItem.image}` : null} // Pass null if default.jpg
              title={newsItem.title}
              text={getSummary(newsItem.content)}
              date={formatDate(newsItem.date)}
              link={`/${lang}/press/news/${newsItem.id}`} // Dynamic link
            />
          );
        })}
      </div>
    </section>
  );
}
